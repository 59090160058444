import { Table } from "antd";
import Column from "antd/es/table/Column";
import moment from "moment/moment";

import React, { useEffect, useState } from "react";
import { FaChartBar } from "react-icons/fa";
import { TbBrandApple } from "react-icons/tb";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/auth/selectors";
import PramienService from "../../../service/PramienService";
import PublicService from "../../../service/PublicService";
import { PopUpComponent } from "../../ConfirmPopup";
import { Drag } from "./Svg";

const Index = () => {
  const { user } = useSelector(selectUser);
  const [supportValue, setSupportValue] = useState("");
  const [requests, setRequests] = useState([]);
  const [redemmed, setRedemmed] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const getRewardsList = async () => {
    try {
      const { data } = await PramienService.getRequests();
      const { data: redemmedData } = await PramienService.listRedeemed();

      setRedemmed(redemmedData);
      setRequests(data);
    } catch (error) {}
  };
  useEffect(() => {
    getRewardsList();
  }, []);

  return (
    <div className="flex  justify-between  xl:flex-nowrap flex-wrap gap-[100px] ">
      <div className="xl:w-3/4 w-[99%] flex flex-col gap-5">
        <div className="rounded-11xl bg-[#fafafa] p-5">
          <h1 className="sm:text-9xl text-xl font-semibold my-2">
            Eingehende Prämienanfragen
          </h1>
          <div
            className=" rounded-[20px] w-[99%] bg-white border-[#D8D8D8] border-[1px]  !overflow-auto"
            id="drag-div-1"
          >
            <Table
              className=" bg-transparent relative overflow-auto  max-h-[300px] min-h-[300px]"
              bordered={false}
              dataSource={requests}
              pagination={false}
              rowKey={"uid"}
            >
              <Column
                title="Name"
                render={(__, _) =>
                  `${_?.promoter_id?.firstname} ${_?.promoter_id?.lastname}`
                }
                className="font-bold text-[14px]"
              ></Column>
              <Column
                title="Angefragt Am"
                dataIndex={"requestedAt"}
                render={(_) => moment(_).format("D.M.YYYY")}
                className="font-bold text-[14px]"
              ></Column>
              <Column
                title="Wert"
                dataIndex={"valueInEUR"}
                className="font-bold text-[14px]"
                render={(_, record) =>
                  record.isBonusClaim
                    ? `${record?.bonus_id?.name}`
                    : `${_?.toFixed(2)} EUR`
                }
              ></Column>
              <Column
                title="Freigabe"
                className="font-bold text-[14px]"
                dataIndex={"_id"}
                render={(_, __) => {
                  return (
                    <div className="flex items-center gap-3 cursor-pointer">
                      <img
                        src="/images/icons-8-anerkennung-2.png"
                        className="w-[30 px]"
                        alt=""
                        onClick={() => {
                          if (submitting) return;
                          try {
                            setSubmitting(true);

                            if (__.isBonusClaim) {
                              PramienService.answerRequestBonus(_, {
                                isRedeemed: true,
                                isRejected: false,
                              }).then(() => {
                                getRewardsList();
                              });
                            } else {
                              PramienService.answerRequest(_, {
                                isRedeemed: true,
                                isRejected: false,
                              }).then(() => {
                                getRewardsList();
                              });
                            }
                          } catch (error) {
                          } finally {
                            setSubmitting(false);
                          }
                        }}
                      />
                      <img
                        src="/images/icons-8-plus-5.png"
                        className="w-[30 px]"
                        onClick={() =>
                          PopUpComponent({
                            confirmButtonText: "Ablehnen",
                            onConfirm: async () => {
                              try {
                                if (__.isBonusClaim) {
                                  await PramienService.answerRequestBonus(_, {
                                    isRejected: true,
                                    isRedeemed: false,
                                  });
                                } else {
                                  await PramienService.answerRequest(_, {
                                    isRejected: true,
                                    isRedeemed: false,
                                  });
                                }

                                await getRewardsList();
                              } catch (error) {}
                            },
                            heading: (
                              <>
                                Möchtest du die Prämienanfrage von
                                <span className="mx-1 text-blue-main">
                                  {`${__?.promoter_id?.firstname} ${__?.promoter_id?.lastname}`}
                                </span>{" "}
                                wirklich ablehnen?
                              </>
                            ),
                            subheading: __.isBonusClaim
                              ? "Dein Promoter wird darüber informiert, das seine Bonusprämienanfrage abgelehnt wurde und er sich an Dich wenden soll."
                              : "Dein Promoter wird darüber informiert, das sein Guthaben nicht eingelöst werden konnte und er sich an Dich wenden soll.",
                          })
                        }
                        alt=""
                      />
                    </div>
                  );
                }}
              ></Column>
            </Table>

            <div className="relative pointer-events-none">
              <div className="!absolute white-overlay !top-[-80px] !bottom-0 !h-[80px] pointer-events-none !w-full" />
            </div>
          </div>
          <div className="my-2  flex items-center gap-3 text-[#868686]">
            <Drag />
            <p>Nach oben/unten ziehen um weitere Einträge anzuzeigen</p>
          </div>
        </div>
        <div className="rounded-11xl bg-[#fafafa] p-10 ">
          <h1 className="text-9xl font-semibold my-2 ">
            Liste eingelöster Prämien
          </h1>
          <div className="rounded-[20px]  max-h-[300px] min-h-[300px] bg-white border-[#D8D8D8] border-[1px]  overflow-auto">
            <Table
              className="my-2 w-full overflow-auto max-h-[300px] min-h-[300px]"
              tableLayout="auto"
              bordered={false}
              dataSource={redemmed}
              pagination={false}
              rowKey={"uid"}
            >
              <Column
                title="Name"
                render={(__, _) =>
                  `${_?.promoter_id?.firstname} ${_?.promoter_id?.lastname}`
                }
                className="font-bold text-[14px]"
              ></Column>
              <Column
                title="eingelöst am"
                dataIndex={"redeemedAt"}
                render={(_, record) =>
                  record.isBonusClaim
                    ? moment(record.createdAt).format("D.M.YYYY HH:mm")
                    : moment(_).format("D.M.YYYY HH:mm")
                }
                className="font-bold text-[14px]"
              ></Column>
              <Column
                title="Wert"
                dataIndex={"valueInEUR"}
                className="font-bold text-[14px]"
                render={(_, record) =>
                  record.isBonusClaim
                    ? `${record?.bonus_id?.name}`
                    : `${_?.toFixed(2)} EUR`
                }
              ></Column>
            </Table>
          </div>
          <div className="relative pointer-events-none">
            <div className="!absolute white-overlay !top-[-80px] !bottom-0 !h-[80px] pointer-events-none !w-full" />
          </div>
          <div className=" my-2 flex items-center gap-3 text-[#868686]">
            <Drag />
            <p>Nach oben/unten ziehen um weitere Einträge anzuzeigen</p>
          </div>
        </div>
      </div>
      <div className="sm:w-3/4 w-[99%] flex flex-col md:w-[345px] bg-blue-hearts rounded-11xl min-h-[770px]">
        <div className="text-white text-center antialiased my-2 mb-[30px]">
          <p className="text-[28px] font-semibold">Upgrade </p>
          <p className="text-[28px] font-semibold">auf Premium</p>
        </div>
        <div className="flex flex-col gap-[10px]  text-[#868686] justify-center items-center ">
          <div className="rounded-[10px]  bg-white gap-3 flex items-center p-[10px]">
            <TbBrandApple size={25} />

            <p className="whitespace-nowrap text-xs">Eigenes Branding</p>
          </div>
          <div className="rounded-[10px]  bg-white gap-3 flex items-center p-[10px]">
            <img
              className="relative w-[25px] h-[25px] overflow-hidden shrink-0"
              alt=""
              src="/images/icons-8-stern-50.png"
            />
            <p className="whitespace-nowrap text-xs">Linkindividualisierung</p>
          </div>
          <div className="rounded-[10px]  bg-white gap-3 flex items-center p-[10px]">
            <FaChartBar size={25} />
            <p className="whitespace-nowrap text-xs">Seitenstatistiken</p>
          </div>
          <div className="rounded-[10px]  bg-white gap-3 flex items-center p-[10px]">
            <img
              className="relative w-[25px] h-[25px] overflow-hidden shrink-0"
              alt=""
              src="/images/icons-8-roman-1.png"
            />{" "}
            <p className="whitespace-nowrap text-xs">Online Akademie</p>
          </div>
          <div className="rounded-[10px]  bg-white gap-3 flex items-center p-[10px]">
            <img
              className="relative w-[25px] h-[25px] overflow-hidden shrink-0"
              alt=""
              src="/images/icons-8-roboter-1.png"
            />
            <p className="whitespace-nowrap text-xs">
              Seiten Einrichtungshilfe
            </p>
          </div>

          <div className="rounded-[10px]  bg-white gap-3 flex items-center p-[10px]">
            <img
              className="w-[25px]"
              src="/images/icons-8-detailansicht-1.png"
              alt=""
            />
            <p className="whitespace-nowrap text-xs">u.v.m.</p>
          </div>
          <div className="rounded-[10px] bg-[#fafafa] p-[10px] w-[80%] m-auto ">
            <h1 className="   flex items-center gap-3 my-2 ">
              <p className=" text-md">Du vermisst etwas?</p>
            </h1>
            <h2 className="   flex items-center gap-3 my-2 ">
              <img src="/images/icons-8-geschenk-1.png" alt="" />
              <p className=" text-xs">Deine Wünsche sind uns wichtig!</p>
            </h2>
            <div className="rounded-[10px] flex justify-center items-center flex-col bg-white border-[#D8D8D8] border-[1px]  p-3">
              <textarea
                placeholder="Bitte teile uns deine Idee mit..."
                value={supportValue}
                onChange={(e) => setSupportValue(e.target.value)}
                className="w-full outline-none border-none resize-none text-xs"
              />
              <button
                onClick={async () => {
                  try {
                    await PublicService.requestSupport({
                      type: "User Suggestion",
                      email: user?.email,
                      theme: "Vorschlage",
                      message: supportValue,
                      feedback: true,

                      user_id: user?._id,
                    });
                    setSupportValue("");
                  } catch (error) {}
                }}
                className="bg-[#001D47] p-[12px_20px] rounded-[10px] text-white w-full my-2"
                type="primary"
              >
                Abschicken
              </button>

              <div>
                Jede Idee wird von uns auf Machbarkeit geprüft und bei positivem
                Ergebnis für die zukünftige Entwicklung eingeplant.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
