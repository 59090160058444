import LandingPageService from "../service/LandingPageService";
import UploadToCloudService from "../service/UploadToCloudService";
export const PROTECTED_URLS = [
  "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1731606489/zlymhflpolovpke4jq7v.png",
  "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1731609988/ofpq2fy83uixt9goicwf.png",
  "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1731609988/f0uuwcmdwbaujdlrktam.png",
  "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1732799570/bouixbxnwybqdixdby3o.png",
  "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1732799570/xzj78sx8z4aiaau8gldp.png",
  "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1732799570/h23ms4ve2xbabodu4erb.png",
  "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1732799570/npwzqryffhviqoql3r7o.png",
  "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1734719532/bg6irie1oudep6wq8pqv.png",
  "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1734719532/hfnztb4tti00yljrqsyx.png",
  "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1734719532/kvauuwajncpa3gqqkslk.png",
  "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1735641052/ncgzgpiacrp2vbxc2ug6.png",
  "https://res.cloudinary.com/dcwsmnhb9/image/upload/wnofplcp9e8juld7puzy.webP",
  "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1736197512/el6mhne8o0rfykyawncg.png",
  "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1729065431/ro7qhy4tfm6wpyh9cvan.jpg",
];

export const handleUnusedImages = async (
  landingPageId,
  updatedEditorContents
) => {
  console.log(
    "handleUnusedImages() aufgerufen mit landingPageId:",
    landingPageId
  );

  let currentImageUrls = [];
  updatedEditorContents.forEach((content, index) => {
    console.log(`Verarbeite updatedEditorContent[${index}]:`, content);
    const urls = extractImageUrls(content);
    console.log(`Extrahierte URLs aus content[${index}]:`, urls);
    currentImageUrls.push(...urls);
  });

  console.log(
    "Aktuelle Bild-URLs aus den aktualisierten Editor-Inhalten:",
    currentImageUrls
  );

  const { data } = await LandingPageService.detail(landingPageId);
  console.log("Erhaltene Daten von LandingPageService.detail:", data);

  const savedImageUrls = data.imageList || [];
  console.log("Gespeicherte Bild-URLs von der LandingPage:", savedImageUrls);

  const unusedImages = savedImageUrls.filter(
    (url) => !currentImageUrls.includes(url) && !PROTECTED_URLS.includes(url)
  );
  console.log(
    "Nicht verwendete Bilder, die gelöscht werden sollen:",
    unusedImages
  );

  await deleteUnusedImages(unusedImages);

  console.log("Alle nicht verwendeten Bilder wurden verarbeitet.");

  return currentImageUrls;
};

const extractImageUrls = (htmlContent) => {
  const imgTagRegex = /<img[^>]+src="([^">]+)"[^>]*>/g;
  const imageUrls = [];
  let match;

  while ((match = imgTagRegex.exec(htmlContent))) {
    imageUrls.push(match[1]);
  }

  console.log("extractImageUrls() hat folgende URLs extrahiert:", imageUrls);

  return imageUrls;
};

const deleteUnusedImages = async (unusedImages) => {
  console.log(
    "deleteUnusedImages() aufgerufen mit folgenden Bildern:",
    unusedImages
  );

  for (const imageUrl of unusedImages) {
    const publicId = getPublicIdFromUrl(imageUrl);
    console.log(`Lösche Bild mit publicId: ${publicId}, URL: ${imageUrl}`);
    try {
      const result = await UploadToCloudService.deleteBase64Image(publicId);
      console.log(`Erfolgreich gelöscht: ${imageUrl}`, result);
    } catch (error) {
      console.error(`Fehler beim Löschen von ${imageUrl}:`, error);
    }
  }
};

const getPublicIdFromUrl = (url) => {
  const urlParts = url.split("/");
  const fileName = urlParts.pop();
  const [publicId] = fileName.split(".");
  console.log(
    `getPublicIdFromUrl() extrahiert publicId: ${publicId} aus URL: ${url}`
  );
  return publicId;
};
