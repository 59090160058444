import { Alert, Button, Checkbox, Radio, Space, message } from "antd";
import { Check, X } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/auth/selectors";
import PublicService from "../../service/PublicService";
import SubscriptionService from "../../service/SubscriptionService";
import UserService from "../../service/UserService";
import { features, tiers } from "../Home/MakeYouFit";
import { useScreenWidth } from "../LandingPageConfig/useScreenWidth";

const prices = {
  Basic: { twelveMonths: 39, twelveMonths2: 55 },
  Standard: { twelveMonths: 79, twelveMonths2: 105 },
  Premium: { twelveMonths: 149, twelveMonths2: 199 },
};

export default function Component({ title }) {
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState();
  const [checkboxFailed, setCheckboxFailed] = useState(false);
  const [selectedTier, setSelectedTier] = useState("Standard");
  const [checkbox1, setCheckbox1] = useState(false);
  const [config, setConfig] = useState({});
  const { width } = useScreenWidth();
  const [pricingPeriod, setPricingPeriod] = useState("annualPrice");

  const getAdminSetting = async () => {
    const { data } = await PublicService.getCMS();
    setConfig(data);
  };

  useEffect(() => {
    getAdminSetting();
  }, []);

  const getSubscriptionInfo = async () => {
    const { data } = await SubscriptionService.getSubscriptionInfo();
    console.log(data);
    setSubscription(data?.subscription);
  };

  React.useEffect(() => {
    title("Upgrade");
    setTimeout(() => title("Upgrade"), 200);
    getSubscriptionInfo();
  }, [title]);

  const { user } = useSelector(selectUser);

  useEffect(() => {
    if (user?.subscription?.paid) {
      navigate("/dashboard/profile");
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight + 5000);
      }, 1000);
    }
  }, [user, navigate]);

  const handleTierChange = (e) => {
    setSelectedTier(e.target.value);
  };

  const handleSubscribe = async () => {
    if (!checkbox1) {
      setCheckboxFailed(true);
      return;
    }

    const { data } = await SubscriptionService.subscribe(
      selectedTier,
      `${window.location.origin}/dashboard`,
      pricingPeriod
    );

    window.location.href = data.paymentLink;
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      {config?.CMS?.enableUpgradeVideo && (
        <div className="flex flex-col items-center justify-center  w-full">
          {config?.CMS?.upgradeVideoText && (
            <p
              className="mb-2 text-xl font-bold text-center"
              dangerouslySetInnerHTML={{
                __html: config?.CMS?.upgradeVideoText,
              }}
            />
          )}

          <video
            playsInline
            controls
            className="aspect-video w-full rounded-sm object-cover shadow-lg bg-black bg-opacity-50 p-1"
            poster={config.CMS.upgradeVideoThumbnail}
            style={{ cursor: "pointer" }}
          >
            <source
              src={config?.CMS?.upgradeVideoAtSubscriptionPage}
              type="video/mp4"
            />
            <source
              src={config?.CMS?.upgradeVideoAtSubscriptionPage}
              type="video/webm"
            />
            <source
              src={config?.CMS?.upgradeVideoAtSubscriptionPage}
              type="video/quicktime"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      )}

      <h1 className="text-2xl font-bold text-center mb-6 mt-6">
        Wähle Deine Zahlweise
      </h1>

      <div className="flex mt-6 mb-8 justify-center w-full rounded-[15px] relative transition-all">
        <div className="relative flex">
          <div
            onClick={() => setPricingPeriod("annualPrice")}
            className={`${
              pricingPeriod === "annualPrice"
                ? "bg-[#001D47]"
                : "bg-[#fafafa] !text-[#000000]"
            } text-center text-white text-base  cursor-pointer px-5 py-4  rounded-s-[15px] duration-300 transition-all`}
          >
            Jährlich
          </div>
          <div
            onClick={() => setPricingPeriod("monthlyPrice")}
            className={`${
              pricingPeriod === "monthlyPrice"
                ? "bg-[#001D47]"
                : "bg-[#fafafa] !text-[#000000]"
            } text-center text-white text-base  cursor-pointer px-5 py-4 rounded-e-[15px] duration-300  transition-all `}
          >
            Monatlich
          </div>

          <img
            src="/images/Group 3365 (1).png"
            className="absolute left-[-70px]"
            alt=""
          />
        </div>
      </div>

      <h1 className="text-2xl font-bold text-center mb-6 mt-6">
        Wähle Deinen Tarif
      </h1>

      <div className="mb-8 flex flex-col items-center gap-4">
        <Radio.Group
          size="large"
          onChange={handleTierChange}
          value={selectedTier}
        >
          <Radio.Button value="Basic">Basic</Radio.Button>
          <Radio.Button value="Standard">Standard</Radio.Button>
          <Radio.Button value="Premium">Premium</Radio.Button>
        </Radio.Group>

        <div className="flex items-center gap-2">
          <Checkbox
            checked={checkbox1}
            onChange={(e) => setCheckbox1(e.target.checked)}
          />
          <span className="text-sm">
            Ich akzeptiere die AGB's von EinGuterTipp
          </span>
        </div>

        <div className="text-center">
          <div className="text-2xl font-bold mb-2">
            {Math.ceil(
              pricingPeriod === "monthlyPrice"
                ? parseFloat(prices[selectedTier].twelveMonths2)
                : parseFloat(prices[selectedTier].twelveMonths)
            )
              .toFixed(2)
              .replace(".", ",")}
            € mtl.{" "}
          </div>
          <div className="text-md font-semibold mb-2">
            {pricingPeriod === "annualPrice"
              ? "(bei jährlicher Abrechnung)"
              : ""}
          </div>
          <Button
            type="primary"
            className="w-full max-w-xs h-10"
            onClick={handleSubscribe}
            size={width < 767 ? "small" : "middle"}
          >
            Abonnieren
          </Button>
        </div>

        {checkboxFailed && (
          <Alert
            type="error"
            message="Du musst allen Checkboxen zustimmen"
            className="w-full max-w-xs"
          />
        )}
      </div>

      <div className="rounded-[20px]  bg-[#0184f8] min-w-[300px] overflow-auto smx:pl-2">
        {/* Table Header */}
        <div className="grid grid-cols-4 mdx:grid-cols-[200px_100px_100px_100px] min-w-[600px]">
          <div className="p-6 mdx:px-1 pb-0 mdx:min-w-[200px]">
            <h1 className="text-2xl mdx:text-lg font-semibold text-white">
              Hauptlizenz
            </h1>
            <h2 className="text-xl mdx:text-md font-bold mt-8 !text-[#000000]">
              Features
            </h2>
          </div>
          {tiers.map((tier) => (
            <div key={tier.name} className=" ">
              <h3 className="font-bold text-white w-[200px] mdx:w-[80px] mx-2 mdx:mx-auto mt-8 mb-2 text-center ">
                {tier.name}
              </h3>
              <div className="bg-[#001d47] w-[200px] mdx:w-[80px] mdx:mx-auto rounded-t-[20px] p-6   mx-2">
                <div className="text-white space-y-1">
                  <p className="!text-[12px] font-bold flex justify-center whitespace-nowrap mdx:text-sm">
                    {Math.ceil(
                      pricingPeriod === "monthlyPrice"
                        ? parseFloat(tier.price2.replace("€", ""))
                        : parseFloat(tier.price.replace("€", ""))
                    )
                      .toFixed(2)
                      .replace(".", ",")}
                    €*{pricingPeriod === "monthlyPrice" ? "*" : ""} mtl.
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Features */}
        <div className="min-w-[600px]">
          {features.map((feature) => (
            <div
              key={feature.name}
              className="grid grid-cols-4 mdx:grid-cols-[200px_100px_100px_100px]"
            >
              <div className="px-6 mdx:px-1 py-1 text-white whitespace-nowrap">
                {feature.name}
              </div>
              {[feature.basic, feature.standard, feature.premium].map(
                (value, index) => (
                  <div
                    key={index}
                    className="bg-[#001d47] px-4 mdx:px-1 w-[200px] mdx:w-[80px] mdx:mx-auto py-1 mx-2 flex justify-center"
                  >
                    {typeof value === "boolean" ? (
                      value ? (
                        <Check className="w-6 h-6 text-green-400" />
                      ) : (
                        <X className="w-6 h-6 text-red-400" />
                      )
                    ) : (
                      <span className="text-white font-medium">{value}</span>
                    )}
                  </div>
                )
              )}
            </div>
          ))}
        </div>

        {/* Footer */}
        <div className="grid grid-cols-4 mdx:grid-cols-[200px_100px_100px_100px] min-w-[600px]">
          <div className="p-4" />
          {[1, 2, 3].map((i) => (
            <div
              key={i}
              className="bg-[#001d47] w-[200px] mdx:w-[80px] mdx:mx-auto rounded-b-[20px] mx-2 p-4"
            />
          ))}
        </div>
        <div className="p-4 text-sm text-[#000000] text-center font-bold min-w-[600px] ml-[215px]">
          <div>
            **Preise zzgl. Mehrwertsteuer und 3 Monate Kündigungsfrist zum Ende
            des jährlichen Abrechnungszeitraums
          </div>
          <div>
            *Preise zzgl. Mehrwertsteuer und 1 Monat Kündigungsfrist zum Ende
            des monatlichen Abrechnungszeitraums
          </div>
        </div>
      </div>
    </div>
  );
}
