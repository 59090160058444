const WhatsappButtonConfig = ({
  text,
  className,
  type,
  changeColor,
  style,
  shadowGradient,
}) => {
  let roundedStyle = "";
  console.log(type);
  switch (type) {
    case "finance":
    case "individual":
      roundedStyle = "rounded-full";
      break;
    case "stormgas":
      roundedStyle = "rounded-0";
      break;
    case "property":
      roundedStyle = "rounded-sm";
      break;
    case "basic":
      roundedStyle = "rounded-full md:!text-sm";
      break;
  }

  return (
    <div className="flex justify-center">
      <a className={className}>
        <div
          style={{
            position: "absolute",
            top: "-8px",
            left: "-22px",
            right: "-22px",
            bottom: "-8px",
            borderRadius: "50%",
            background: shadowGradient,
            filter: "blur(20px)",
            zIndex: -1,
          }}
        />
        <button
          onClick={changeColor}
          className={`
              ${roundedStyle}
              gap-4 
              items-center 
              justify-center 
              font-semibold 
              text-white 
              flex 
              text-sm
              py-4
              px-7
              md:text-xl
              md:py-4
              md:px-10 
              mx-auto 
            `}
          style={style}
        >
          <img
            className="h-[24px] md:h-[25px]"
            src="/svg/whatsapp.svg"
            alt=""
          />
          {text}
        </button>
      </a>
    </div>
  );
};

export default WhatsappButtonConfig;
