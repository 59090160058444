import { Button, Checkbox, message, Modal, Spin, Tooltip } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { FiCheckCircle } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectUser } from "../../../redux/auth/selectors";
import AnalyticService from "../../../service/AnalyticService";
import CrudService from "../../../service/CrudService";
import LandingPageService from "../../../service/LandingPageService";
import QuestionService from "../../../service/QuestionService";
import { PopUpComponent } from "../../ConfirmPopup";
import StatisticsContent from "./StatisticsContent";

const LandingPageCard = ({
  data,
  getLandingPages,
  IsOldPage,
  landingPages,
  loadingStandard,
  setLodingStandard,
}) => {
  const [stats, setStats] = useState();
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);
  const [loadingMigration, setLoadingMigration] = useState(false);
  const [analyticsData, setAnalyticsdata] = useState({
    pageview: 0,
    click_wa1: 0,
    click_wa2: 0,
    click_wa3: 0,
    click_qf: 0,
    click_q1: 0,
    click_q2: 0,
    click_q3: 0,
    click_q4: 0,
    click_q5: 0,
    click_q6: 0,
    click_q7: 0,
    click_q8: 0,
    click_recall: 0,
    play_v1: 0,
    play_v2: 0,
    watchtime_v1: 0,
    watchtime_v2: 0,
  });
  const [waActivated, setWaActivated] = useState(true);
  const [closingVideoActivated, setClosingVideoActivated] = useState(true);
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  const [questionLength, setQuestionLength] = useState(0);
  const [deleteChecked, setDeleteChecked] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { user } = useSelector(selectUser);
  const [range, setRange] = useState("alle");
  const navigate = useNavigate();
  const cacheAnalyticsData = (landingPageId, data, range) => {
    const cacheKey = `analytics_${landingPageId}_${range}`;
    const cachedData = {
      data,
      timestamp: Date.now(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
  };

  const getCachedAnalyticsData = (landingPageId, range) => {
    const cacheKey = `analytics_${landingPageId}_${range}`;
    const cachedData = localStorage.getItem(cacheKey);
    if (!cachedData) return null;

    const parsedData = JSON.parse(cachedData);
    const cacheDuration = 2 * 60 * 60 * 1000;

    if (Date.now() - parsedData.timestamp < cacheDuration) {
      return parsedData.data;
    }

    return null;
  };

  // useEffect(() => {
  //   const relevantKeys = [
  //     "accompanyingTextOne",
  //     "introVideoHeader",
  //     "introductoryText",
  //     "accompanyingTextTwo",
  //     "accompanyingTextThree",
  //     "questionaireIntroductionText",
  //   ];

  //   const clearedFields = relevantKeys.reduce(
  //     (acc, key) => ({
  //       ...acc,
  //       [key]: "",
  //     }),
  //     {}
  //   );
  //   const update = async () => {
  //     const da = await LandingPageService.update(data._id, {
  //       ...data,
  //       ...clearedFields,
  //     });
  //     console.log(da);
  //   };

  //   if (data._id === "66d75db3a228d1a651661111") {
  //     update();
  //   }
  // }, []);

  const handleStandard = useCallback(async () => {
    if (!landingPages) return;
    if (loadingStandard) return;
    try {
      setLodingStandard(true);
      if (data.isStandard) {
        await CrudService.update("Landingpages", data._id, {
          isStandard: false,
        });
      } else {
        if (landingPages?.some?.((l) => l.isStandard)) {
          setLodingStandard(false);
          return message.error(
            "Ein anderer Link ist bereits als Standard markiert"
          );
        }
        await CrudService.update("Landingpages", data._id, {
          isStandard: true,
        });
      }

      await getLandingPages(true);
    } catch (e) {
    } finally {
      setLodingStandard(false);
    }
  }, [data, landingPages, loadingStandard]);

  const handleDuplicate = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        let isLoading = false; // Ladezustand als Variable

        const handleConfirm = async () => {
          const confirmButton = document.getElementById("confirmButton");
          isLoading = true;
          confirmButton.disabled = true;
          confirmButton.innerText = "Wird dupliziert...";

          try {
            await LandingPageService.duplicate(data._id);
            await getLandingPages();
            onClose();
          } catch (error) {
            console.error("Fehler beim Duplizieren:", error);
            confirmButton.disabled = false;
            confirmButton.innerText = "Duplizieren";
          } finally {
            isLoading = false;
          }
        };

        return (
          <div
            style={{ zIndex: 900000000000000 }}
            className="white-text-div-folder bg-white rounded-2xl p-[20px_30px] flex flex-col justify-center items-center gap-5 md:w-[600px]  w-[90%] m-auto relative"
          >
            <div
              className="absolute w-[48px] h-[48px] rounded-[10px] right-[-12px] top-[-12px] bg-white flex justify-center items-center border border-[#d6d6d6] cursor-pointer"
              onClick={onClose}
            >
              <MdOutlineClose fontSize={30} />
            </div>

            <div>
              <h5>{`Möchtest du die Landingpage "${data.name}" duplizieren?`}</h5>
              <p className="my-1">
                Durch das Duplizieren dieser Landingpage wird eine exakte Kopie
                erstellt. Die Kopie enthält alle zugehörigen Daten wie Reviews
                und Fragen.
              </p>
            </div>

            <div className="flex flex-col items-center justify-between w-full gap-1 sm:flex-row sm:flex-nowrap">
              <button
                id="confirmButton"
                className="bg-redattendtion rounded-[10px] text-white h-[46px] w-[250px]"
                onClick={handleConfirm}
              >
                Duplizieren
              </button>
              <Button
                type="primary"
                className="h-[46px] w-[250px] rounded-[10px] text-white"
                onClick={onClose}
              >
                Abbrechen
              </Button>
            </div>
          </div>
        );
      },
    });
  };

  useEffect(() => {
    const getLandingPages = async () => {
      const { data: statdata } = await LandingPageService.getStats(data?._id);
      setStats(statdata);
    };

    const getLPData = async () => {
      const detailLP = await LandingPageService.detail(data?._id);
      const { activateClosingVideo, activateWhatsApp } = detailLP.data;
      console.log(activateWhatsApp);
      if (!activateClosingVideo) {
        setClosingVideoActivated(false);
      }
      if (!activateWhatsApp) {
        setWaActivated(false);
      }
    };

    const getQuestionLength = async () => {
      const questions = await QuestionService.listQuestions(data?._id);
      setQuestionLength(questions.data.length);
    };
    getQuestionLength();
    getLandingPages();
    getLPData();
  }, [data]);

  const cleanupCache = () => {
    const cachePrefix = "analytics_";
    const keys = Object.keys(localStorage);
    const now = Date.now();
    const cacheDuration = 2 * 60 * 60 * 1000;

    keys.forEach((key) => {
      if (key.startsWith(cachePrefix)) {
        const cachedData = JSON.parse(localStorage.getItem(key));
        if (now - cachedData.timestamp > cacheDuration) {
          localStorage.removeItem(key);
        }
      }
    });
  };

  useEffect(() => {
    const getGoogleAnalyticsStatistic = async () => {
      cleanupCache();
      setLoadingAnalytics(true);
      try {
        const cachedData = getCachedAnalyticsData(data?._id, range);
        if (
          cachedData &&
          Object.values(cachedData).some((value) => value > 0)
        ) {
          setAnalyticsdata(cachedData);
          setLoadingAnalytics(false);
          return;
        }

        const response = await AnalyticService.getUserAnalytics(
          data?._id,
          range
        );

        console.log(response.data);
        if (response?.status === 200 && response.data?.length) {
          const analyticsData1 = response.data;
          const pageView =
            parseInt(analyticsData1[18]?.page_viewLP) +
              parseInt(analyticsData1[17]?.page_view) || 1;

          const newAnalyticsData = {
            click_wa1: Math.round(
              ((analyticsData1[0]?.click_wa1 || 0) / pageView) * 100
            ),
            click_wa2: Math.round(
              ((analyticsData1[1]?.click_wa2 || 0) / pageView) * 100
            ),
            click_wa3: Math.round(
              ((analyticsData1[2]?.click_wa3 || 0) / pageView) * 100
            ),
            click_qf: Math.round(
              ((analyticsData1[3]?.click_qf || 0) / pageView) * 100
            ),
            click_recall: Math.round(
              ((analyticsData1[4]?.click_recall || 0) / pageView) * 100
            ),
            click_q1: Math.round(
              ((analyticsData1[5]?.click_q0 || 0) / pageView) * 100
            ),
            click_q2: Math.round(
              ((analyticsData1[6]?.click_q1 || 0) / pageView) * 100
            ),
            click_q3: Math.round(
              ((analyticsData1[7]?.click_q2 || 0) / pageView) * 100
            ),
            click_q4: Math.round(
              ((analyticsData1[8]?.click_q3 || 0) / pageView) * 100
            ),
            click_q5: Math.round(
              ((analyticsData1[9]?.click_q4 || 0) / pageView) * 100
            ),
            click_q6: Math.round(
              ((analyticsData1[10]?.click_q5 || 0) / pageView) * 100
            ),
            click_q7: Math.round(
              ((analyticsData1[11]?.click_q6 || 0) / pageView) * 100
            ),
            click_q8: Math.round(
              ((analyticsData1[12]?.click_q7 || 0) / pageView) * 100
            ),
            play_v1: Math.round(
              ((analyticsData1[13]?.play_v1 || 0) / pageView) * 100
            ),
            play_v2: Math.round(
              ((analyticsData1[14]?.play_v2 || 0) / pageView) * 100
            ),
            watchtime_v1: analyticsData1[15]?.watchtime_v1 || 0,
            watchtime_v2: analyticsData1[16]?.watchtime_v2 || 0,
            pageview: pageView,
          };

          setAnalyticsdata(newAnalyticsData);

          if (Object.values(newAnalyticsData).some((value) => value > 0)) {
            cacheAnalyticsData(data?._id, newAnalyticsData, range);
          }
        } else {
          setAnalyticsdata({
            pageview: 0,
            click_wa1: 0,
            click_wa2: 0,
            click_wa3: 0,
            click_qf: 0,
            click_q1: 0,
            click_q2: 0,
            click_q3: 0,
            click_q4: 0,
            click_q5: 0,
            click_q6: 0,
            click_q7: 0,
            click_q8: 0,
            click_recall: 0,
            play_v1: 0,
            play_v2: 0,
            watchtime_v1: 0,
            watchtime_v2: 0,
          });
        }
      } catch (error) {
        console.error("Error fetching analytics data", error);
      } finally {
        setLoadingAnalytics(false);
      }
    };

    if (data?._id) {
      getGoogleAnalyticsStatistic();
    }
  }, [data?._id, range]);

  const handleMigration = async () => {
    if (IsOldPage) return;

    setLoadingMigration(true);
    try {
      await PopUpComponentMigration({
        confirmButtonText: "Übertragen",
        heading: (
          <>
            Hiermit überträgst du deine Landingpage{" "}
            <span className="mx-1 text-blue-main">"{data.name}"</span> in das
            neue Format. Das erfordert unbedingt eine Überarbeitung der
            Textbausteine! Deine Bilder, Videos und Testemonials werden
            übernommen.
          </>
        ),
        onConfirm: async () => {
          try {
            await LandingPageService.update(data._id, {
              ...data,
              migrateToNewFormat: true,
            });
            navigate(`/landingpageconfig/${data?.theme}/${data._id}`);
            await getLandingPages();
          } catch (error) {
            console.error("Migration failed:", error);
          }
        },
        subheading: ({ confirmationChecked, onConfirmationChange }) => (
          <div className="space-y-4">
            <p>Dieser Vorgang kann nicht rückgängig gemacht werden.</p>
            <div className="flex items-center gap-2">
              <Checkbox
                checked={confirmationChecked}
                onChange={onConfirmationChange}
              />
              <span>
                Ich habe verstanden und möchte die Landingpage{" "}
                <span className="mx-1 text-blue-main">"{data.name}"</span>
                jetzt übertragen
              </span>
            </div>
          </div>
        ),
      });
    } catch (error) {
      console.error("Migration failed:", error);
    } finally {
      setLoadingMigration(false);
    }
  };
  const decodeHtmlEntities = (text) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  };

  return (
    <div className="w-full gap-6 primary:flex-nowrap flex-wrap-reverse flex p-5 rounded-mini bg-white box-border border-[1px] border-solid border-grey-accent">
      <div className="w-full grid gap-3 ">
        <div className="font-semibold flex justify-start items-center gap-2">
          <Tooltip
            title="Hier markierst Du eine Deiner Landing Pages als Standard und bestimmst damit die für den QR-Code hinterlegte Seite."
            className="cursor-pointer"
            onClick={handleStandard}
          >
            {loadingStandard ? (
              <Spin>
                <FiCheckCircle
                  size={20}
                  className="!w-[20px]"
                  color={data.isStandard ? "#40c945" : "#ff0000"}
                />
              </Spin>
            ) : (
              <FiCheckCircle
                size={20}
                className="!w-[20px]"
                color={data.isStandard ? "#40c945" : "#ff0000"}
              />
            )}
          </Tooltip>
          <div className="flex flex-1 text-left ">
            {data?.name ? decodeHtmlEntities(data.name) : ""}
          </div>
        </div>
        <div className="flex gap-3 xl:flex-nowrap flex-wrap items-center lg:justify-start justify-center">
          <div className="sm:flex-nowrap flex-wrap  md:grid grid-cols-[2fr_2fr_1fr_1fr] flex flex-row items-center justify-between secondary:w-[60%] tertiary:w-[40%] sm:gap-[2.5rem] gap-[10px] text-[0.88rem] text-text-sec">
            <div className="flex flex-col text-nowrap items-start  justify-start gap-[0.63rem]">
              <div className=" font-medium">Erstellt Am</div>
              <div className="flex flex-col   items-start justify-start text-[14px] sm:text-[1rem] text-text-prim">
                <div className=" font-semibold">
                  {moment(data.createdAt).format("D.M.YYYY")}
                </div>
              </div>
            </div>
            <div className=" flex flex-col text-nowrap items-start  justify-start gap-[0.63rem]">
              <div className=" font-medium">Geändert Am</div>
              <div className="flex flex-col   items-start justify-start text-[14px] sm:text-[1rem] text-text-prim w-full">
                <div className=" font-semibold">
                  {moment(data.updatedAt).format("D.M.YYYY")}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[0.63rem] flex-shrink">
              <div className=" font-medium">Aufrufe</div>
              <div className="flex flex-col items-start justify-start text-[14px] sm:text-[1rem] text-text-prim">
                <div className=" font-semibold">{analyticsData.pageview}</div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start gap-[0.63rem] flex-basis-[10%] flex-shrink">
              <div className=" font-medium">Statistik</div>
              <div className="flex flex-col   items-start justify-start text-[14px] sm:text-[1rem] text-text-prim">
                <div className=" font-semibold">
                  <Tooltip
                    overlayClassName="max-w-[100%] bg-blue-main"
                    placement="bottom"
                    title={
                      <StatisticsContent
                        range={range}
                        setRange={setRange}
                        analyticsData={analyticsData}
                        loadingAnalytics={loadingAnalytics}
                        waActivated={waActivated}
                        closingVideoActivated={closingVideoActivated}
                        questionLength={questionLength}
                        user={user}
                      />
                    }
                  >
                    <Button className="size-5">i</Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-auto w-full md:flex-nowrap font-semibold flex-wrap gap-[1.25rem]  flex flex-row items-center justify-center  text-[1rem] text-white">
            <Tooltip
              title={
                !IsOldPage
                  ? `Es ist ein neuerer Landingpage-Konfigurator verfügbar!
Erstelle eine neue Landingpage und genieße ganz neue Möglichkeiten. Du kannst deine alte Landingpage in das neue Format übertragen.`
                  : ""
              }
            >
              <div
                className={`m-auto rounded-3xs bg-[#001D47] overflow-hidden flex flex-col py-[0.91rem] px-[1.25rem] items-center justify-center transition-opacity duration-300 ${
                  !IsOldPage ? "opacity-50" : "cursor-pointer"
                }`}
              >
                <Link
                  to={`/landingpageconfig/${data?.theme}/${data._id}`}
                  className={`${
                    !IsOldPage ? "pointer-events-none" : ""
                  } transition-all duration-300`}
                  onClick={!IsOldPage ? (e) => e.preventDefault() : undefined}
                >
                  Konfigurieren
                </Link>
              </div>
            </Tooltip>

            <div
              className="m-auto rounded-3xs cursor-pointer bg-blue-main overflow-hidden flex flex-col py-[0.91rem] px-[1.25rem] items-center justify-center text-center"
              onClick={handleDuplicate}
            >
              {loadingDuplicate ? (
                <div className="flex items-center gap-2">
                  <Spin size="small" />
                  <span>Wird dupliziert...</span>
                </div>
              ) : (
                "Duplizieren"
              )}
            </div>

            {!IsOldPage && (
              <div
                className="m-auto rounded-3xs cursor-pointer bg-[#4CAF50] overflow-hidden flex flex-col py-[0.91rem] px-[1.25rem] items-center justify-center"
                onClick={handleMigration}
              >
                {loadingMigration ? (
                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    <span>Übertragen...</span>
                  </div>
                ) : (
                  "Übertragen"
                )}
              </div>
            )}

            <div
              className=" m-auto rounded-3xs cursor-pointer bg-[#E81900] overflow-hidden flex flex-col py-[0.91rem] px-[1.25rem] items-center justify-center text-center"
              onClick={() =>
                PopUpComponent({
                  confirmButtonText: "Löschen",
                  onConfirm: async () => {
                    setDeleteLoading(true);
                    await LandingPageService.deleteLandingPage(data._id);
                    await getLandingPages();
                    setDeleteLoading(false);
                    try {
                    } catch (error) {}
                  },
                  heading: (
                    <>
                      Möchtest du deine Landingpage
                      <span className="mx-1 text-blue-main">
                        “{data.name}”
                      </span>{" "}
                      wirklich löschen?
                    </>
                  ),
                  subheading: (
                    <div className="text-red-500 font-bold">
                      Wenn du dich dazu entscheidest, diese Landingpage zu
                      löschen, werden auch alle zu dieser Seite gehörenden
                      Promolinks in den Promoterkarten gelöscht. Dieser Vorgang
                      ist endgültig und kann NICHT rückgängig gemacht werden!
                    </div>
                  ),
                  checkbox: (
                    <Checkbox
                      checked={deleteChecked}
                      onChange={(e) => console.log(e.target.checked)}
                    >
                      Ich bin mir folgendem bewusst und möchte fortfahren
                    </Checkbox>
                  ),
                  loading: deleteChecked,
                  checked: deleteLoading,
                })
              }
            >
              Löschen
            </div>
          </div>
        </div>
      </div>
      <div
        className="m-auto cursor-pointer transition-all image-overlay-eye-container relative w-full xl:w-[250px] h-[118px] overflow-hidden"
        onClick={() => {
          window.open(
            `/landingpage/${data?.theme}/${data._id}/viewmode`,
            "_blank"
          );
        }}
      >
        <img
          className="w-full xl:w-[250px] h-[118px] rounded-xl object-cover object-[10%_50%]"
          alt=""
          src={data?.background_image}
        />
        <img
          className="animate-fade  image-overlay-eye inset-0  w-full aspect-[9/16]   xl:w-[250px]  h-[118px] bg-[rgba(255,255,255,0.4)] absolute rounded-xl  "
          alt=""
          src="/images/overlay-eye-img.svg"
        />
      </div>
    </div>
  );
};

export default LandingPageCard;

export const PopUpComponentMigration = ({
  heading,
  subheading,
  confirmButtonText = "Bestätigen",
  onConfirm,
}) => {
  return new Promise((resolve) => {
    const modal = Modal.confirm({
      title: heading,
      content: (
        <MigrationContent
          subheading={subheading}
          onStateChange={(checked) => {
            modal.update({
              okButtonProps: {
                disabled: !checked,
                style: {
                  opacity: !checked ? 0.5 : 1,
                  cursor: !checked ? "not-allowed" : "pointer",
                  color: "#fff",
                  backgroundColor: "#4CAF50",
                  borderColor: "#4CAF50",
                },
              },
            });
          }}
        />
      ),
      okText: confirmButtonText,
      okButtonProps: {
        disabled: true,
        style: {
          opacity: 0.5,
          cursor: "not-allowed",
          color: "#fff",
          backgroundColor: "#4CAF50",
          borderColor: "#4CAF50",
        },
      },
      cancelText: "Abbrechen",
      onOk: () => {
        if (onConfirm) {
          onConfirm();
        }
        resolve(true);
      },
      onCancel: () => resolve(false),
      width: 600,
      maskClosable: false,
    });
  });
};

const MigrationContent = ({ subheading, onStateChange }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (e) => {
    const newChecked = e.target.checked;
    setChecked(newChecked);
    onStateChange(newChecked);
  };

  return (
    <div className="space-y-4">
      {typeof subheading === "string" ? (
        <p>{subheading}</p>
      ) : (
        subheading({
          confirmationChecked: checked,
          onConfirmationChange: handleChange,
        })
      )}
    </div>
  );
};
