import { Button } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MainLogo } from "../../assets/Svgs/GUTERTIPP.js";

import PublicService from "../../service/PublicService.js";
import MovingHearts from "../MovingHearts/MovingHearts.jsx";
import LoadingScreen from "../_NEW/LandingPage/loadingScreen/LoadingScreen.jsx";

const PromoterEmailVerification = () => {
  const { prompter_id } = useParams();
  const canvasRef = useRef();
  const navigate = useNavigate();
  const [pressed, setPressed] = useState(false);
  const [loading, setLoading] = useState(false);
  const verifiyEmail = useCallback(async () => {
    setPressed(true);
    setLoading(true);
    try {
      await PublicService.erlaubnisPromoter(prompter_id)
        .then(() => navigate("/"))
        .catch(() => setPressed(false));

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [prompter_id]);

  // useEffect(() => {
  //   const canv = canvasRef.current;
  //   canv.width = window.innerWidth;
  //   canv.height = window.innerHeight;

  //   window.addEventListener("resize", () => {
  //     canv.width = window.innerWidth;
  //     canv.height = window.innerHeight;
  //   });

  //   const c = canv.getContext("2d");
  //   class Circle {
  //     constructor(x, y, radius, dx, dy, fill) {
  //       this.x = x;
  //       this.y = y;
  //       this.radius = radius;
  //       this.dx = dx;
  //       this.dy = dy;
  //       this.fill = fill;
  //     }
  //     draw() {
  //       c.beginPath();
  //       c.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
  //       c.strokeStyle = "blue";
  //       c.stroke();
  //       if (this.fill) {
  //         c.fillStyle = this.fill;
  //       }
  //       c.fill();
  //     }
  //     update() {
  //       if (this.x + this.radius > window.innerWidth || this.x < 0) {
  //         this.dx = -this.dx;
  //       }
  //       this.x += this.dx;
  //       if (this.y + this.radius > window.innerHeight || this.y < 0) {
  //         this.dy = -this.dy;
  //       }

  //       this.y += this.dy;
  //       this.draw();
  //     }
  //   }
  //   const getRandomColors = () => {
  //     const colors = ["#00a5ef", "#00a5ba", "#002459"];
  //     return colors[Math.floor(Math.random() * 3 - 0.1)];
  //   };
  //   const circlesCollection = [];
  //   for (let i = 0; i < 30; i++) {
  //     const x = Math.random() * window.innerWidth;
  //     const y = Math.random() * window.innerHeight;
  //     const radius = Math.floor(Math.random() * 10 + 5);
  //     const dx = Math.ceil(Math.random() * 2);
  //     const dy = Math.ceil(Math.random() * 2);
  //     const color = getRandomColors();
  //     circlesCollection.push(new Circle(x, y, radius, dx, dy, color));
  //   }

  //   const animate = () => {
  //     c.clearRect(0, 0, window.innerWidth, window.innerHeight);
  //     requestAnimationFrame(animate);
  //     circlesCollection.forEach((e) => e.update());
  //   };
  //   animate();
  //   return () => {
  //     window.removeEventListener("resize", () => {
  //       canv.width = window.innerWidth;
  //       canv.height = window.innerHeight;
  //     });
  //   };
  // }, []);

  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <div>
      {/* <canvas id="canvas" ref={canvasRef} className="absolute top-0 left-0" /> */}
      <MovingHearts />
      <div className="flex justify-center items-center flex-col text-center h-full relative z-40">
        <style>
          {`
            #root{
                position:relative;
                height:100vh;
                display:flex;
                justify-content:center;
                align-items:center;
                overflow:hidden
                
            }
            
            
            
            `}
        </style>
        <MainLogo
          className="brightness-125 mx-auto transition-all sm:scale-[2.5] scale-[2]"
          style={{
            margin: "2rem auto",
          }}
        />
        <Button
          onClick={verifiyEmail}
          type="primary"
          className="w-full h-[50px]"
          loading={loading}
          disabled={pressed}
        >
          E-Mail bestätigen
        </Button>
      </div>
    </div>
  );
};

export default PromoterEmailVerification;
