import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class PramienService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/pramien`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });
    middleField(this.api);
  }

  getPramienConfiguration() {
    return this.api.get("/getPramienConfiguration");
  }

  setPramienConfiguration(configuration) {
    return this.api.put("/setPramienConfiguration", configuration);
  }

  getRequests() {
    return this.api.get("/getRequests");
  }

  answerRequest(id, answer) {
    return this.api.put(`/answerRequest?id=${id}`, answer);
  }
  answerRequestBonus(id, answer) {
    return this.api.put(`/answerRequestBonus?id=${id}`, answer);
  }

  listRedeemed() {
    return this.api.get("/listRedeemed");
  }

  listRejected() {
    return this.api.get("/listRejected");
  }
  manuellBonus({ promoter_id, valueInEUR }) {
    return this.api.post("/manuellBonus", {
      promoter_id,
      valueInEUR,
      baseUrl: window.location.origin,
    });
  }
}

export default new PramienService();
