import { Button, message } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { FaPlayCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../../redux/auth/selectors";
import PublicService from "../../service/PublicService";
import SubscriptionService from "../../service/SubscriptionService";
import UserService from "../../service/UserService";

const Welcome = ({ title }) => {
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(null);
  const [adminTestPhase, setAdminTestPhase] = useState(null);
  const [config, setConfig] = useState({});
  const [videoWatched, setVideoWatched] = useState(
    process.env.REACT_APP_EGT_ENV !== "production"
  );
  const [isPlaying, setIsPlaying] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [showPlayButton, setShowPlayButton] = useState(true);
  const videoRef = useRef(null);

  // Simulated functions for fetching data
  const getAdminSetting = async () => {
    const { data } = await PublicService.getCMS();
    setConfig(data);
  };

  const getSubscriptionInfo = async () => {
    const { data } = await SubscriptionService.getSubscriptionInfo();
    setSubscription(data?.subscription);
  };

  const getAdminConfigure = async () => {
    const response = await PublicService.getAdminConfigure();
    setAdminTestPhase(response?.data?.config?.testPhaseDurationDays);
  };

  useEffect(() => {
    getAdminSetting();
    title("Willkommen");
    setTimeout(() => title("Willkommen"), 200);
    getSubscriptionInfo();
    getAdminConfigure();
  }, [title]);

  const { user } = useSelector(selectUser);

  useEffect(() => {
    if (!adminTestPhase) return;
    const subscribed = !!subscription?.id;
    const hasBeenWelcomed = !!user?.hasBeenWelcomed;
    const isTestPhase = (
      user?.firstAccessAt ? moment(user.firstAccessAt) : moment()
    )
      .add(adminTestPhase, "days")
      .isAfter(moment());

    if (subscribed || !isTestPhase || hasBeenWelcomed) {
      navigate("/dashboard");
    }
  }, [user, navigate, adminTestPhase, subscription]);

  useEffect(() => {
    const checkInterval = setInterval(async () => {
      const me = await UserService.me();
      const subscribed = !!me?.data?.user?.subscription?.paid;
      if (subscribed) {
        clearInterval(checkInterval);
        navigate("/dashboard");
      }
    }, 2000);

    return () => clearInterval(checkInterval);
  }, [navigate]);

  const handleVideoEnd = () => {
    setVideoWatched(true);
    setIsPlaying(false);
    setVideoProgress(100);
    setShowPlayButton(true);
  };

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        setShowPlayButton(true);
      } else {
        videoRef.current.play();
        setShowPlayButton(false);
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleVideoTimeUpdate = () => {
    if (videoRef.current) {
      const progress =
        (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setVideoProgress(progress);
    }
  };

  const handleStartClick = async () => {
    await UserService.updateUserInfo({ hasBeenWelcomed: true });
    navigate("/dashboard");
  };

  return (
    <div
      className="flex flex-col items-center justify-start pb-[60px] p-4 rounded-xl"
      style={{
        background:
          "url('/images/Soft Green Modern Personal Branding Golden Rules Instagram Post-2.png')",
        backgroundPositionX: "100%",
        backgroundSize: "100%",
      }}
    >
      <div className="w-full max-w-4xl mb-2" id="pricing">
        {config?.CMS?.enableWelcomingVideo && (
          <div className="flex flex-col items-center justify-center w-full">
            {config?.CMS?.welcomingVideoText && (
              <p className="mb-2 mt-10 text-2xl font-bold text-center text-[#FFFFFF]">
                {config.CMS.welcomingVideoText}
              </p>
            )}

            <div className="relative w-full mt-[40px]">
              <video
                ref={videoRef}
                playsInline
                className="aspect-video w-full rounded-sm object-cover shadow-lg bg-black bg-opacity-50 p-1"
                poster={config.CMS.welcomingVideoThumbnail}
                onEnded={handleVideoEnd}
                onClick={handleVideoClick}
                onTimeUpdate={handleVideoTimeUpdate}
                style={{ cursor: "pointer" }}
              >
                <source
                  src={config?.CMS?.welcomingVideoAtSubscriptionPage}
                  type="video/mp4"
                />
                <source
                  src={config?.CMS?.welcomingVideoAtSubscriptionPage}
                  type="video/webm"
                />
                <source
                  src={config?.CMS?.welcomingVideoAtSubscriptionPage}
                  type="video/quicktime"
                />
                Your browser does not support the video tag.
              </video>
              {showPlayButton && (
                <div
                  className="absolute cursor-pointer inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300"
                  onClick={handleVideoClick}
                >
                  <FaPlayCircle className="w-20 h-20 rounded-full text-white opacity-75 hover:opacity-100 transition-opacity duration-300" />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="flex w-full justify-center mt-[40px]">
        <div className="relative w-full max-w-[300px]">
          <div
            className="absolute z-11 inset-0 bg-blue-500 rounded-md transition-all duration-300 ease-in-out"
            style={{ width: `${videoProgress}%` }}
          />
          <Button
            type={videoWatched ? "primary" : null}
            className={`relative z-10 w-full !text-[#FFFFFF] h-10 font-bold ${
              videoWatched
                ? "!text-[#ffffff]"
                : "bg-transparent hover:!bg-transparent hover:!border-[#ffffff]"
            }`}
            onClick={(e) => {
              if (!videoWatched)
                return message.info(
                  "Du musst das Willkommensvideo bis zum Ende anschauen damit es weiter geht, da es wichtige Informationen enthält"
                );
              handleStartClick(e);
            }}
          >
            Jetzt loslegen
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
