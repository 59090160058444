import { Alert, Button, Modal, Select, Spin, Typography, message } from "antd";
import React, { useRef, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { selectLoading } from "../../../redux/auth/selectors";
import AdminService from "../../../service/AdminService";

const mappedVacancySubmission = [
  { value: "fullname", label: "Fullname" },
  { value: "firstname", label: "Firstname" },
  { value: "lastname", label: "Lastname" },
  { value: "email", label: "Email" },
  { value: "phone", label: "Phone" },
];

const handleXLSXTOJSON = async ({ sheet }, callback) => {
  const XLSXTOJSON = new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const jsonResult = jsonData.map((row) =>
        row.reduce((acc, cell, index) => {
          const header = jsonData[0][index];
          if (header) {
            acc[header] = cell;
          }
          return acc;
        }, {})
      );
      resolve(jsonResult);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsBinaryString(sheet);
  });
  const json = await XLSXTOJSON;
  callback(json);
};

const ImportModule = ({ ImportButton, onImportEnd }) => {
  const [bulkUploadProcess, setBulkUploadProcess] = useState({});
  const [lastScroll, setLastScroll] = useState(0);

  const loading = useSelector(selectLoading);

  const fileInputRef = useRef(null);

  const handleStartImport = () => {
    setLastScroll(window.scrollY);

    fileInputRef.current.value = "";
    setBulkUploadProcess({});
    fileInputRef.current.click();
  };

  const columns = Array.from(
    new Set(
      bulkUploadProcess?.json?.map?.((line) => Object.keys(line))?.flat?.() ??
        []
    )
  );

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      handleXLSXTOJSON({ sheet: file }, async (json) => {
        json.shift();

        let mappings = {};
        try {
          mappings = JSON.parse(
            localStorage[`importfile_mapping_${(columns ?? []).join("_")}`]
          );
        } catch (e) {}

        setBulkUploadProcess((current) => ({ ...current, json, mappings }));
      });
    }
  };

  return (
    <>
      <ImportButton onClick={handleStartImport} />

      <Modal
        open={!!bulkUploadProcess?.json?.[0]}
        onCancel={() => setBulkUploadProcess({})}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        afterOpenChange={(e) => {
          if (!e) window.scrollTo(0, lastScroll);
        }}
      >
        <Alert
          type="info"
          message={"Bitte überprüfe die Einträge"}
          className="mt-5"
        />

        {bulkUploadProcess?.mappedItems ? (
          <div className="overflow-auto">
            <table className="min-w-full divide-y divide-gray-200 mt-5 mb-3">
              <thead>
                <tr className="font-bold">
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs text-gray-500"
                  >
                    {"Delete"}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs text-gray-500"
                  >
                    {"Firstname"}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs text-gray-500"
                  >
                    {"Lastname"}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs text-gray-500"
                  >
                    {"Email"}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs text-gray-500"
                  >
                    {"Phone"}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs text-gray-500"
                  >
                    {"LinkedIn URL"}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200">
                {bulkUploadProcess?.mappedItems?.map((line, i) => (
                  <tr key={i}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <MdDelete
                        onClick={() => {
                          setBulkUploadProcess((cur) => {
                            const current = { ...cur };

                            current.mappedItems.splice(i, 1);

                            return current;
                          });
                        }}
                        className="cursor-pointer text-red-500 relative top-0.5 start-1"
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Typography.Paragraph
                        editable={{
                          onChange: (e) => {
                            setBulkUploadProcess((cur) => {
                              const current = { ...cur };

                              current.mappedItems[i].firstname = e;

                              return current;
                            });
                          },
                        }}
                      >
                        {line?.firstname ?? ""}
                      </Typography.Paragraph>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Typography.Paragraph
                        editable={{
                          onChange: (e) => {
                            setBulkUploadProcess((cur) => {
                              const current = { ...cur };

                              current.mappedItems[i].lastname = e;

                              return current;
                            });
                          },
                        }}
                      >
                        {line?.lastname ?? ""}
                      </Typography.Paragraph>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap flex">
                      <Typography.Paragraph
                        editable={{
                          onChange: (e) => {
                            setBulkUploadProcess((cur) => {
                              const current = { ...cur };

                              current.mappedItems[i].email = e;

                              return current;
                            });
                          },
                        }}
                      >
                        {line?.email ?? ""}
                      </Typography.Paragraph>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Typography.Paragraph
                        editable={{
                          onChange: (e) => {
                            setBulkUploadProcess((cur) => {
                              const current = { ...cur };

                              current.mappedItems[i].phone = e;

                              return current;
                            });
                          },
                        }}
                      >
                        {line?.phone ?? ""}
                      </Typography.Paragraph>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="flex justify-end gap-3">
              <button
                onClick={async () => {
                  const chunk = (arr, size) =>
                    Array.from(
                      { length: Math.ceil(arr.length / size) },
                      (v, i) => arr.slice(i * size, i * size + size)
                    );

                  const chunks = chunk(
                    bulkUploadProcess.mappedItems.map((l) => ({
                      ...l,
                    })),
                    100
                  );

                  for (const chunk of chunks)
                    await AdminService.importLead(chunk);
                  setBulkUploadProcess({});
                  onImportEnd();
                }}
                className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
                disabled={loading}
              >
                {!loading ? "Import" : <Spin>{"Import"}</Spin>}
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="font-bold flex items-center justify-between mt-5 mb-3">
              <div>{"Column of Imported File"}</div>
              <div>{"Target Column"}</div>
            </div>
            {columns &&
              columns.map((key, i) => (
                <div key={i} className="flex items-center justify-between mb-1">
                  <div>{key}</div>
                  <div>
                    <Select
                      className="min-w-[120px]"
                      value={bulkUploadProcess?.mappings?.[key]}
                      allowClear
                      onChange={(e) =>
                        setBulkUploadProcess((cur) => {
                          const current = { ...cur };
                          if (!current?.mappings) current.mappings = {};

                          current.mappings[key] = e;

                          localStorage[
                            `importfile_mapping_${Object.keys(
                              bulkUploadProcess.json[0]
                            ).join("_")}`
                          ] = JSON.stringify(current.mappings);

                          return current;
                        })
                      }
                    >
                      {mappedVacancySubmission
                        .filter((item) => {
                          return (
                            !bulkUploadProcess?.mappings ||
                            bulkUploadProcess?.mappings[key] === item.value ||
                            !Object.values(bulkUploadProcess.mappings).includes(
                              item.value
                            )
                          );
                        })
                        .map((item) => (
                          <Select.Option
                            key={item.value}
                            value={item.value}
                            label={item.label}
                          >
                            {item.label}
                          </Select.Option>
                        ))}
                    </Select>
                  </div>
                </div>
              ))}

            <div className="flex justify-end mt-2">
              <button
                onClick={async () => {
                  const keys = Object.keys(bulkUploadProcess.mappings);
                  const values = Object.values(bulkUploadProcess.mappings);

                  if (!values.includes("email"))
                    return message.info(
                      "Please select a mapping column for email"
                    );
                  if (
                    !values.includes("firstname") &&
                    !values.includes("fullname")
                  )
                    return message.info(
                      "Please select a mapping column for firstname or fullname"
                    );
                  if (
                    !values.includes("lastname") &&
                    !values.includes("fullname")
                  )
                    return message.info(
                      "Please select a mapping column for lastname or fullname"
                    );

                  const mappedItems = bulkUploadProcess.json
                    .map((item) => {
                      const mappedItem = {};
                      for (const key of keys)
                        mappedItem[bulkUploadProcess.mappings[key]] = item[key];

                      if (mappedItem.fullname) {
                        mappedItem.firstname = mappedItem.fullname
                          .split(" ")
                          ?.slice(0, -1)
                          ?.join(" ");
                        mappedItem.lastname = mappedItem.fullname
                          .split(" ")
                          ?.slice(-1)
                          ?.join(" ");
                        if (!mappedItem.firstname && mappedItem.lastname) {
                          mappedItem.firstname = `${mappedItem.lastname}`;
                          mappedItem.lastname = "";
                        }

                        delete mappedItem.fullname;
                      }
                      return mappedItem;
                    })
                    .filter((a) => {
                      if (Object.keys(a).every((key) => !a[key])) return false;

                      return true;
                    });
                  setBulkUploadProcess((current) => ({
                    ...current,
                    mappedItems,
                  }));
                }}
                className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
                disabled={loading}
              >
                {!loading ? "Import" : <Spin>{"Import"}</Spin>}
              </button>
            </div>
          </>
        )}
      </Modal>

      {/* For bulk upload */}
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
        accept=".xlsx,.csv"
      />
    </>
  );
};

export default ImportModule;
