import { Button } from "antd";
import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";

const CollapseItem = ({
  title,
  children,
  icon,
  line = true,
  moveVideo = null,
  data = null,
  style = null,
}) => {
  const [open, setOpen] = useState(false);

  const handletoggle = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <div
      className={`cursor-pointer transition animate-fade-left w-full bg-[#FAFAFA] rounded-[1.25rem] ${style}`}
    >
      <div onClick={handletoggle}>
        <div className="flex items-center justify-between ">
          <h1 className="font-semibold text-[1.25rem]">{title}</h1>
          <div className="flex">
            {moveVideo !== null && data !== null ? (
              <div className="flex">
                <Button
                  className="bg-green-600 text-center border-none text-white font-medium p-0 flex justify-center items-center h-[42px] w-[42px]"
                  onClick={(e) => {
                    e.stopPropagation();
                    moveVideo("UP", data);
                  }}
                >
                  <BsArrowUp width={16} height={16} fontSize={16} />
                </Button>
                <Button
                  className="bg-green-600  text-center border-none text-white font-medium p-0 flex justify-center items-center h-[42px] w-[42px] ml-[16px]"
                  onClick={(e) => {
                    e.stopPropagation();
                    moveVideo("DOWN", data);
                  }}
                >
                  <BsArrowDown width={16} height={16} fontSize={16} />
                </Button>
              </div>
            ) : (
              <></>
            )}
            <div className="flex justify-between items-center min-w-[23px] ml-[42px]">
              {icon}
              <img
                src="/images/icons8pfeil-ausklappen3-1.png"
                className={`transition-all ${open && "rotate-90"}`}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`w-full transition-all duration-500 overflow-hidden ${
          open && line ? "h-[1.875rem] pt-[1.875rem]" : "h-0 pt-0"
        }`}
      >
        <hr className="bg-[#D8D8D8] text-[#D8D8D8] w-[100%] h-[2px] transition-all duration-500" />
      </div>
      <Collapse
        isOpened={open}
        className={`transition-all   animate-duration-1000 animate-dissolve`}
      >
        {children}
      </Collapse>
    </div>
  );
};

export default CollapseItem;
