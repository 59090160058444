import { InputNumber } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/auth/selectors";
import WelcomeSection from "./WelcomeSection";

export default function VorschauEmpfehlungsportal() {
  const { user } = useSelector(selectUser);
  const [anzahlEmpfehlungen, setAnzahlEmpfehlungen] = useState(1);

  const handleAnzahlChange = (value) => {
    if (value !== null) {
      setAnzahlEmpfehlungen(Math.max(0, value));
    }
  };

  return (
    <div className="mt-1.5 flex flex-col items-center self-stretch">
      <div className="flex flex-col gap-6 container-xs mdx:px-2">
        <WelcomeSection
          user_id={user._id}
          promoter={{
            firstname: "PromoterVorname",
            lastname: "PromoterNachname",
            user_id: user,
            anzahlEmpfehlungen,
          }}
        />

        <div className="flex items-center justify-start gap-4">
          <span className="text-gray-700 max-w-[200px]">
            Nach Anzahl erfolgreicher Empfehlungen testen:
          </span>
          <InputNumber
            min={0}
            value={anzahlEmpfehlungen}
            onChange={handleAnzahlChange}
            className="w-24"
          />
        </div>
      </div>
    </div>
  );
}
