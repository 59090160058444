import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Pagination,
  Popconfirm,
  Select,
  Space,
  Table,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import CrudService from "../../../service/CrudService";
import classNames from "./example.module.css";
import classNameTitle from "./title.module.css";

const variables = [
  { id: "anrede", display: "Anrede des Adressaten" },
  { id: "firstname", display: "Vorname des Adressaten" },
  { id: "lastname", display: "Nachname des Adressaten" },
  { id: "branche", display: "Branche des Adressaten" },
  { id: "company", display: "Unternehmen des Adressaten" },
  {
    id: "vat",
    display: "USt-IdNr / Steuernummer des Adressaten",
  },
  { id: "street", display: "Straße des Adressaten" },
  { id: "streetNr", display: "Hausnummer des Adressaten" },
  { id: "postCode", display: "PLZ des Adressaten" },
  { id: "city", display: "Stadt des Adressaten" },
  { id: "email", display: "Email des Adressaten" },
  { id: "phone", display: "Telefonnummer des Adressaten" },
  {
    id: "resetToken",
    display: "Geheimnis-Tokendes Adressaten",
  },
  { id: "baseUrl", display: "Basis URL" },
  { id: "_id", display: "ID des Nutzers" },
  { id: "", display: "" },
];

const ereignisse = [
  {
    value: "registrierung-bestatigen",
    label: "Nutzer hat sich registriert",
  },
  { value: "password-reset", label: "Nutzer hat Passwort vergessen" },
  // { value: "new-user", label: "Neuer Nutzer" },
  // {
  //   value: "emailZustimmungFallig",
  //   label: "Email Zustimmung fällig",
  // },
  // { value: "linkNichtGeklickt", label: "Link nicht geklicht" },
  // {
  //   value: "empfehlungUnbearbeitet",
  //   label: "Empfehlung unbearbeitet",
  // },
  // {
  //   value: "empfehlungTerminiert",
  //   label: "Empfehlung terminiert",
  // },
  // {
  //   value: "empfehlungOhneStatusAnderung",
  //   label: "Empfehlung ohne Statusänderung",
  // },
  // {
  //   value: "startergesprach-erinnerung",
  //   label: "Startergespräch Erinnerung",
  // },
  // {
  //   value: "demogesprach-erinnerung",
  //   label: "Demogespräch Erinnerung",
  // },
  // { value: "neue-empfehlung", label: "Neue Empfehlung" },
  // { value: "promoter-neues-guthaben", label: "Neues Guthaben" },
  // {
  //   value: "promoter-emailbestatigung",
  //   label: "Promoter Emailbestätigung",
  // },
  // {
  //   value: "empfehlung-emailbestatigung",
  //   label: "Empfehlung Emailbestätigung",
  // },
  // { value: "bonus-erhalten", label: "Bonus erhalten" },
  // { value: "neuerPromoter", label: "Neuer Promoter" },
  // { value: "neuerKunde", label: "Neuer Kunde" },
  // { value: "neueEmpfehlung", label: "Neue Empfehlung" },
  // { value: "zugang", label: "Dashboard Zugang" },
  // {
  //   value: "pramien-ohne-erlaubnis",
  //   label: "Prämienforderung (Erlaubnis nicht nötig)",
  // },
  // {
  //   value: "gutscheineinlösung-ohne-erlaubnis",
  //   label: "Gutscheineinlösung (Erlaubnis nicht nötig)",
  // },
  // {
  //   value: "pramien-erlaubnis-benotigt",
  //   label: "Prämienforderung (Erlaubnis benötigt)",
  // },
  // {
  //   value: "gutscheineinlösung-erlaubnis-benotigt",
  //   label: "Gutscheineinlösung (Erlaubnis benötigt)",
  // },
  // {
  //   value: "promoter-emailbestatigt",
  //   label: "Promoter nach Emailbestätigung",
  // },

  // {
  //   value: "email-bestatigen",
  //   label: "Nutzer nach Email Update",
  // },
  // { value: "pramien-ausschuttung", label: "Prämienausschüttung" },
  // { value: "kundigung", label: "Kündigungsbestätigung" },
  // { value: "zahlungsbeleg", label: "Zahlungsbeleg" },
  // { value: "zahlungsfehler", label: "Zahlung fehlgeschlagen" },
  // { value: "linkAngeklickt", label: "Link angeklickt" },
  // { value: "bonus", label: " Manuelle Gutschrift" },
];

const SystemBenachrichtigungen = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [modalVisible, setModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [pagination.current]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await CrudService.search(
        "AdminNotificationConfig",
        pagination.pageSize,
        pagination.current,
        {}
      );
      setData(response.data.items);
      setPagination({
        ...pagination,
        total: response.data.total,
      });
    } catch (error) {
      console.error(error);
      message.error("Fehler beim Laden");
    }
    setLoading(false);
  };

  const handleCreate = () => {
    setEditingRecord(null);
    form.resetFields();
    setModalVisible(true);
  };

  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue(record);
    setModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await CrudService.delete("AdminNotificationConfig", id);
      message.success("Erfolgreich gelöscht");
      fetchData();
    } catch (error) {
      console.error(error);
      message.error("Fehler beim Löschen");
    }
  };

  const handleSave = async () => {
    setFormLoading(true);
    try {
      const values = await form.validateFields();
      if (editingRecord) {
        await CrudService.update(
          "AdminNotificationConfig",
          editingRecord._id,
          values
        );
        message.success("Erfolgreich gespeichert");
      } else {
        await CrudService.create("AdminNotificationConfig", values);
        message.success("Erfolgreich erstellt");
      }
      fetchData();
      setModalVisible(false);
    } catch (error) {
      console.error(error);
      message.error("Fehler beim Speichern");
    }
    setFormLoading(false);
  };

  const columns = [
    {
      title: "Ereignis",
      dataIndex: "event",
      key: "event",
      render: (a) => ereignisse.find((x) => x.value === a)?.label,
    },
    {
      title: "Typ",
      dataIndex: "type",
      key: "type",
      render: (a) => <div className="capitalize">{a}</div>,
    },
    {
      title: "Verzögerung (Stunden)",
      dataIndex: "delay",
      key: "delay",
    },
    {
      title: "Aktion",
      key: "action",
      render: (_, record) => (
        <Space>
          <Button type="link" onClick={() => handleEdit(record)}>
            Bearbeiten
          </Button>
          <Popconfirm
            title="Sicher?"
            onConfirm={() => handleDelete(record._id)}
          >
            <Button type="link" danger>
              Löschen
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="p-4">
        <Button type="primary" onClick={handleCreate} className="mb-4">
          Neue Benachrichtigung
        </Button>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          rowKey="_id"
          pagination={{
            ...pagination,
            onChange: (page) => setPagination({ ...pagination, current: page }),
          }}
        />
      </div>

      <Modal
        open={modalVisible}
        title={editingRecord ? "Edit Notification" : "Create Notification"}
        onCancel={() => setModalVisible(false)}
        onOk={handleSave}
        okButtonProps={{ loading: formLoading }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="type"
            label="Type"
            rules={[{ required: true, message: "Please input the event" }]}
          >
            <Select>
              {[
                { value: "email", label: "Email" },
                { value: "app", label: "App" },
              ].map((a) => (
                <Select.Option value={a.value}>{a.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="event"
            label="Ereignis"
            rules={[{ required: true, message: "Please input the event" }]}
          >
            <Select>
              {ereignisse.map((a) => (
                <Select.Option value={a.value}>{a.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="delay"
            label="Verzögerung (Stunden)"
            rules={[{ required: true, message: "Please input the delay" }]}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item name="title" label="Titel">
            <MentionsInput
              placeholder="# eingeben um Variablen zu sehen"
              classNames={classNameTitle}
              a11ySuggestionsListLabel={"Possible variables"}
            >
              <Mention trigger="#" className={""} data={variables} />
            </MentionsInput>
          </Form.Item>
          <Form.Item
            name="text"
            label="Text"
            rules={[{ required: true, message: "Please input the text" }]}
          >
            <MentionsInput
              placeholder="# eingeben um Variablen zu sehen"
              classNames={classNames}
              a11ySuggestionsListLabel={"Possible variables"}
            >
              <Mention trigger="#" className={""} data={variables} />
            </MentionsInput>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SystemBenachrichtigungen;
