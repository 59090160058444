import { Button } from "antd";
import React from "react";

export default function ReferralSections() {
  return (
    <div className="w-full max-w-[1200px] mx-auto px-4 mt-8">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left Column */}
        <div className="space-y-4">
          <h2 className="text-2xl font-bold">
            Wem solltest Du den Link senden?
          </h2>

          {/* Target Groups */}
          <div className="grid grid-cols-3 gap-4 !text-[12px]">
            {/* Financial Advisors */}
            <div className="bg-[#3B82F6] rounded-[24px] p-6 text-white text-center font-semibold">
              <h3 className="font-bold mb-4">Finanzberater</h3>
              <ul className="space-y-2 ">
                <li>Versicherungsagentur</li>
                <li>Versicherungsmakler</li>
                <li>Vermögensberater</li>
              </ul>
            </div>

            {/* Structural Sales */}
            <div className="bg-[#60A5FA] rounded-[24px] p-6 m-2 text-white text-center font-semibold">
              <h3 className="font-bold mb-4">Strukturvertriebe</h3>
              <ul className="space-y-2 ">
                <li>PV Anlagen</li>
                <li>Immobilienfinanzierer</li>
                <li>Strom & Gas</li>
              </ul>
            </div>

            {/* Others */}
            <div className="bg-[#93C5FD] rounded-[24px] p-6 m-4 text-white text-center font-semibold">
              <h3 className="font-bold mb-4">sonstige</h3>
              <p className="">Direktvertriebe</p>
            </div>
          </div>

          {/* Info Boxes */}
          <div className="grid grid-cols-3 gap-4">
            {/* Bonus Box */}
            <div className="bg-gray-100 rounded-[24px] p-6 bg-[#eeeeee]">
              <div className="flex justify-start items-center gap-2 mb-3">
                <img
                  src="/images/faq-f.png"
                  className="object-cover m-[-5px]"
                  style={{ height: 50, width: 40 }}
                />
                <h3 className="font-bold">Prämie erhalten</h3>
              </div>
              <p className="text-sm text-gray-600">
                Deinen Fortschritt kannst Du oben in der Prämienübersicht
                verfolgen. Sobald Du einen Meilenstein erreicht hast, erscheint
                ein Button über den du die Prämie einlösen kannst.
              </p>
            </div>

            {/* Double Recommendation Box */}
            <div className="bg-gray-100 rounded-[24px] p-6 bg-[#eeeeee] mt-2">
              <div className="flex justify-start items-center gap-2 mb-3">
                <img
                  src="/images/faq-a.png"
                  className="object-cover m-[-5px]"
                  style={{ height: 50, width: 40 }}
                />
                <h3 className="font-bold">doppelte Empfehlung</h3>
              </div>
              <p className="text-sm text-gray-600">
                Sofern 2 Nutzer die selbe Empfehlung aussprechen, wird diese
                demjenigen zugeordnet, der die letzten Empfehlung ausgesprochen
                hat.
              </p>
            </div>

            {/* Personal Link Box */}
            <div className="bg-gray-100 rounded-[24px] p-6 bg-[#eeeeee] mt-4">
              <div className="flex justify-start items-center gap-2 mb-3">
                <img
                  src="/images/faq-q.png"
                  className="object-cover m-[-10px]"
                  style={{ height: 60, width: 40 }}
                />
                <h3 className="font-bold">Persönlicher Link</h3>
              </div>
              <p className="text-sm text-gray-600">
                Der Link ist dir persönlich auf Dich zugeordnet. Somit ist
                sicher gestellt, dass das System immer genau weiß von wem
                empfohlen wurde.
              </p>
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div className="space-y-4">
          <h2 className="text-2xl font-bold">WAS DU VERSCHENKST!</h2>

          {/* Free Trial Box */}
          <div className="border-2 border-[#3B82F6] text-[#3B82F6] rounded-full p-4 text-center font-semibold">
            kostenlosen Empfehlungsratgeber & EinGuterTipp garantiert 14 Tage
            kostenlos testen
          </div>

          {/* Subscription Info */}
          <p className="text-center font-semibold">
            Sobald ein aktives Abo
            <span className="text-[#3B82F6] underline font-semibold mx-1 hover:underline">
              ab Tarif Standard
            </span>
            gebucht wird, gilt deine Empfehlung als erfolgreich.
          </p>

          {/* Recommend Button */}
          <Button
            type="primary"
            className="w-full h-12 !bg-[#FCD34D] !text-black hover:!bg-[#F59E0B] border-none rounded-lg text-lg font-medium"
          >
            Jetzt empfehlen
          </Button>

          {/* Team Referral Section */}
          <div className="text-center mt-12 relative">
            <p className="mb-4 text-lg">
              Du möchtest ein größeres Team oder eine Organisation empfehlen?
            </p>

            {/* Decorative "oder" */}
            <span className="absolute right-[45%] -mt-8 text-[#3B82F6] italic transform -rotate-12">
              oder
            </span>

            {/* Arrows */}
            <div className="relative">
              <img
                src="/placeholder.svg?height=40&width=40"
                alt="Arrow"
                width={40}
                height={40}
                className="absolute -left-8 top-1/2 transform -translate-y-1/2"
              />
              <img
                src="/placeholder.svg?height=40&width=40"
                alt="Arrow"
                width={40}
                height={40}
                className="absolute -right-8 top-1/2 transform -translate-y-1/2"
              />

              {/* Book Appointment Button */}
              <Button
                type="primary"
                className="!bg-[#3B82F6] hover:!bg-[#2563EB] !text-white border-none rounded-lg h-12 px-8 text-lg font-medium"
              >
                Termin buchen
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
