import moment from "moment";

export const aboTarif = (user) => {
  const adminTestPhaseLocalStorage = parseInt(localStorage.adminTestPhase);
  const adminTestPhase = isNaN(adminTestPhaseLocalStorage)
    ? 14
    : adminTestPhaseLocalStorage;

  const isTestPhase = (
    user?.firstAccessAt ? moment(user?.firstAccessAt) : moment()
  )
    .add(adminTestPhase, "days")
    .isAfter(moment());

  if (isTestPhase && !user?.subscription?.paid) return 3;
  if (!user?.subscription?.paid) return 0;
  if (user?.subscription?.tier === "Basic") return 1;
  if (user?.subscription?.tier === "Standard") return 2;
  if (user?.subscription?.tier === "Premium") return 3;
  return 0;
};
