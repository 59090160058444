import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import SwitchTitle from "../shared/SwitchTitle";
import CollapseItem from "../shared/CollapseItem";
import Box from "../Layout/Box";
import { Card, Button } from "antd";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useScreenWidth } from "../../../LandingPageConfig/useScreenWidth";
import { useMessageContext } from "../../../../context/messageContext";
import WhatsappButtonConfig from "../shared/WhatsappButtonConfig";
import GradientPickerModal from "../shared/GradientPickerModal";
import TypeButtonConfig from "../shared/TypeButtonConfig";

const ButtonConfig = forwardRef(
  ({ disabled, landingPageData, wa, ca }, ref) => {
    const { width } = useScreenWidth();
    const { showMessage } = useMessageContext();

    const [buttonsData, setButtonsData] = useState({
      activate_wa1: false,
      activate_wa2: false,
      activate_wa3: false,
      activate_calendly: false,
      activate_recall: false,
    });

    const [isGradientPickerVisible, setGradientPickerVisible] = useState(false);
    const [buttonGradient, setButtonGradient] = useState(
      landingPageData.whatsAppButtonColor
    );

    const [
      isTypeButtonGradientPickerVisible,
      setTypeButtonGradientPickerVisible,
    ] = useState(false);
    const [typeButtonColor, setTypeButtonColor] = useState(
      landingPageData.typeButtonColor
    );

    const handleTypeButtonGradientPicker = () => {
      setTypeButtonGradientPickerVisible(true);
    };

    const applyTypeButtonGradient = () => {
      setTypeButtonGradientPickerVisible(false);
      showMessage(
        "success",
        "Gradient für den Type-Button erfolgreich angewendet!"
      );
    };

    const handleGradientPicker = () => {
      setGradientPickerVisible(true);
    };

    const applyGradient = () => {
      setGradientPickerVisible(false);
      showMessage("success", "Gradient erfolgreich angewendet!");
    };

    const [errorMessages, setErrorMessages] = useState({
      activate_wa1: "",
      activate_wa2: "",
      activate_wa3: "",
      activate_calendly: "",
    });

    useEffect(() => {
      setButtonsData({
        activate_wa1: landingPageData.activate_wa1,
        activate_wa2: landingPageData.activate_wa2,
        activate_wa3: landingPageData.activate_wa3,
        activate_calendly: landingPageData.activate_calendly,
        activate_recall: landingPageData.activate_recall,
      });
    }, [landingPageData, wa]);

    useImperativeHandle(ref, () => ({
      async save() {
        console.log("ButtonConfig.save()");

        // 1. Deaktiviere WhatsApp-Buttons, falls WA insgesamt nicht aktiv ist
        if (
          !wa &&
          (buttonsData.activate_wa1 ||
            buttonsData.activate_wa2 ||
            buttonsData.activate_wa3)
        ) {
          setButtonsData((prevState) => ({
            ...prevState,
            activate_wa1: false,
            activate_wa2: false,
            activate_wa3: false,
          }));

          showMessage(
            "error",
            "Whatsapp ist deaktiviert. Alle zugehörigen Buttons wurden deaktiviert."
          );
        }

        // 2. Deaktiviere Calendly-Button, falls Calendly insgesamt nicht aktiv ist
        if (!ca && buttonsData.activate_calendly) {
          setButtonsData((prevState) => ({
            ...prevState,
            activate_calendly: false,
          }));

          showMessage(
            "error",
            "Calendly ist deaktiviert. Der zugehörige Button wurde deaktiviert."
          );
        }

        // 3. **Neue Prüfung**: Es muss mindestens einer der Formular-Buttons aktiviert sein
        //    (activate_wa3, activate_calendly oder activate_recall).
        //    Wenn alle drei false sind, wird abgebrochen.
        if (
          !buttonsData.activate_wa3 &&
          !buttonsData.activate_calendly &&
          !buttonsData.activate_recall
        ) {
          showMessage(
            "warning",
            "Es muss mindestens ein Formular-Button aktiviert sein. 'Rückruf' wird automatisch aktiviert."
          );

          setButtonsData((prev) => ({
            ...prev,
            activate_recall: true, // Fallback
          }));
        }

        // 4. Rückgabe der aktualisierten Werte
        return {
          landingPageData: {
            activate_wa1: wa ? buttonsData.activate_wa1 : false,
            activate_wa2: wa ? buttonsData.activate_wa2 : false,
            activate_wa3: wa ? buttonsData.activate_wa3 : false,
            activate_calendly: ca ? buttonsData.activate_calendly : false,
            activate_recall: buttonsData.activate_recall,
            whatsAppButtonColor: buttonGradient,
            typeButtonColor: typeButtonColor,
          },
        };
      },
    }));

    const handleSwitchChange = (field, value) => {
      if (
        (field === "activate_wa1" ||
          field === "activate_wa2" ||
          field === "activate_wa3") &&
        !wa
      ) {
        setErrorMessages((prev) => ({
          ...prev,
          [field]:
            "Whatsapp muss aktiviert sein, um diesen Button zu aktivieren.",
        }));
        return;
      }

      if (field === "activate_calendly" && !ca) {
        setErrorMessages((prev) => ({
          ...prev,
          [field]:
            "Calendly muss aktiviert sein, um diesen Button zu aktivieren.",
        }));
        return;
      }

      setErrorMessages((prev) => ({ ...prev, [field]: "" }));
      setButtonsData((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    };

    useEffect(() => {
      if (!wa) {
        setButtonsData((prevState) => ({
          ...prevState,
          activate_wa1: false,
          activate_wa2: false,
          activate_wa3: false,
        }));

        setErrorMessages((prev) => ({
          ...prev,
          activate_wa1:
            "Whatsapp muss aktiviert sein, um diesen Button zu aktivieren.",
          activate_wa2:
            "Whatsapp muss aktiviert sein, um diesen Button zu aktivieren.",
          activate_wa3:
            "Whatsapp muss aktiviert sein, um diesen Button zu aktivieren.",
        }));
      } else {
        setErrorMessages((prev) => ({
          ...prev,
          activate_wa1: "",
          activate_wa2: "",
          activate_wa3: "",
        }));
      }

      if (!ca) {
        setButtonsData((prevState) => ({
          ...prevState,
          activate_calendly: false,
        }));

        setErrorMessages((prev) => ({
          ...prev,
          activate_calendly:
            "Calendly muss aktiviert sein, um diesen Button zu aktivieren.",
        }));
      } else {
        setErrorMessages((prev) => ({
          ...prev,
          activate_calendly: "",
        }));
      }
    }, [wa, ca]);

    return (
      <Box>
        <CollapseItem
          reduceRandMobile
          title={
            <div className="flex items-center gap-3 trkisswitch">
              <div className="text-base font-semibold text-left lg:text-9xl sm:text-xl">
                Button Einstellungen
              </div>
            </div>
          }
          icon={
            <Button
              title="Hier kannst du bestimmen welche Button auf der Landing Page und dem Schlussformular angezeigt werden sollen."
              type="default"
              className={`${
                width < 550 ? "min-w-[25px] h-[25px]" : "min-w-[40px] h-[40px]"
              } p-0 justify-center items-center rounded-[10px] text-center border-[1px] border-[#d8d8d8]`}
            >
              <AiOutlineInfoCircle fontSize={width < 550 ? 14 : 20} />
            </Button>
          }
        >
          {disabled ? (
            <div className="h-[200px] relative">
              <div
                style={{ zIndex: 99999999999999999 }}
                className="absolute inset-0 bg-gray-100 backdrop-filter backdrop-blur-md"
              />
              <Card
                style={{ zIndex: 99999999999999999 }}
                className="absolute top-0 left-1/2 transform -translate-x-1/2 p-2 text-center"
              >
                <h2 className="text-2xl font-bold mb-4">Upgrade benötigt</h2>
                <p className="mb-4">
                  Um auf diesen Bereich zugreifen zu können, musst du auf eine
                  höhere Abonnementstufe upgraden.
                </p>
                <Link to="/dashboard/upgrade">
                  <Button type="primary" className="bg-[#001D47] h-[40px] mr-2">
                    Upgrade
                  </Button>
                </Link>
              </Card>
            </div>
          ) : !landingPageData.activateBasicQuestion ? (
            <div className="space-y-6">
              <div className="smx:!text-[12px] font-semibold md:text-[16px] primary:text-[20px] text-[16px] border-b pb-2">
                Landing Page Button
              </div>
              <div className="pl-4 space-y-4">
                <div>
                  <SwitchTitle
                    checked={buttonsData.activate_wa1}
                    onChange={(e) => handleSwitchChange("activate_wa1", e)}
                  >
                    Whatsapp Button 1
                  </SwitchTitle>
                  {errorMessages.activate_wa1 && (
                    <p className="text-red-500 text-sm mt-1">
                      {errorMessages.activate_wa1}
                    </p>
                  )}
                </div>
                <div>
                  <SwitchTitle
                    checked={buttonsData.activate_wa2}
                    onChange={(e) => handleSwitchChange("activate_wa2", e)}
                  >
                    Whatsapp Button 2
                  </SwitchTitle>
                  {errorMessages.activate_wa2 && (
                    <p className="text-red-500 text-sm mt-1">
                      {errorMessages.activate_wa2}
                    </p>
                  )}
                </div>
              </div>

              <div className="smx:!text-[12px] font-semibold md:text-[16px] primary:text-[20px] text-[16px] border-b mt-4 pb-2">
                Formular Button
              </div>
              <div className="pl-4 space-y-4">
                <div>
                  <SwitchTitle
                    checked={buttonsData.activate_wa3}
                    onChange={(e) => handleSwitchChange("activate_wa3", e)}
                  >
                    Whatsapp Button
                  </SwitchTitle>
                  {errorMessages.activate_wa3 && (
                    <p className="text-red-500 text-sm mt-1">
                      {errorMessages.activate_wa3}
                    </p>
                  )}
                </div>
                <div>
                  <SwitchTitle
                    checked={buttonsData.activate_calendly}
                    onChange={(e) => handleSwitchChange("activate_calendly", e)}
                  >
                    Calendly Button
                  </SwitchTitle>
                  {errorMessages.activate_calendly && (
                    <p className="text-red-500 text-sm mt-1">
                      {errorMessages.activate_calendly}
                    </p>
                  )}
                </div>
                <SwitchTitle
                  checked={buttonsData.activate_recall}
                  onChange={(e) =>
                    setButtonsData((prevState) => ({
                      ...prevState,
                      activate_recall: e,
                    }))
                  }
                >
                  Rückruf Button
                </SwitchTitle>
              </div>
              <div className="flex flex-col gap-4">
                <div className="space-y-4 flex flex-col gap-4">
                  <div className="smx:!text-[12px] font-semibold md:text-[16px] primary:text-[20px] text-[16px] border-b mt-4 pb-2">
                    Button Style
                  </div>
                  <div className="flex text-base font-semibold text-left lg:text-9xl sm:text-xl gap-[15px]">
                    Clicke auf den Button um deine Farbe anzupassen
                  </div>
                  <WhatsappButtonConfig
                    text={"Deine Whatsapp Button"}
                    className={`mx-4 mt-14 block relative`}
                    type={landingPageData.theme}
                    style={{
                      background: buttonGradient,
                    }}
                    shadowGradient={buttonGradient} // Übergeben des Gradients für den Shadow
                    changeColor={handleGradientPicker}
                  />
                  <TypeButtonConfig
                    className={`mx-4 mt-14 block relative`}
                    text="Dein Fragenbutton"
                    typeButtonColor={typeButtonColor}
                    type={landingPageData.theme}
                    onClick={handleTypeButtonGradientPicker}
                  />
                  <GradientPickerModal
                    visible={isGradientPickerVisible}
                    value={buttonGradient}
                    onChange={setButtonGradient}
                    onClose={applyGradient}
                    title="Gradient Picker"
                  />
                  <GradientPickerModal
                    visible={isTypeButtonGradientPickerVisible}
                    value={typeButtonColor}
                    onChange={setTypeButtonColor}
                    onClose={applyTypeButtonGradient}
                    title="Gradient Picker für Type-Button"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="smx:!text-[12px] font-semibold md:text-[16px] primary:text-[20px] text-[16px] border-b mt-4 pb-2">
                Formular Button
              </div>
              <div className="pl-4 space-y-4">
                <div>
                  <SwitchTitle
                    checked={buttonsData.activate_wa3}
                    onChange={(e) => handleSwitchChange("activate_wa3", e)}
                  >
                    Whatsapp Button
                  </SwitchTitle>
                  {errorMessages.activate_wa3 && (
                    <p className="text-red-500 text-sm mt-1">
                      {errorMessages.activate_wa3}
                    </p>
                  )}
                </div>
                <SwitchTitle
                  checked={buttonsData.activate_recall}
                  onChange={(e) =>
                    setButtonsData((prevState) => ({
                      ...prevState,
                      activate_recall: e,
                    }))
                  }
                >
                  Rückruf Button
                </SwitchTitle>
              </div>
              <div className="space-y-4 flex flex-col gap-4">
                <div className="smx:!text-[12px] font-semibold md:text-[16px] primary:text-[20px] text-[16px] border-b mt-4 pb-2">
                  Button Style
                </div>
                <div className="flex text-base font-semibold text-left lg:text-9xl sm:text-xl gap-[15px]">
                  Clicke auf den Button um deine Farbe anzupassen
                </div>
                <TypeButtonConfig
                  className={`mx-4 mt-14 block relative`}
                  text="Dein Fragenbutton"
                  typeButtonColor={typeButtonColor}
                  type={landingPageData.theme}
                  onClick={handleTypeButtonGradientPicker}
                  isBasicQuestion={landingPageData.activateBasicQuestion}
                />
                <GradientPickerModal
                  visible={isTypeButtonGradientPickerVisible}
                  value={typeButtonColor}
                  onChange={setTypeButtonColor}
                  onClose={applyTypeButtonGradient}
                  title="Gradient Picker für Type-Button"
                />
              </div>
            </div>
          )}
        </CollapseItem>
      </Box>
    );
  }
);

export default ButtonConfig;
