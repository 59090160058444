import React from "react";
import constructWhatsappLink from "../../../../utils/whatsappLink";

const WhatsappButton = ({
  number,
  whatsappText,
  text,
  className,
  activateWhatsApp,
  type,
  sendGoogleAnalytics,
  whatsAppButtonColor = "#25D366",
}) => {
  let roundedStyle = "";
  switch (type) {
    case "finance":
    case "individual":
      roundedStyle = "rounded-full";
      break;
    case "stormgas":
      roundedStyle = "rounded-0";
      break;
    case "property":
      roundedStyle = "rounded-sm";
      break;
    case "basic":
      roundedStyle = "rounded-full md:!text-sm";
      break;
  }

  const buttonStyle = {
    background: whatsAppButtonColor || "#25D366",
    zIndex: 50,
    position: "relative", // Button bleibt in Position
  };
  console.log(className);
  return (
    activateWhatsApp && (
      <div className="flex justify-center">
        {/* Button */}
        <a
          className={`${className} ${
            type !== "stormgas" ? "relative" : "relative"
          }`}
          href={constructWhatsappLink(number, whatsappText)}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              borderRadius: "30%",
              background: whatsAppButtonColor,
              transform: "translate(-50%, -50%)",
              width: "100%",
              height: type !== "stormgas" ? "40%" : "100%",
              filter: "blur(20px)",
              zIndex: 10,
            }}
          />
          <button
            onClick={sendGoogleAnalytics}
            className={`
              ${roundedStyle}
              gap-4 
              items-center 
              justify-center 
              font-semibold 
              text-white 
              flex 
              text-sm
              py-4
              px-7
              md:text-xl
              md:py-4
              md:px-10 
              mx-auto 
              z-[50]
            `}
            style={buttonStyle} // Inline-Stil für den Button-Hintergrund
          >
            <img
              className="h-[24px] md:h-[25px] bg-dark"
              src="/svg/whatsapp.svg"
              alt="WhatsApp"
            />
            {text}
          </button>
        </a>
      </div>
    )
  );
};

export default WhatsappButton;
