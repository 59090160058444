import { UploadOutlined } from "@ant-design/icons";
import { Button, Input, Switch, Upload, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import AdminService from "../../../service/AdminService";
import UploadToCloudService from "../../../service/UploadToCloudService";
import FloatLabel from "../../FloatingLabel";
import CollapseItem from "./CollapseItem";
import HTMLTextEditor from "./HtmlEditor";

const Index = () => {
  const editors = [
    "Datenschutz-Text",
    "Impressum-Text",
    "AGBs",
    "Teilnahmebedingungen",
  ];
  const [config, setConfig] = useState({});
  const [welcomingVideoText, setWelcomingVideoText] = useState("");
  const [upgradeVideoText, setUpgradeVideoText] = useState("");
  const [formDataLoading, setFormDataLoading] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  const [thumbnailLoading, setThumbnailLoading] = useState(false);
  const [videoLoading2, setVideoLoading2] = useState(false);
  const [thumbnailLoading2, setThumbnailLoading2] = useState(false);
  const [videoLoading3, setVideoLoading3] = useState(false);
  const [thumbnailLoading3, setThumbnailLoading3] = useState(false);
  const [whatsappNummer, setWhatsappNummer] = useState("");
  const [loadingWhatsapp, setLoadingWhatsapp] = useState(false);

  const getAdminSetting = async () => {
    const { data } = await AdminService.getAdminSetting();
    setConfig(data);
    setWelcomingVideoText(data?.CMS?.welcomingVideoText ?? "");
    setUpgradeVideoText(data?.CMS?.upgradeVideoText ?? "");
    setWhatsappNummer(data?.CMS?.whatsappNummer);
  };

  const onWhatsappSave = useCallback(async () => {
    setLoadingWhatsapp(true);
    try {
      await AdminService.setAdminConfiguration({
        key: "CMS.whatsappNummer",
        value: whatsappNummer,
      });
    } catch (e) {
    } finally {
      setLoadingWhatsapp(false);
    }
  }, [whatsappNummer]);

  const configChange = async (idx, data) => {
    if (config && config["CMS"]) {
      config["CMS"][cmsKeys[idx]] = data;
      setConfig({ ...config });
    }
  };

  const saveConfig = async (idx, data) => {
    AdminService.setAdminConfiguration({
      key: "CMS." + cmsKeys[idx],
      value: data,
    });
  };

  useEffect(() => {
    getAdminSetting();
  }, []);

  const cmsKeys = [
    "privacyPolicy",
    "impressum",
    "terms",
    "teilnahmebedingungen",
  ];

  const handleVideoUpload = useCallback(
    async (info) => {
      if (videoLoading) return;
      setVideoLoading(true);
      try {
        const res = await UploadToCloudService.upload(info.file.originFileObj);
        setConfig({
          ...config,
          CMS: {
            ...config.CMS,
            welcomingVideoAtSubscriptionPage: res.secure_url,
          },
        });
        await AdminService.setAdminConfiguration({
          key: "CMS.welcomingVideoAtSubscriptionPage",
          value: res.secure_url,
        });
        message.success("Video hochgeladen");
      } catch (e) {
        message.error("Fehler beim hochladen");
      } finally {
        setVideoLoading(false);
      }
    },
    [config, videoLoading]
  );

  const handleThumbnailUpload = useCallback(
    async (info) => {
      if (thumbnailLoading) return;
      setThumbnailLoading(true);
      try {
        const res = await UploadToCloudService.upload(info.file.originFileObj);
        setConfig({
          ...config,
          CMS: {
            ...config.CMS,
            welcomingVideoThumbnail: res.secure_url,
          },
        });
        await AdminService.setAdminConfiguration({
          key: "CMS.welcomingVideoThumbnail",
          value: res.secure_url,
        });
        message.success("Thumbnail hochgeladen");
      } catch (e) {
        message.error("Fehler beim hochladen");
      } finally {
        setThumbnailLoading(false);
      }
    },
    [config, thumbnailLoading]
  );

  const handleVideoUpload2 = useCallback(
    async (info) => {
      if (videoLoading2) return;
      setVideoLoading2(true);
      try {
        const res = await UploadToCloudService.upload(info.file.originFileObj);
        setConfig({
          ...config,
          CMS: {
            ...config.CMS,
            upgradeVideoAtSubscriptionPage: res.secure_url,
          },
        });
        await AdminService.setAdminConfiguration({
          key: "CMS.upgradeVideoAtSubscriptionPage",
          value: res.secure_url,
        });
        message.success("Video hochgeladen");
      } catch (e) {
        message.error("Fehler beim hochladen");
      } finally {
        setVideoLoading2(false);
      }
    },
    [config, videoLoading2]
  );

  const handleThumbnailUpload2 = useCallback(
    async (info) => {
      if (thumbnailLoading2) return;
      setThumbnailLoading2(true);
      try {
        const res = await UploadToCloudService.upload(info.file.originFileObj);
        setConfig({
          ...config,
          CMS: {
            ...config.CMS,
            upgradeVideoThumbnail: res.secure_url,
          },
        });
        await AdminService.setAdminConfiguration({
          key: "CMS.upgradeVideoThumbnail",
          value: res.secure_url,
        });
        message.success("Thumbnail hochgeladen");
      } catch (e) {
        message.error("Fehler beim hochladen");
      } finally {
        setThumbnailLoading2(false);
      }
    },
    [config, thumbnailLoading2]
  );

  const handleVideoUpload3 = useCallback(
    async (info) => {
      if (videoLoading3) return;
      setVideoLoading3(true);
      try {
        const res = await UploadToCloudService.upload(info.file.originFileObj);
        setConfig({
          ...config,
          CMS: {
            ...config.CMS,
            erklarFilm: res.secure_url,
          },
        });
        await AdminService.setAdminConfiguration({
          key: "CMS.erklarFilm",
          value: res.secure_url,
        });
        message.success("Video hochgeladen");
      } catch (e) {
        message.error("Fehler beim hochladen");
      } finally {
        setVideoLoading3(false);
      }
    },
    [config, videoLoading3]
  );

  const handleThumbnailUpload3 = useCallback(
    async (info) => {
      if (thumbnailLoading3) return;
      setThumbnailLoading3(true);
      try {
        const res = await UploadToCloudService.upload(info.file.originFileObj);
        setConfig({
          ...config,
          CMS: {
            ...config.CMS,
            erklarFilmThumbnail: res.secure_url,
          },
        });
        await AdminService.setAdminConfiguration({
          key: "CMS.erklarFilmThumbnail",
          value: res.secure_url,
        });
        message.success("Thumbnail hochgeladen");
      } catch (e) {
        message.error("Fehler beim hochladen");
      } finally {
        setThumbnailLoading3(false);
      }
    },
    [config, thumbnailLoading3]
  );

  const handleVideoToggle = async (checked) => {
    setConfig({
      ...config,
      CMS: {
        ...config.CMS,
        enableWelcomingVideo: checked,
      },
    });
    await AdminService.setAdminConfiguration({
      key: "CMS.enableWelcomingVideo",
      value: checked,
    });
  };

  const variableSetter = (variabletext) => {
    navigator.clipboard.writeText(variabletext);
    message.info("Kopiert");
  };

  return (
    <div>
      <CollapseItem title={"Rechtliches"}>
        <div className="p-8 flex flex-col gap-8">
          {editors.map((e, i) => (
            <div key={i}>
              {e === "Teilnahmebedingungen" && (
                <div className="rounded-3xl p-3 w-full flex overflow-auto shadow-inner whitespace-nowrap">
                  <div
                    className="text-trkis cursor-pointer text-[1rem]"
                    onClick={() => variableSetter("{Partner Vorname}")}
                  >{`{Partner Vorname} `}</div>
                  <div
                    className="text-trkis cursor-pointer text-[1rem]"
                    onClick={() => variableSetter("{Partner Nachname}")}
                  >{`{Partner Nachname} `}</div>
                </div>
              )}
              <HTMLTextEditor
                label={e}
                value={config && config["CMS"] ? config["CMS"][cmsKeys[i]] : ""}
                configChange={(data) => {
                  configChange(i, data);
                }}
                saveConfig={(data) => {
                  saveConfig(i, data);
                }}
              />
            </div>
          ))}
        </div>
      </CollapseItem>
      <div className="my-8">
        <CollapseItem title={"Testphase"}>
          <div className="p-8 flex items-center justify-start gap-6">
            <div className="w-[216px]">
              <FloatLabel
                label={"Dauer der Testphase"}
                keepitfocused
                unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] w-[216px] !font-semibold  "
                className={" flex-grow"}
              >
                <input
                  type="text"
                  value={config ? config.testPhaseDurationDays : 0}
                  onChange={(e) => {
                    setConfig({
                      ...config,
                      testPhaseDurationDays: e.target.value,
                    });
                  }}
                  className="border-[1px] text-xl text-text-sec bg-transparent w-[216px] border-[#d8d8d8] outline-none rounded-[15px] p-[30px_30px]  h-[87px]"
                />
              </FloatLabel>
            </div>
            <div className="text-9xl font-semibold">+</div>
            <p className="text-xl text-trkis font-semibold">Tage</p>
          </div>
          <div className="p-8">
            <Button
              type="primary"
              loading={formDataLoading}
              className="h-[46px] w-full"
              onClick={() => {
                setFormDataLoading(true);
                AdminService.setAdminConfiguration({
                  key: "testPhaseDurationDays",
                  value: parseInt(config.testPhaseDurationDays),
                });
                setFormDataLoading(false);
              }}
            >
              Speichern
            </Button>
          </div>
        </CollapseItem>
      </div>
      <div className="my-8">
        <CollapseItem title={"Willkommensvideo"}>
          <div className="p-8 flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <Switch
                checked={config?.CMS?.enableWelcomingVideo}
                onChange={handleVideoToggle}
              />
              <span>Willkommensvideo aktivieren</span>
            </div>
            <div className="flex items-center gap-4">
              <Input
                value={welcomingVideoText}
                onChange={(e) => setWelcomingVideoText(e.target.value)}
                placeholder="Titel"
              />
              <Button
                onClick={() => {
                  AdminService.setAdminConfiguration({
                    key: "CMS.welcomingVideoText",
                    value: welcomingVideoText,
                  });
                }}
              >
                Speichern
              </Button>
            </div>
            <div className="flex flex-col gap-4">
              <Upload
                loading={videoLoading}
                name="video"
                onChange={handleVideoUpload}
                accept="video/*"
              >
                <Button icon={<UploadOutlined />} loading={videoLoading}>
                  Video hochladen
                </Button>
              </Upload>
              <Upload
                loading={thumbnailLoading}
                name="thumbnail"
                onChange={handleThumbnailUpload}
                accept="image/*"
              >
                <Button icon={<UploadOutlined />} loading={thumbnailLoading}>
                  Thumbnail hochladen
                </Button>
              </Upload>
            </div>

            {config?.CMS?.enableWelcomingVideo && (
              <div className="!w-full flex flex-col items-center justify-center mb-10">
                {welcomingVideoText && (
                  <p
                    className={`secondary:w-[65%] mb-2 text-xl font-bold text-center `}
                    dangerouslySetInnerHTML={{
                      __html: welcomingVideoText,
                    }}
                  ></p>
                )}

                <video
                  playsInline
                  className={`aspect-video secondary:w-[65%] rounded-sm object-cover shadow-[0_8px_30px_rgb(0,0,0,0.12)] bg-[#000000] p-1 rounded-sm bg-opacity-50`}
                  controls
                  poster={config.CMS.welcomingVideoThumbnail}
                >
                  <source
                    src={config?.CMS?.welcomingVideoAtSubscriptionPage}
                    type="video/mp4"
                  />
                  <source
                    src={config?.CMS?.welcomingVideoAtSubscriptionPage}
                    type="video/webm"
                  />
                  <source
                    src={config?.CMS?.welcomingVideoAtSubscriptionPage}
                    type="video/quicktime"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </div>
        </CollapseItem>

        <CollapseItem title={"Upgrade Video"}>
          <div className="p-8 flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <Switch
                checked={config?.CMS?.enableUpgradeVideo}
                onChange={handleVideoToggle}
              />
              <span>Upgrade Video aktivieren</span>
            </div>
            <div className="flex items-center gap-4">
              <Input
                value={upgradeVideoText}
                onChange={(e) => setUpgradeVideoText(e.target.value)}
                placeholder="Titel"
              />
              <Button
                onClick={() => {
                  AdminService.setAdminConfiguration({
                    key: "CMS.upgradeVideoText",
                    value: upgradeVideoText,
                  });
                }}
              >
                Speichern
              </Button>
            </div>
            <div className="flex flex-col gap-4">
              <Upload
                loading={videoLoading2}
                name="video"
                onChange={handleVideoUpload2}
                accept="video/*"
              >
                <Button icon={<UploadOutlined />} loading={videoLoading2}>
                  Video hochladen
                </Button>
              </Upload>
              <Upload
                loading={thumbnailLoading2}
                name="thumbnail"
                onChange={handleThumbnailUpload2}
                accept="image/*"
              >
                <Button icon={<UploadOutlined />} loading={thumbnailLoading2}>
                  Thumbnail hochladen
                </Button>
              </Upload>
            </div>

            {config?.CMS?.enableUpgradeVideo && (
              <div className="!w-full flex flex-col items-center justify-center mb-10">
                {upgradeVideoText && (
                  <p
                    className={`secondary:w-[65%] mb-2 text-xl font-bold text-center `}
                    dangerouslySetInnerHTML={{
                      __html: upgradeVideoText,
                    }}
                  ></p>
                )}

                <video
                  playsInline
                  className={`aspect-video secondary:w-[65%] rounded-sm object-cover shadow-[0_8px_30px_rgb(0,0,0,0.12)] bg-[#000000] p-1 rounded-sm bg-opacity-50`}
                  controls
                  poster={config.CMS.upgradeVideoThumbnail}
                >
                  <source
                    src={config?.CMS?.upgradeVideoAtSubscriptionPage}
                    type="video/mp4"
                  />
                  <source
                    src={config?.CMS?.upgradeVideoAtSubscriptionPage}
                    type="video/webm"
                  />
                  <source
                    src={config?.CMS?.upgradeVideoAtSubscriptionPage}
                    type="video/quicktime"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </div>
        </CollapseItem>

        <CollapseItem title={"Erklärfilm"}>
          <div className="p-8 flex flex-col gap-4">
            <div className="flex flex-col gap-4">
              <Upload
                loading={videoLoading3}
                name="video"
                onChange={handleVideoUpload3}
                accept="video/*"
              >
                <Button icon={<UploadOutlined />} loading={videoLoading3}>
                  Video hochladen
                </Button>
              </Upload>
              <Upload
                loading={thumbnailLoading3}
                name="thumbnail"
                onChange={handleThumbnailUpload3}
                accept="image/*"
              >
                <Button icon={<UploadOutlined />} loading={thumbnailLoading3}>
                  Thumbnail hochladen
                </Button>
              </Upload>
            </div>

            {config?.CMS?.erklarFilm && (
              <div className="!w-full flex flex-col items-center justify-center mb-10">
                <video
                  playsInline
                  className={`aspect-video secondary:w-[65%] rounded-sm object-cover shadow-[0_8px_30px_rgb(0,0,0,0.12)] bg-[#000000] p-1 rounded-sm bg-opacity-50`}
                  controls
                  poster={config.CMS.upgradeVideoThumbnail}
                >
                  <source src={config?.CMS?.erklarFilm} type="video/mp4" />
                  <source src={config?.CMS?.erklarFilm} type="video/webm" />
                  <source
                    src={config?.CMS?.erklarFilm}
                    type="video/quicktime"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
          </div>
        </CollapseItem>

        <CollapseItem title={"WhatsApp Support Nr."}>
          <div className="p-8 flex flex-col gap-4">
            <div className="flex smx:flex-col gap-2">
              <Input
                type="text"
                value={whatsappNummer}
                onChange={(e) => setWhatsappNummer(e.target.value)}
              />

              <Button
                type="primary"
                onClick={onWhatsappSave}
                loading={loadingWhatsapp}
              >
                Speichern
              </Button>
            </div>
          </div>
        </CollapseItem>
      </div>
    </div>
  );
};

export default Index;
