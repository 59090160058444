import {
  Button,
  Checkbox,
  Divider,
  Input,
  Modal,
  Popconfirm,
  Skeleton,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
  message,
  Select as AntdSelect,
} from "antd";
import moment from "moment/moment";
import { useEffect, useRef, useState } from "react";
import { BsArrowUpRight, BsLink45Deg } from "react-icons/bs";
import { FiTrash } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import FloatLabel from "../../FloatingLabel";
import { NewSimple, Pencil } from "./Svgs";
import MessageModal from "./sendMessageModal";

import { LinkOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import Paragraph from "antd/es/skeleton/Paragraph";
import Column from "antd/es/table/Column";
import { default as classNames } from "classnames";
import { ExternalLink } from "lucide-react";
import { LuPhone } from "react-icons/lu";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { selectLoading } from "../../../redux/auth/selectors";
import EmpfehlungService from "../../../service/EmpfehlungService";
import PramienService from "../../../service/PramienService";
import PromoterService from "../../../service/PromoterService";
import PromotionsLinkService from "../../../service/PromotionsLinkService";
import { copyToClipboard } from "../../../utils/CopyToClipboard";
import { aboTarif } from "../../../utils/divers";
import { PopUpComponent } from "../../ConfirmPopup";

const label = {
  anrede: "Anrede",
  firstname: "Vorname",
  lastname: "Nachname",
  email: "E-Mail",
  phone: "Telefon",
  shortCode: "Linkname",
};

export const LoadingDiv = ({ children, loading, className }) => (
  <div className={className}>
    {loading ? <Spin>{children}</Spin> : children}
  </div>
);
const ExpandedCardVersionTwo = ({
  data,
  expanded,
  className,
  props,
  manipulateTop,
  bg,
  promoterLinks,
  setList,
  options,
  updatePromoter,
  deleteLead,
  getExpandedData,
  getLists,
  user,
}) => {
  const [openmodel, setopenmodel] = useState(false);
  const [form, setForm] = useState({
    anrede: data?.anrede || "",
    firstname: data?.firstname || "",
    lastname: data?.lastname || "",
    email: data?.email || "",
    phone: data?.phone || "",
    shortCode: data?.shortCode || "",
  });

  const getGermanPlaceholder = (field, form) => {
    return {
      anrede: "Anrede",
      firstname: form?.anrede === "Firma" ? "Firmenname" : "Vorname",
      lastname: form?.anrede === "Firma" ? "Rechtsform" : "Nachname",
      email: "Email",
      phone: "Telefon",
    }[field];
  };
  const [title, setTitle] = useState("");
  const [promoterLink, setPromoterLink] = useState("");
  const [SecondDropDown, setSecondDropDown] = useState(false);
  const [FirstDropDown, setFirstDropDown] = useState(false);
  const [value, setvalue] = useState("");
  const [promoterLastActivity, setPromoterLastActivity] = useState(null);
  const [selectValue, setselectvalue] = useState();
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [newOpen, setNewOpen] = useState(false);
  const [begleittextModal, setBegleittextModal] = useState(false);
  const [begleittextEdit, setBegleittextEdit] = useState("");
  const [formNew, setFormNew] = useState({
    firstname: "",
    lastname: "",
    phone: "",
  });
  const loading = useSelector(selectLoading);
  const [promoDataLoading, setPromoDataLoading] = useState(false);
  const [formLoading, setFormLoading] = useState({
    newPromoLink: false,
    notiz: false,
    empfehlung: false,
    begleittext: false,
    guthaben: false,
    promoter: false,
  });

  const handleChange = (e) => {
    const value = e.target ? e.target.value : e;
    const name = e.target ? e.target.name : "anrede";
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeNew = (e) => {
    const value = e.target ? e.target.value : e;
    const name = e.target ? e.target.name : "anrede";
    setFormNew((prev) => ({ ...prev, [name]: value }));
  };

  const notzRef = useRef();
  const cardExpandedRef = useRef();
  const whatsAppTextRef = useRef();
  const pramienRef = useRef();
  const handleOk = () => {
    setFormLoading({ notiz: true });
    setList({
      key: "notes",
      value: [
        ...data?.notes,
        {
          text: newNote,
          timestamp: new Date(),
        },
      ],
    });
    setFormLoading({ notiz: false });
    setNewNote("");
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setPromoDataLoading(true);
    setTimeout(() => {
      setPromoDataLoading(false);
    }, 1500);
  }, [expanded]);

  useEffect(() => {
    if (expanded) {
      setTimeout(() => {
        manipulateTop(cardExpandedRef?.current?.offsetHeight - 100);
      }, 500);
    }
  }, [expanded, cardExpandedRef, notzRef, pramienRef, manipulateTop, data]);

  useEffect(() => {
    const load = async () => {
      if (!data) return;

      const res = await PromoterService.getPromoterLink(data._id);
      setPromoterLink(res.data.link);
    };
    load();
  }, [data]);

  const [editopen, setEditOpen] = useState(false);
  // useEffect(() => {
  //   if (notes?.length > 0) {
  //     setSecondDropDown(true);
  //   }
  // }, [notes]);

  // useEffect(() => {
  //   PromoterService.getLastSiteClickOfPromoter(data._id).then((response) =>
  //     setPromoterLastActivity(response.data.lastClick)
  //   );
  // }, [data]);
  if (!data) {
    return <Skeleton active />;
  }
  return (
    <div
      className="w-full    absolute  pointer-events-none  transition-all duration-1000  rounded-11xl"
      {...props}
      onClick={() => {
        props.onClick(() => {
          setFirstDropDown(false);
          setSecondDropDown(true);
          // setTimeout(() => {
          //   manipulateTop(
          //     cardExpandedRef?.current?.offsetHeight * 0.8 +
          //       notzRef?.current?.offsetHeight ||
          //       200 + pramienRef?.current?.offsetHeight ||
          //       200
          //   );
          // }, 500);
        });
      }}
    >
      <MessageModal
        close={() => setopenmodel(false)}
        open={openmodel}
        promoter_id={data._id}
      />
      <Modal
        open={editopen}
        title="Promoter bearbeiten"
        onCancel={() => setEditOpen(false)}
        destroyOnClose
        okText="Speichern"
        okButtonProps={{ loading: formLoading.promoter }}
        onOk={async () => {
          setFormLoading({ promoter: true });
          await updatePromoter(setEditOpen, data, form);
          await getExpandedData(data._id);
          setFormLoading({ promoter: false });
        }}
      >
        <div className="my-10">
          {Object.keys(form).map((e, i) => (
            <div className="mt-5 px-6 font-semibold text-base ">
              <div className="font-bold text-xs">
                {getGermanPlaceholder(e, form)}{" "}
                {aboTarif(user) < 3 &&
                  e === "shortCode" &&
                  `(Premium erforderlich)`}
              </div>

              {e === "anrede" ? (
                <AntdSelect
                  required={true}
                  tabIndex={i + 1}
                  name={e}
                  value={form[e]}
                  onChange={handleChange}
                  size="small"
                  prefixCls="promoterregselect"
                  className="border-[1px] h-full w-full shadow-none  p-2 flex-grow  font-semibold border-[#d6d6d6] outline-none bg-transparent  "
                >
                  <option value="Herr">Herr</option>
                  <option value="Frau">Frau</option>
                  <option value="Divers">Divers</option>
                  <option value="Firma">Firma</option>
                </AntdSelect>
              ) : (
                <Input
                  placeholder={getGermanPlaceholder(e, form)}
                  name={e}
                  value={form[e]}
                  onChange={handleChange}
                  disabled={aboTarif(user) < 3 && e === "shortCode"}
                />
              )}
            </div>
          ))}
        </div>
      </Modal>
      <Modal
        title="Neue Notiz"
        open={isModalOpen}
        onOk={handleOk}
        okButtonProps={{ loading: formLoading.notiz }}
        okText="Speichern"
        onCancel={handleCancel}
      >
        <TextArea
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
        />
      </Modal>
      <Modal
        open={open}
        title="Neuer Promotionlink"
        okText="Erstellen"
        onCancel={() => setOpen(false)}
        destroyOnClose
        okButtonProps={{ loading: formLoading.newPromoLink }}
        onOk={async () => {
          setFormLoading({ newPromoLink: true });
          if (title?.length === 0) {
            return message.error("Titel ist erforderlich");
          }
          if (!selectValue?.value) {
            return message.error("Bitte eine Landingpage auswählen");
          }
          try {
            await PromotionsLinkService.create({
              promoter_id: data._id,
              title,
              landingpage_id: selectValue.value,
            });
            await getExpandedData(data._id);
            setTitle("");
            setselectvalue({});
            getLists();
          } catch (error) {
            console.log(error);
          } finally {
            setFormLoading({ newPromoLink: false });
            setOpen(false);
          }
        }}
      >
        <div className="my-10 black-search">
          <Input
            className="my-3 px-6 font-semibold text-base "
            placeholder="Titel"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Select
            className="!border-none  font-semibold text-[#000000] bg-transparent !text-base !outline-none hover:!outline-none !shadow-none"
            placeholder="Suche nach einer Vorlage"
            options={options}
            value={selectValue}
            onChange={(e) => setselectvalue(e)}
            classNamePrefix={"super-select"}
          />
        </div>
      </Modal>
      <div
        className={` h-full w-full relative top-[11%]  rounded-[30px]       ${className}`}
      >
        <svg
          className="absolute right-0 w-[555px] md:block hidden top-[-100px] z-[17] pointer-events-none"
          height="102"
          viewBox="0 0 555 102"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M553.5 100.5H155.769C158.295 98.7366 160.335 96.2715 161.582 93.3094L187.705 31.2724C195.302 13.2314 212.97 1.5 232.545 1.5H525C540.74 1.5 553.5 14.2599 553.5 30V100.5Z"
            fill={bg}
            stroke="#D8D8D8"
            strokeWidth="2"
          />
        </svg>
        <svg
          width="265"
          className="absolute right-[1px]  md:hidden  top-[-79px] z-[17] pointer-events-none"
          height="81"
          viewBox="0 0 265 81"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M-85.2032 103C-85.2032 91.7977 -76.2489 82.7162 -65.2032 82.7162H-25.0156H-9.932C2.03887 82.7162 12.7016 75.0411 16.6527 63.5809L27.8826 31.0079C34.3272 12.3154 51.719 -0.203247 71.2445 -0.203247H244.797C255.843 -0.203247 264.797 8.87828 264.797 20.0805V71.2968C264.797 82.7162 264.797 81.7968 244.797 81.7968H-65.2032C-76.2489 81.7968 -85.2032 72.7152 -85.2032 61.5129V103Z"
            fill={bg}
            stroke="#D8D8D8"
            strokeWidth="2"
          />
        </svg>
        <div
          className="absolute md:right-0 right-[-100px] top-[-80px]  md:top-[-100px] w-[386px] pointer-events-auto h-[100px] z-[17]"
          onClick={() => props.onClick()}
        >
          <div className="text-center h-full flex  items-center ps-[4rem]">
            {" "}
            <h1
              className={classNames(
                `leading-[38px] md:w-[300px] w-[200px] whitespace-normal hyphens-auto  text-white font-medium `,
                {
                  "text-xl  md:text-[28px]": expanded,
                  "text-base md:text-[20px]": !expanded,
                }
              )}
              style={
                bg[data.status]?.[0] === "#FFE465" ? { color: "black" } : {}
              }
            >
              {data?.firstname}
              {"  "} {data?.lastname}
            </h1>
          </div>
        </div>
        <div
          className={`z-50 relative p-6  transition-all duration-1000  rounded-[30px_0px_30px_30px] border-editor border-[2px] border-[#D8D8D8] pointer-events-auto antialiased `}
          style={{
            background: bg,
          }}
        >
          <div className="specialeditor" style={{ color: bg }}>
            <svg
              width="52"
              height="65"
              viewBox="0 0 52 65"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M270.5 6V1681C270.5 1691.22 262.217 1699.5 252 1699.5H-58C-68.2173 1699.5 -76.5 1691.22 -76.5 1681V82.6154C-76.5 72.3981 -68.2173 64.1154 -58 64.1154H-17.8124H-1.61235C10.3614 64.1154 21.0754 56.6776 25.2613 45.4593L35.8546 17.0688C42.4889 -0.711495 59.47 -12.5 78.4476 -12.5H252C262.217 -12.5 270.5 -4.21727 270.5 6Z"
                fill="currentColor"
                stroke="#D8D8D8"
                stroke-width="2"
              />
            </svg>
          </div>
          <div className="desktopspecialeditor">
            <svg
              width="76"
              height="81"
              viewBox="0 0 76 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M423.5 16V413C423.5 428.74 410.74 441.5 395 441.5H-655C-670.74 441.5 -683.5 428.74 -683.5 413V107.943C-683.5 92.2026 -670.74 79.4427 -655 79.4427H-130H-9.52728H17.07C23.2811 79.4427 28.9303 75.8464 31.559 70.2191L56.5106 16.8054C64.8627 -1.07384 82.8116 -12.5 102.545 -12.5H395C410.74 -12.5 423.5 0.259888 423.5 16Z"
                fill={bg}
                stroke="#D8D8D8"
                stroke-width="2"
              />
            </svg>
          </div>

          <div className="super-line" style={{ background: bg }}></div>

          {expanded ? (
            <div className="pb-[200px]" ref={cardExpandedRef}>
              <div className="white-text-div-folder overflow-hidden flex flex-col gap-3 bg-white p-2 rounded-[15px] ">
                <div className="whitespace-nowrap overflow-hidden text-ellipsis gap-10 flex justify-between flex-wrap">
                  {data?.anrede !== "Firma" && (
                    <div>
                      <p>Name</p>
                      <h5> {`${data?.firstname} ${data?.lastname}`}</h5>
                    </div>
                  )}
                  {data?.anrede === "Firma" && (
                    <div>
                      <p>Firmenname</p>
                      <h5> {`${data?.firstname} ${data?.lastname}`}</h5>
                    </div>
                  )}
                  <div>
                    <p>Telefon</p>
                    <h5>
                      <a href={`tel:${data?.phone}`}>{data?.phone}</a>
                    </h5>
                  </div>
                  <div>
                    <p>E-Mail des Promoters</p>
                    <h5>
                      <a href={`mailto:${data?.email}`}>{data?.email}</a>
                    </h5>
                  </div>
                  <div>
                    <p>Erstellt am </p>
                    <h5>{moment(data?.createdAt).format("D.M.YYYY")}</h5>
                  </div>
                  <div>
                    <p>Aufrufe </p>
                    <h5>{data?.clicks}</h5>
                  </div>
                  <div>
                    <p>Anzahl Empfehlungen</p>
                    <h5>{data?.empfehlungen}</h5>
                  </div>
                </div>
                <div className="whitespace-nowrap overflow-hidden text-ellipsis gap-10 flex justify-between flex-wrap">
                  <div>
                    <p>Anzahl Neukunden</p>
                    <h5>{data?.kunden}</h5>
                  </div>
                  <div className="blueswitch">
                    <p>Prämiensystem</p>
                    <Switch
                      checked={data?.pramiensystem}
                      onChange={(e) =>
                        setList({ key: "pramiensystem", value: e })
                      }
                    />
                  </div>
                  <div className="blueswitch">
                    <p>Braucht Erlaubnis zum Prämieneinlösen</p>
                    <Switch
                      checked={data?.needsPermissionToRedeem}
                      onChange={(e) =>
                        setList({ key: "needsPermissionToRedeem", value: e })
                      }
                    />
                  </div>
                  <div className="greenswitch">
                    <p>Email bestätigt </p>
                    <Switch
                      checked={data?.allowsAutomatedMessaging}
                      disabled={true}
                    />
                  </div>
                  <div>
                    <p>letzter Seitenaufruf </p>
                    <h5>
                      {data?.lastClick
                        ? moment(data?.lastClick).format("D.M.YYYY")
                        : "Keine Klicks"}
                    </h5>
                  </div>
                  <div>
                    <p>Zugang Prämien-/ Empfehlungsportal</p>
                    <Space>
                      <Button
                        type="primary"
                        size="small"
                        className="whitespace-normal h-fit mr-2"
                        onClick={async () => {
                          if (loading) return;
                          await PromoterService.createPromoterAccess(data._id);
                        }}
                      >
                        An Promoter versenden
                      </Button>

                      <Link to={promoterLink} target="_blank">
                        <ExternalLink size={18} className="cursor-pointer" />
                      </Link>
                    </Space>
                  </div>
                </div>

                <div className="mt-4 ms-3 card-table  h-[150px]">
                  <Table
                    bordered={false}
                    className="overflow-auto  h-[150px] "
                    dataSource={[...promoterLinks]}
                    pagination={false}
                    rowKey={"uid"}
                  >
                    {[...promoterLinks].length > 0 && !promoDataLoading ? (
                      <>
                        <Column
                          title="Promotionslinks"
                          render={(e, _, i) => (
                            <div className="flex gap-2">
                              <div className=" ">
                                <h5 key={i}>{e.title}</h5>
                              </div>
                            </div>
                          )}
                          className="font-bold text-[16px] min-w-[150px] "
                        ></Column>

                        <Column
                          title="Begleittext"
                          render={(e, _, i) => (
                            <div className="flex gap-2">
                              <div className=" ">
                                <Button
                                  size="sm"
                                  onClick={() => {
                                    setBegleittextModal(e);
                                    setBegleittextEdit(e.begleittext);
                                  }}
                                >
                                  Ändern
                                </Button>
                              </div>
                            </div>
                          )}
                          className="font-bold text-[16px]"
                        ></Column>
                        <Column
                          title=""
                          render={(e, _, i) => {
                            return (
                              <div className="flex gap-2 relative">
                                <div className="grid    ">
                                  <h5 className="flex items-center gap-2  ">
                                    <p className="flex items-center hover:underline ">
                                      (
                                      <p className="text-ellipsis whitespace-nowrap  w-[100px] overflow-hidden">
                                        {process.env
                                          .REACT_APP_SHORTENER_APP_LINK +
                                          `/${e?.promoter_id?.shortCode}/${e.num}`}
                                      </p>
                                      )
                                    </p>
                                    <BsArrowUpRight
                                      className="cursor-pointer"
                                      onClick={() => {
                                        window.open(
                                          process.env
                                            .REACT_APP_SHORTENER_APP_LINK +
                                            `/${e?.promoter_id?.shortCode}/${e.num}`
                                        );
                                      }}
                                    />
                                    <Tooltip
                                      title="Kopiert"
                                      color="green"
                                      trigger={"click"}
                                      mouseLeaveDelay={100}
                                      prefixCls="ant-custom-tooltip"
                                    >
                                      <div
                                        style={{ display: "none" }}
                                        dangerouslySetInnerHTML={{
                                          __html: user?.promotionWhatsApp,
                                        }}
                                        ref={whatsAppTextRef}
                                      ></div>
                                      <BsLink45Deg
                                        className="cursor-pointer"
                                        onClick={() => {
                                          const link =
                                            process.env
                                              .REACT_APP_SHORTENER_APP_LINK +
                                            `/${e?.promoter_id?.shortCode}/${e.num}`;

                                          let finalText = e?.begleittext
                                            ?.replace?.(
                                              /{Promoter Vorname}/g,
                                              data?.firstname
                                            )
                                            ?.replace?.(
                                              /{Promoter Nachname}/g,
                                              data?.lastname
                                            )
                                            ?.replace?.(
                                              /{Partner Vorname}/g,
                                              user?.firstname
                                            )
                                            ?.replace?.(
                                              /{Partner Nachname}/g,
                                              user?.lastname
                                            )
                                            ?.replace?.(/{Promolink}/g, link);

                                          if (!finalText.includes(link))
                                            finalText += ` ${link}`;

                                          copyToClipboard(finalText);
                                        }}
                                      />
                                    </Tooltip>
                                    <Popconfirm
                                      className="primary-btn"
                                      title="Bist Du sicher, dass Du diesen Link löschen möchtest? Dein Promoter kann diesen Link dann nicht mehr nutzen.?"
                                      okText="Löschen"
                                      cancelText="Abbrechen"
                                      onConfirm={async () => {
                                        await PromotionsLinkService.delete(
                                          e._id
                                        );
                                        await getExpandedData(data._id);
                                      }}
                                    >
                                      <FiTrash
                                        color="#E81900"
                                        className="cursor-pointer"
                                      />
                                    </Popconfirm>
                                  </h5>
                                </div>
                              </div>
                            );
                          }}
                          className="font-bold text-[16px]"
                        ></Column>
                        <Column
                          title="Promotionlink erstellt am"
                          className="font-bold text-[16px]"
                          render={(_) => (
                            <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                              {`${moment(_.createdAt).format("D.M.YYYY")}`}
                            </div>
                          )}
                        ></Column>

                        <Column
                          title="Empfehlungsportal"
                          render={(_, record) => (
                            <LoadingDiv loading={loading} className="w-[28px]">
                              {aboTarif(user) < 2 ? (
                                <Tooltip title="Bitte abonniere den Standardtarif">
                                  <img
                                    src="/images/img_info_gray.svg"
                                    alt="Info Icon"
                                    className="h-[28px] rounded-lg cursor-pointer"
                                  />
                                </Tooltip>
                              ) : record?.freigabeEmpfehlungsportal ? (
                                <img
                                  src="/images/img_info_green.svg"
                                  alt="Info Icon"
                                  className="h-[28px] rounded-lg cursor-pointer bg-[#ff0000]"
                                  onClick={async () => {
                                    if (loading) return;
                                    await PromotionsLinkService.setLinkFreigabeEmpfehlungsportal(
                                      record._id,
                                      false
                                    );
                                    await getExpandedData(data._id);
                                  }}
                                />
                              ) : (
                                <img
                                  src="/images/img_info_red.svg"
                                  alt="Info Icon"
                                  className="h-[28px] rounded-lg cursor-pointer"
                                  onClick={async () => {
                                    if (loading) return;
                                    await PromotionsLinkService.setLinkFreigabeEmpfehlungsportal(
                                      record._id,
                                      true
                                    );
                                    await getExpandedData(data._id);
                                  }}
                                />
                              )}
                            </LoadingDiv>
                          )}
                          className="font-bold text-[16px]"
                        ></Column>
                      </>
                    ) : (
                      <Column
                        className="w-full"
                        render={(_) => (
                          <Skeleton
                            title={true}
                            paragraph={false}
                            className="w-full"
                          />
                        )}
                      ></Column>
                    )}
                  </Table>
                </div>

                <div className="flex items-center flex-wrap gap-3 my-10">
                  <Button
                    type="primary"
                    className="bg-[#001D47] rounded-[15px ] flex items-center gap-3 text-white"
                    onClick={() => setOpen((prev) => !prev)}
                  >
                    <NewSimple /> <h1>Neuen Promotionslink anlegen</h1>
                  </Button>
                  <Button
                    onClick={() => setEditOpen(true)}
                    type="primary"
                    className="bg-[#001D47] rounded-[15px ] flex items-center gap-3 text-white"
                  >
                    <Pencil /> <h1>Promoter bearbeiten</h1>
                  </Button>

                  <Button
                    onClick={() => {
                      setNewOpen(data._id);
                    }}
                    type="primary"
                    className="bg-[#001D47]  flex items-center gap-3 text-white whitespace-break-spaces !h-fit"
                  >
                    Empfehlung nachtragen
                  </Button>

                  {!data?.emailConfirmed && (
                    <Button
                      onClick={() => {
                        PromoterService.sendConfirmationLink(data._id);
                      }}
                      type="primary"
                      className="bg-[#001D47]  flex items-center gap-3 text-white whitespace-break-spaces !h-fit"
                    >
                      Email Bestätigungslink erneut senden
                    </Button>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap items-center gap-3 my-10">
                <Button
                  onClick={() => setopenmodel(true)}
                  type={
                    user?.subscription?.tier === "Basic" ||
                    !user?.subscription?.tier
                      ? null
                      : "primary"
                  }
                  disabled={
                    user?.subscription?.tier === "Basic" ||
                    !user?.subscription?.tier
                  }
                  className={`bg-[#001D47] !text-[#ffffff] ${
                    user?.subscription?.tier === "Basic" ||
                    !user?.subscription?.tier
                      ? "!bg-[#ababab]"
                      : ""
                  }`}
                >
                  Nachricht schicken
                  {(user?.subscription?.tier === "Basic" ||
                    !user?.subscription?.tier) && (
                    <span className="text-xs">(Upgrade benötigt)</span>
                  )}
                </Button>
                <Button
                  className="bg-[#E81900] !text-white border-none"
                  onClick={() =>
                    PopUpComponent({
                      onConfirm: async () => {
                        await deleteLead();
                      },
                      heading: "Promoter Löschen",
                      subheading: `Bist du dir sicher, dass du den Promoter ${data?.firstname} ${data?.lastname} löschen möchtest?`,
                      confirmButtonText: "Löschen",
                      cancelButtonText: "Abbrechen",
                    })
                  }
                >
                  Diesen Promoter löschen
                </Button>
              </div>
              <div className="flex items-center gap-3 text-white mt-10">
                <p
                  className="cursor-pointer"
                  onClick={() => {
                    setFirstDropDown(!FirstDropDown);
                    if (FirstDropDown) {
                      manipulateTop(
                        (prev) => prev - pramienRef.current.offsetHeight
                      );
                      setTimeout(() => {
                        manipulateTop(
                          (prev) => prev + pramienRef.current.offsetHeight
                        );
                      }, 500);
                    } else {
                      manipulateTop(
                        (prev) => prev - pramienRef.current.offsetHeight
                      );
                      setTimeout(() => {
                        manipulateTop(
                          (prev) => prev + pramienRef.current.offsetHeight
                        );
                      }, 500);
                    }
                  }}
                >
                  Prämien
                </p>
                <IoIosArrowDown
                  className="transition-all cursor-pointer"
                  onClick={() => {
                    setFirstDropDown(!FirstDropDown);
                    if (FirstDropDown) {
                      manipulateTop(
                        (prev) => prev - pramienRef.current.offsetHeight
                      );
                      setTimeout(() => {
                        manipulateTop(
                          (prev) => prev + pramienRef.current.offsetHeight
                        );
                      }, 500);
                    } else {
                      manipulateTop(
                        (prev) => prev - pramienRef.current.offsetHeight
                      );
                      setTimeout(() => {
                        manipulateTop(
                          (prev) => prev + pramienRef.current.offsetHeight
                        );
                      }, 500);
                    }
                  }}
                  style={{
                    rotate: !FirstDropDown ? "180deg" : "0deg",
                  }}
                />
              </div>
              <div
                className="transition-all pt-5 "
                style={{
                  display: FirstDropDown ? "none" : "block",
                }}
                ref={pramienRef}
              >
                <div className="white-text-div-folder flex sm:flex-nowrap flex-wrap justify-between rounded-[15px] bg-white sm:h-[93px] overflow-hidden sm:py-0 py-4">
                  <div className=" ms-3 card-table sm:basis-1/3 basis-full h-[93px]">
                    <Table
                      bordered={false}
                      className="overflow-auto  h-[93px] "
                      dataSource={
                        Array.isArray(data?.pramien) ? [...data?.pramien] : []
                      }
                      pagination={false}
                      rowKey={"uid"}
                    >
                      {(Array.isArray(data?.pramien) ? [...data?.pramien] : [])
                        .length > 0 ? (
                        <>
                          <Column
                            title="Eingelöst am"
                            dataIndex={"redeemedAt"}
                            render={(_) =>
                              _ ? moment(_).format("D.M.YYYY") : ""
                            }
                            className="font-bold text-[16px]"
                          ></Column>
                          <Column
                            title="Wert"
                            dataIndex={"valueInEUR"}
                            className="font-bold text-[16px]"
                            render={(_) => `${_?.toFixed(2)} EUR`}
                          ></Column>
                        </>
                      ) : (
                        <></>
                      )}
                    </Table>
                  </div>
                  <Divider className="sm:hidden block" />

                  <div className="border-l-[1px] relative  border-r-[1px] w-full flex justify-center items-center border-[#868686]">
                    <div className="flex gap-3 relative scale-90 black-search">
                      <FloatLabel
                        label={"Manuelle Gutschrift"}
                        value={value}
                        keepitfocused
                      >
                        <Input
                          className="rounded-[10px]"
                          value={value}
                          placeholder="min. 5€ max. 100€"
                          onChange={(e) => setvalue(e.target.value)}
                        />
                      </FloatLabel>
                      <Button
                        className="bg-[#001D47] rounded-[10px]"
                        type="primary"
                        loading={formLoading.guthaben}
                        onClick={async () => {
                          setFormLoading({ guthaben: true });
                          await PramienService.manuellBonus({
                            promoter_id: data?._id,
                            valueInEUR: value,
                          });
                          setList({
                            key: "credits",
                            value: data?.credits + parseFloat(value),
                          });
                          setFormLoading({ guthaben: false });
                          setvalue("");
                        }}
                      >
                        {" "}
                        Betrag hinzufügen{" "}
                      </Button>
                    </div>
                    <MdOutlineKeyboardDoubleArrowRight
                      width={35}
                      height={35}
                      fontSize={40}
                      color="#D8D8D8"
                      className="absolute right-0  primary:inline hidden"
                    />
                  </div>
                  <Divider className="sm:hidden block" />
                  <div className="w-full flex justify-center items-center sm:basis-1/3 basis-full">
                    <div className="text-center">
                      <p>Aktuelles Guthaben</p>
                      <h5>
                        {(data?.credits).toFixed(2).replace(".", ",")} EUR
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-3 text-white mt-10 transition-all ">
                <p
                  className="cursor-pointer"
                  onClick={() => {
                    setSecondDropDown(!SecondDropDown);
                    if (SecondDropDown) {
                      manipulateTop(
                        (prev) => prev - notzRef.current.offsetHeight
                      );
                    } else {
                      setTimeout(() => {
                        manipulateTop(
                          (prev) => prev + notzRef.current.offsetHeight
                        );
                      }, 500);
                    }
                  }}
                >
                  Notizen
                </p>
                <IoIosArrowDown
                  className="transition-all cursor-pointer"
                  onClick={() => {
                    console.log(notzRef.current.offsetHeight);
                    setSecondDropDown(!SecondDropDown);
                    if (SecondDropDown) {
                      manipulateTop(
                        (prev) => prev - notzRef.current.offsetHeight
                      );
                    } else {
                      setTimeout(() => {
                        manipulateTop(
                          (prev) => prev + notzRef.current.offsetHeight
                        );
                      }, 500);
                    }
                  }}
                  style={{
                    rotate: !SecondDropDown ? "180deg" : "0deg",
                  }}
                />
              </div>
              <div
                className=" !transition-all pt-5"
                style={{
                  display: !SecondDropDown ? "none" : "block",
                }}
                ref={notzRef}
              >
                <Button
                  type="primary"
                  className="bg-[#001D47] my-3"
                  onClick={() => setIsModalOpen(true)}
                >
                  Neue Notiz
                </Button>
                <div className="flex flex-col gap-3 pt-3">
                  {data?.notes?.length > 0 ? (
                    [...data.notes]
                      .sort((a, b) => {
                        const date1 = new Date(a?.timestamp);
                        const date2 = new Date(b?.timestamp);

                        return date2 - date1;
                      })
                      ?.map((e, i) => (
                        <div
                          key={i}
                          className="  bg-white py-[1rem] w-[97%] px-[2rem] rounded-[15px] relative"
                        >
                          <div>
                            <p className=" text-[12px] text-[#868686]">
                              {moment(e?.timestamp).format("D.M.YYYY - HH:mm")}
                            </p>
                            <p>{e?.text}</p>
                            <div className="bg-white border border-[#d8d8d8]  p-2 absolute top-[-10px] right-[-10px] rounded-[8px] w-fit">
                              <RxCross1
                                className="cursor-pointer"
                                onClick={() => {
                                  PopUpComponent({
                                    onConfirm: () => {
                                      setList({
                                        key: "notes",
                                        value: [...data.notes].filter(
                                          (fe) => fe._id !== e._id
                                        ),
                                      });
                                    },
                                    heading:
                                      "Bist Du dir sicher, dass du diese Notiz löschen möchtest?",
                                    confirmButtonText: "Löschen",
                                    cancelButtonText: "Abbrechen",
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))
                  ) : (
                    <div className="text-white">Noch keine Notizen</div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div
              className=" text-white h-[100px] relative"
              style={{
                zIndex: (props.style.zIndex + 1) * 1,
              }}
            >
              <div className="md:flex hidden items-center gap-3">
                <p className="text-sm text-white">Telefon</p>
                <h5 className="text-white font-bold text-base">
                  <a href={`tel:${data.phone}`}>{data.phone}</a>
                </h5>
              </div>

              <a
                href={`tel:${data.phone}`}
                className="text-white md:hidden text-primary"
              >
                <LuPhone />
              </a>
            </div>
          )}
        </div>
      </div>

      <Modal
        open={newOpen}
        title="Empfehlung nachtragen"
        onCancel={() => setNewOpen(false)}
        okText="Zustimmen & anlegen"
        okButtonProps={{ loading: formLoading.empfehlung }}
        onOk={async () => {
          setFormLoading({ empfehlung: true });
          try {
            if (loading) return;
            if (!formNew?.checkbox)
              return message.info(
                "Bitte bestätige, dass diese Empfehlung die Erlaubnis erteilt hat."
              );
            for (let i = 0; i < Object.keys(formNew).length; i++) {
              const key = Object.keys(formNew)[i];
              if (formNew[key].length === 0) {
                message.error(
                  `${key.replace(key[0], key[0].toUpperCase())} erforderlich`
                );
                return;
              }
            }
            await EmpfehlungService.create({
              ...formNew,
              promoter_id: newOpen,
            }).then(() => getLists());
            setNewOpen(false);
            setFormNew({
              firstname: "",
              lastname: "",
              phone: "",
            });
          } catch (error) {}
          setFormLoading({ empfehlung: false });
        }}
      >
        <div className="my-10 black-search">
          {Object.keys(formNew)
            .filter((key) => key !== "checkbox")
            .map((e, i) => (
              <Input
                className="my-3 px-6 font-semibold text-base "
                placeholder={getGermanPlaceholder(e, formNew)}
                name={e}
                required={e !== "lastname" || form.anrede !== "Firma"}
                value={formNew[e]}
                onChange={handleChangeNew}
              />
            ))}

          <div className="mt-5">
            Du bist gesetzlich dazu verpflichtet eine Erlaubnis dieser Person
            ein zu holen. Ohne Erlaubnis dürfen keine Daten von Dritten bei
            EinGuterTipp eingesetzt werden.
          </div>
          <div className=" flex items-start justify-start tertiary:gap-4 gap-1">
            <Checkbox
              value={formNew?.checkbox}
              onChange={(e) =>
                setFormNew((prev) => ({ ...prev, checkbox: e.target.checked }))
              }
            />
            <p className="text-ellipsis text-start hyphens-auto ">
              Ich bestätige, dass diese Empfehlung mir die Erlaubnis erteilt
              hat, sie hinzufügen zu dürfen.
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        open={begleittextModal}
        title="Begleittext anpassen"
        onCancel={() => setBegleittextModal(false)}
        okText="Anpassen"
        okButtonProps={{ loading: formLoading.begleittext }}
        onOk={async () => {
          setFormLoading({ begleittext: true });
          await PromotionsLinkService.update(begleittextModal._id, {
            begleittext: begleittextEdit,
          });
          await getExpandedData(data._id);
          setFormLoading({ begleittext: false });
          setBegleittextEdit("");
          setBegleittextModal(false);
        }}
      >
        <TextArea
          rows={4}
          value={begleittextEdit}
          onChange={(e) => setBegleittextEdit(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export { ExpandedCardVersionTwo };
