import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class LandingPageService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/landingPage`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });
    middleField(this.api);
  }

  list() {
    return this.api.get("/list");
  }

  detail(id) {
    console.log("landingPageService.detail()");
    return this.api.get(`/detail?id=${id}`);
  }

  create(data) {
    return this.api.post("/create", data);
  }

  duplicate(id) {
    console.log(id);
    return this.api.post("/duplicate", { id });
  }

  clickCheck({ landingpage_id, click_id }) {
    return this.api.post("/clickCheck", { landingpage_id, click_id });
  }

  update(id, data) {
    console.log("update()");
    return this.api.put(`/update?id=${id}`, data);
  }

  click(data) {
    return this.api.post("/click", data);
  }

  getStats(id) {
    return this.api.get(`/getStats?id=${id}`);
  }
  deleteLandingPage(id) {
    return this.api.delete(`/deleteLandingPage/${id}`);
  }
}

export default new LandingPageService();
