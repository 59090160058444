import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class PromoterPublicService {
  constructor() {
    this.baseURL = `${getBackendUrl()}/promoterPublic`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });
    middleField(this.api);
  }

  createRedeemRequest(token, valueInEUR) {
    const value = parseInt(valueInEUR);
    if (isNaN(value)) throw new Error(`Not a number: ${valueInEUR}`);
    return this.api.post("/createRedeemRequest", { valueInEUR: value, token });
  }

  getPramienList(token) {
    return this.api.get(`/getPramienList?token=${token}`);
  }

  getPromotionslinks(token) {
    return this.api.get(`/getPromotionslinks?token=${token}`);
  }

  getStats(token) {
    return this.api.get(`/getStats?token=${token}`);
  }
  detail(token, id) {
    return this.api.get(`/detail?id=${id}&token=${token}`);
  }
  changeAllowances(token, data) {
    return this.api.put(`/changeAllowances`, { ...data, token });
  }
  bonusClaim({ token, bonus }) {
    return this.api.post(`/bonusClaim?token=${token}`, { bonus });
  }
}

export default new PromoterPublicService();
