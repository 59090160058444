import React from "react";

const TypeButtonConfig = ({
  type,
  text,
  typeButtonColor, // Benutzerdefinierte Farbe
  onClick,
  className,
  isBasicQuestion,
}) => {
  // Standard-Stile für die Typen definieren
  const defaultStyles = {
    finance: "rounded-full",
    stormgas: "rounded-0",
    property: "rounded-sm",
    basic: isBasicQuestion ? "rounded-full" : "md:!text-sm rounded-full",
  };

  // Standard-Hintergrundfarben definieren
  const defaultBackgrounds = {
    finance:
      "linear-gradient(152deg, #00DEF7 0%, #0084F7 49.48%, #001D47 100%)",
    stormgas:
      "linear-gradient(152deg, #DBEF5D 0%, #B8D01D 49.48%, #8DA10B 100%)",
    property: "darkslateblue",
    basic: isBasicQuestion
      ? "linear-gradient(90deg, #77420c 0%, #fae49b 100%)"
      : "linear-gradient(152deg, #00DEF7 0%, #0084F7 49.48%, #001D47 100%)",
  };

  // Standard-Shadowfarben definieren
  const defaultShadows = {
    finance: "rgba(0, 222, 247, 0.5)",
    stormgas: "rgba(219, 239, 93, 0.5)",
    property: "rgba(72, 61, 139, 0.5)",
    basic: isBasicQuestion
      ? "rgba(250, 228, 155, 0.5)"
      : "rgba(0, 222, 247, 0.5)",
  };

  // Dynamische Hintergrundfarbe und Shadow basierend auf `typeButtonColor` oder Standard
  const backgroundStyle =
    typeButtonColor !== "" ? typeButtonColor : defaultBackgrounds[type];
  const shadowStyle =
    typeButtonColor !== "" ? typeButtonColor : defaultShadows[type];


  // Button-Klasse basierend auf Typ bestimmen
  const buttonClass = defaultStyles[type] || defaultStyles["basic"]; 

  return (
    <div className={`relative flex justify-center ${className}`}>
      <button
        onClick={onClick}
        style={{
          background: backgroundStyle, // Dynamische Hintergrundfarbe
        }}
        className={`${buttonClass} 
        relative
          mx-auto  
          gap-4 
          items-center 
          justify-center 
          font-semibold 
          text-white 
          flex 
          text-sm
          py-4
          px-7
          md:text-xl
          md:py-4
          md:px-10`}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "105%", // Breite proportional zur Button-Breite
            height: "105%", // Höhe proportional zur Button-Höhe
            borderRadius: "50%", // Einheitliche Rundung für den Shadow
            background: shadowStyle, // Dynamische Shadow-Farbe
            filter: "blur(20px)", // Blur-Effekt
            zIndex: -1, // Schatten hinter dem Button
          }}
        />
        {text}
      </button>
    </div>
  );
};

export default TypeButtonConfig;
