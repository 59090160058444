import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FloatLabel from "../../FloatingLabel";

const HTMLTextEditor = ({
  quillattributes,
  value = "",
  onChange = () => {},
  label = "",
  variables,
  options = {},
}) => {
  const textBeautifier = (val) => {
    let newText = val;
    for (let variable of variables) {
      newText = newText.replaceAll(
        variable,
        `<span style="color:#00def7">${variable}</span>`
      );
    }
    return newText;
  };

  const removeTrailingEmptyParagraphs = (content) => {
    let cleaned = content;

    while (/<p><br><\/p>\s*$/gm.test(cleaned)) {
      cleaned = cleaned.replace(/<p><br><\/p>\s*$/gm, "");
    }

    return cleaned;
  };

  const onQuillChange = (content, delta, source, editor) => {
    const cleaned = removeTrailingEmptyParagraphs(content);

    onChange(cleaned);
  };

  return (
    <div {...quillattributes}>
      <FloatLabel
        label={label}
        keepitfocused
        focusClass="!text-base font-semibold text-[#D8D8D8] ms-[5px]"
      >
        <ReactQuill
          // Optional: Falls du die Variablen dynamisch "einfärben" willst,
          // könntest du "textBeautifier" hier anwenden.
          // Falls du das nur bei der Anzeige willst,
          // setz das lieber in einer Preview-Komponente ein.
          value={value}
          onChange={onQuillChange}
          {...options}
        />
      </FloatLabel>
    </div>
  );
};

export default HTMLTextEditor;
