import React, { useState, useEffect } from "react";
import PublicService from "../../../../service/PublicService";
import { sendEventUser } from "../../../../utils/analytics";
import { useMessageContext } from "../../../../context/messageContext";
import constructWhatsappLink from "../../../../utils/whatsappLink";

const BasicForm = ({
  landingPageData,
  promoter_id,
  clickId,
  id,
  user,
  promoter,
}) => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [privacy, setPrivacy] = useState(false);
  const [disableButtons, setDisableButtons] = useState(true);
  const { showMessage, clearMessage } = useMessageContext();

  useEffect(() => {
    setDisableButtons(
      !(name && number && /^[+]?\d{7,15}$/.test(number) && privacy)
    );
  }, [name, number, privacy]);

  const onSubmit = async (submitType) => {
    if (!name || !number) {
      showMessage("error", "Bitte alle Felder ausfüllen");
      return;
    }

    if (!/^[+]?\d{7,15}$/.test(number)) {
      showMessage("error", "Bitte gültige Nummer eingeben!");
      return;
    }

    if (!privacy) {
      showMessage("error", "Du musst der Datenschutzerklärung zustimmen");
      return;
    }

    setDisableButtons(true);
    showMessage("loading", "Anfrage wird bearbeitet...", 0, "popup-key");

    try {
      sendEventUser({
        category: "button",
        action: `click_wa1`,
        label: "wa1",
        value: 1,
        landingPageId: landingPageData._id,
      });
      if (promoter_id !== "viewmode" && submitType === "recall") {
        await PublicService.createEmpfehlung({
          type: submitType,
          firstname: name,
          phone: number,
          promoter_id: promoter_id,
          user_id: landingPageData?.user_id,
          landingpageaufruf_id: clickId,
          landingpage_id: id,
        });
        showMessage(
          "success",
          `Vielen Dank, ${name.trim()}. Wir werden Sie bald unter ${number.trim()} zurückrufen.`
        );
        setName("");
        setNumber("");
        setPrivacy(false);
      } else if (submitType === "whatsapp") {
        const text = `Hi ${user.firstname.trim()}. Ich bin ${name.trim()} und komme auf Empfehlung von ${promoter.firstname.trim()} ${promoter.lastname.trim()}. Ich suche Unterstützung zu folgendem Thema:`;
        return (window.location.href = constructWhatsappLink(
          landingPageData?.whatsappNumber,
          text
        ));
      }
      clearMessage("popup-key");
    } catch (error) {
      showMessage(
        "error",
        "Es gab einen Fehler bei der Verarbeitung Ihrer Anfrage. Bitte versuchen Sie es später erneut."
      );
    } finally {
      setDisableButtons(false);
    }
  };

  return (
    <div className="flex items-center justify-center w-screen bg-gray-100">
      <div className="w-[90%] xs:w-[80%] p-5 bg-white rounded-sm max-w-[500px] shadow-lg">
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-3">
            <InputField
              label="Dein Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <InputField
              label="Mobilnummer"
              type="tel"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </div>

          <Datenschutz
            checkbox={privacy}
            setCheckbox={setPrivacy}
            text={landingPageData.datenSchutzText}
          />

          <div className="flex flex-col gap-3 md:flex-row">
            <Button
              text={landingPageData.whatsappButtonTextOne}
              backgroundColor="#FFD814"
              textColor="black"
              onClick={() => onSubmit("recall")}
              disabled={disableButtons}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicForm;

const Button = ({
  backgroundColor = "grey",
  text,
  textColor = "white",
  onClick,
  disabled,
}) => (
  <button
    onClick={() => !disabled && onClick()}
    style={{ background: backgroundColor, color: textColor }}
    className={`w-full p-3 font-semibold rounded-sm md:p-4 text-md md:text-lg ${
      disabled ? "opacity-50 cursor-not-allowed" : "opacity-100"
    }`}
  >
    {text}
  </button>
);

const InputField = ({ label, type = "text", value, onChange }) => (
  <div className="flex flex-col gap-1">
    <label className="font-semibold text-sm">{label}</label>
    <input
      type={type}
      value={value}
      onChange={onChange}
      className="p-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
    />
  </div>
);

const Datenschutz = ({ checkbox, setCheckbox, text }) => (
  <div className="flex flex-col gap-2">
    <p className="text-sm font-semibold text-gray-600">{text}</p>
    <div className="flex items-center gap-2">
      <input
        type="checkbox"
        checked={checkbox}
        onChange={(e) => setCheckbox(e.target.checked)}
        className="w-4 h-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
      />
      <span className="text-sm font-semibold text-gray-700">
        Ich stimme der Datenschutzerklärung zu
      </span>
    </div>
  </div>
);
