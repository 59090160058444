import { Button, message, Space, Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MainLogo } from "../../../assets/Svgs/GUTERTIPP";
import PublicService from "../../../service/PublicService";
import { aboTarif } from "../../../utils/divers";
import LoadingScreen from "../../_NEW/LandingPage/loadingScreen/LoadingScreen";
import { Button as BlueButton } from "../../Einstellungen/components";
import FloatLabel from "../../FloatingLabel";
import Confirmation from "../PromoterRegisration/Confirmation";
import PromotionsLinkService from "../../../service/PromotionsLinkService";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoShareOutline } from "react-icons/io5";

const PromoterRegistrationLink = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [nachrichtZumVerschicken, setNachrichtZumVerschicken] = useState("");
  const [link, setLink] = useState("");
  const navigate = useNavigate();

  React.useEffect(() => {
    const getUserName = async () => {
      try {
        const { data } = await PublicService.getUserNameOfPromoter(id);
        setUser(data);
        const tmp = document.createElement("div");
        tmp.innerHTML =
          data?.standardLandingpage?.begleitTextTemplate !== ""
            ? data?.standardLandingpage?.begleitTextTemplate
            : data.promotionWhatsApp;

        setNachrichtZumVerschicken(
          (tmp.textContent || tmp.innerText)
            ?.replace?.(/{Promolink}/, "")
            ?.replace?.("{Promoter Vorname}", `${data?.promoter?.firstname}`)
            ?.replace?.("{Promoter Nachname}", `${data?.promoter?.lastname}`)
        );

        setLink(
          process.env.REACT_APP_SHORTENER_APP_LINK +
            `/${data?.promoter?.shortCode}/${1}`
        );
      } catch (error) {
        console.log(error);
      }
    };
    getUserName();
  }, [id]);

  const handleWhatsapp = useCallback(() => {
    let finalText = nachrichtZumVerschicken;
    finalText += ` ${link}`;

    window.open(
      `https://wa.me/?text=${encodeURIComponent(finalText)}`,
      "_blank"
    );
  }, [link, nachrichtZumVerschicken]);

  const handleShare = useCallback(() => {
    let finalText = nachrichtZumVerschicken;
    finalText += ` ${link}`;

    if (navigator.share) {
      navigator
        .share({
          title: "Empfehlung",
          text: finalText,
          url: link,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      // Fallback to email
      window.location.href = `mailto:?subject=Empfehlung&body=${encodeURIComponent(
        finalText
      )}`;
    }
  }, [link, nachrichtZumVerschicken]);

  useEffect(() => {
    if (user && !user?.standardLandingpage)
      navigate(
        "/notfound?note=Deine Registrierung war erfolgreich. Bitte bestätige noch Deine Emailadresse. Den Link dazu haben wir Dir bereits zugesendet."
      );
    if (user && aboTarif(user) < 2)
      navigate(
        "/notfound?note=Diese Funktion ist für dieses Konto zurzeit nicht möglich."
      );
  }, [navigate, user]);

  if (!user) {
    return <LoadingScreen />;
  }
  if (!user?.standardLandingpage) {
    return <Confirmation silent />;
  }
  if (aboTarif(user) < 2) {
    return <Confirmation silent />;
  }
  return (
    <div className="overflow-auto py-3 w-full max-w-[2000px] m-auto">
      <div className="tertiary:w-6/12 sm:w-8/12 w-11/12 m-auto mb-12">
        <div className="ml-1 mb-5 flex items-center justify-between gap-5 md:ml-0 w-full">
          <div className=" text-[18px] mt-6 mb-6  text-[#000000] xl:text-primary text-responsiveHeading font-bold">
            Empfehlung verschicken
          </div>

          <Tooltip title="Jede Empfehlung, die sich über diesen Link im System einträgt wird Dir zugewiesen.">
            <Button
              type="default"
              className={`${"min-w-[40px] h-[40px]"} p-0 justify-center items-center rounded-[10px] text-center border-[1px] border-[#d8d8d8] `}
            >
              <AiOutlineInfoCircle fontSize={20} />
            </Button>
          </Tooltip>
        </div>

        <FloatLabel
          label={"Dein persönlicher Empfehlungslink"}
          unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full "
          className={" flex-grow"}
          value={link}
          disabled
        >
          <div className="w-full flex justify-between items-center ">
            <input
              type="text"
              className="text-[#535353] text-[16px] border-[1px] border-[#d8d8d8] pr-10 outline-none rounded-[15px] p-[15px_12.5px] w-full !appearance-none"
              value={link}
              disabled
            />

            <img
              src="/images/clone 1.svg"
              alt="Arrow Up"
              className="h-[24px] w-[20px] object-contain absolute right-3 cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(link);
                message.info("Kopiert");
              }}
            />
          </div>
        </FloatLabel>

        <br />
        <br />

        <FloatLabel
          label={"Deine Nachricht"}
          unFocusClass="sm:!top-[15px] !top-[20px]   text-ellipsis text-[16px] !font-semibold  !w-full "
          className={" flex-grow"}
          value={nachrichtZumVerschicken}
          onChange={(e) => setNachrichtZumVerschicken(e.target.value)}
        >
          <div className="w-full flex justify-between items-center ">
            <textarea
              rows={6}
              className="border-[1px] border-[#d8d8d8] outline-none rounded-[15px] p-[15px_12.5px] w-full !appearance-none !text-[16px]"
              value={nachrichtZumVerschicken}
              onChange={(e) => setNachrichtZumVerschicken(e.target.value)}
            />
          </div>
        </FloatLabel>

        <BlueButton
          shape="round"
          className="w-full ml-1 mt-[55px] rounded-[10px] px-[34px] md:ml-0 sm:px-5 smx:text-[14px]"
          onClick={handleWhatsapp}
        >
          <Space>
            <FaWhatsapp size={20} />
            Jetzt per WhatsApp versenden
          </Space>
        </BlueButton>

        <BlueButton
          shape="round"
          className="w-full ml-1 mt-[15px] rounded-[10px] px-[34px] md:ml-0 sm:px-5 smx:text-[14px]"
          onClick={handleShare}
        >
          <Space>
            <IoShareOutline size={20} />
            Jetzt anderweitig teilen
          </Space>
        </BlueButton>
      </div>

      <div className="sm:w-[95%] m-auto flex justify-center items-center mt-[60px]">
        <div className="flex  justify-left sm:justify-between font-semibold text-[12px] sm:text-[16px]  gap-5 sm:gap-20 my-5 w-full px-5">
          <h1 className="flex-1 whitespace-wrap">©</h1>
          <div className="flex flex-1 items-center gap-2 sm:gap-10 justify-end">
            <Link
              to={`${window.location.origin}/impressum/${user?._id}`}
              target="_blank"
            >
              <span>Impressum</span>
            </Link>
            <Link to={"/datenschutz"} target="_blank">
              Datenschutz
            </Link>
            <Link
              to={`${window.location.origin}/teilnahmebedingungen/${user?._id}`}
              target="_blank"
            >
              Teilnahmebedingungen
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoterRegistrationLink;
