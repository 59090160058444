import React, { useEffect, useRef, useState } from "react";

export function InfoTooltip({ imageUrl, alt = "Information", fixed = false }) {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const tooltipRef = useRef(null);
  const triggerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target)
      ) {
        setIsTooltipVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      setIsTooltipVisible(false);
    }
  };

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  return (
    <div className="relative inline-block">
      <button
        ref={triggerRef}
        className="inline-flex items-center justify-center rounded-full w-8 h-8 text-blue-600 hover:text-blue-700 transition-colors focus:outline-none "
        onClick={() => setIsTooltipVisible(!isTooltipVisible)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={() => setIsTooltipVisible(!isTooltipVisible)}
        onKeyDown={handleKeyDown}
        aria-expanded={isTooltipVisible}
        aria-haspopup="true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-5 w-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span className="sr-only">Show information</span>
      </button>
      {(isTooltipVisible || isHovering) && (
        <div
          ref={tooltipRef}
          className={`${
            fixed ? "fixed left-[40px] " : "absolute right-[-28px]"
          } z-10  mt-2  rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
          style={{ minWidth: fixed ? 400 : 280 }}
          role="tooltip"
        >
          <div className="rounded-lg overflow-hidden">
            <img
              src={imageUrl}
              style={{ minWidth: fixed ? 400 : 280 }}
              alt={alt}
              className="w-full h-auto"
            />
          </div>
        </div>
      )}
    </div>
  );
}
