import {
  EyeOutlined,
  ImportOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { Col, Divider, Row, Segmented, Skeleton, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import AnalyticService from "../../../service/AnalyticService";

const EGTStats = () => {
  const [loadingAnalytics, setLoadingAnalytics] = useState(false);
  const [analyticsData, setAnalyticsData] = useState({
    pageView: 0,
    activeUser: 0,
    eg1: 0,
    eg2: 0,
    eg3: 0,
    eg4: 0,
    reg1: 0,
    reg2: 0,
    egvideo: 0,
    watchtime: 0,
  });
  const [range, setRange] = useState("alle");

  const toPercent = (views, clicks) => {
    return views === 0 || clicks === 0
      ? "0%"
      : `${Math.round((clicks / views) * 100)}%`;
  };

  const cacheAnalyticsData = (data, range) => {
    const cacheKey = `egt_analytics_${range || "default"}`;
    const cachedData = {
      data,
      timestamp: Date.now(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cachedData));
  };

  const getCachedAnalyticsData = (range) => {
    const cacheKey = `egt_analytics_${range || "default"}`;
    const cachedData = localStorage.getItem(cacheKey);
    if (!cachedData) return null;

    const parsedData = JSON.parse(cachedData);
    const cacheDuration = 2 * 60 * 60 * 1000; // 2 hours

    if (Date.now() - parsedData.timestamp < cacheDuration) {
      return parsedData.data;
    }
    return null;
  };

  const cleanupCache = () => {
    const cachePrefix = "egt_analytics_";
    const keys = Object.keys(localStorage);
    const now = Date.now();
    const cacheDuration = 2 * 60 * 60 * 1000;

    keys.forEach((key) => {
      if (key.startsWith(cachePrefix)) {
        const cachedData = JSON.parse(localStorage.getItem(key));
        if (now - cachedData.timestamp > cacheDuration) {
          localStorage.removeItem(key);
        }
      }
    });
  };

  const fetchAnalytic = async () => {
    cleanupCache();
    setLoadingAnalytics(true);
    try {
      const cachedData = getCachedAnalyticsData(range);
      if (cachedData) {
        setAnalyticsData(cachedData);
        setLoadingAnalytics(false);
        return;
      }

      const { data } = await AnalyticService.getEGTAnalytics(range);

      const activeUser =
        data.find((item) => item.activeUsers)?.activeUsers || 0;
      const pageView =
        data.find((item) => item.page_view)?.page_view.eventCount || 0;

      const newAnalyticsData = {
        pageView: pageView,
        activeUser: activeUser,
        eg1: toPercent(
          pageView,
          data.find((item) => item.click_eg1)?.click_eg1.eventCount || 0
        ),
        eg2: toPercent(
          pageView,
          data.find((item) => item.click_eg2)?.click_eg2.eventCount || 0
        ),
        eg3: toPercent(
          pageView,
          data.find((item) => item.click_eg3)?.click_eg3.eventCount || 0
        ),
        eg4: toPercent(
          pageView,
          data.find((item) => item.click_eg4)?.click_eg4.eventCount || 0
        ),
        reg1: toPercent(
          pageView,
          data.find((item) => item.click_reg1)?.click_reg1.eventCount || 0
        ),
        reg2: toPercent(
          pageView,
          data.find((item) => item.click_reg2)?.click_reg2.eventCount || 0
        ),
        egvideo: data.find((item) => item.play)?.play.eventCount || 0,
        watchtime:
          data.find((item) => item.watchtime)?.watchtime.eventCount || 0,
      };

      setAnalyticsData(newAnalyticsData);
      cacheAnalyticsData(newAnalyticsData, range);
    } catch (error) {
      console.error("Error fetching analytics data:", error);
    } finally {
      setLoadingAnalytics(false);
    }
  };

  useEffect(() => {
    fetchAnalytic();
  }, [range]);

  const stats = [
    { title: "Aktive Nutzer", value: analyticsData.activeUser },
    { title: "Aufrufe Gesamt", value: analyticsData.pageView },
    { title: "Kostenlos testen", value: analyticsData.eg1 },
    { title: "Click Erstgespräch 2", value: analyticsData.eg2 },
    { title: "Click Erstgespräch 3", value: analyticsData.eg3 },
    { title: "Click Erstgespräch 4", value: analyticsData.eg4 },
    { title: "Click jetzt mitmachen", value: analyticsData.reg1 },
    { title: "Click jetzt Kunden gewinnen", value: analyticsData.reg2 },
    { title: "Video Aufrufe", value: analyticsData.egvideo },
    {
      title: "Video Watchtime",
      value: analyticsData.egvideo
        ? `${Math.round(analyticsData.watchtime / analyticsData.egvideo)}%`
        : "0%",
    },
  ];

  return (
    <div>
      <Segmented
        options={["Heute", "letzte 7 Tage", "letzte 30 Tage", "alle"]}
        value={range}
        default="alle"
        onChange={(value) => {
          setRange(value);
        }}
      />
      <Row
        gutter={[64, 22]}
        className="bg-[#FAFAFA] p-12 !ml-0 w-full lg:w-full"
      >
        <div className="w-full flex justify-center mb-4">
          <EyeOutlined className="text-blue-500 text-2xl" />
        </div>

        {stats.slice(0, 2).map((stat, index) => (
          <Col
            key={index}
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="p-4"
          >
            <Statistic
              title={
                loadingAnalytics ? (
                  <Skeleton />
                ) : (
                  <h1 className="text-white text-xs mb-4">{stat.title}</h1>
                )
              }
              value={stat.value}
              className="shadow-xl p-4 rounded-xl bg-blue-main h-32"
              valueStyle={{ color: "#ffffff" }}
            />
          </Col>
        ))}

        <Divider />
        <div className="w-full flex justify-center mb-4">
          <ImportOutlined className="text-blue-500 text-2xl" />
        </div>

        {stats.slice(2, 8).map((stat, index) => (
          <Col
            key={index}
            xs={24}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className="p-4"
          >
            <Statistic
              title={
                loadingAnalytics ? (
                  <Skeleton />
                ) : (
                  <h1 className="text-white text-xs mb-4">{stat.title}</h1>
                )
              }
              value={stat.value}
              className="shadow-xl p-4 rounded-xl bg-blue-main h-32"
              valueStyle={{ color: "#ffffff" }}
            />
          </Col>
        ))}

        <Divider />
        <div className="w-full flex justify-center mb-4">
          <PlayCircleOutlined className="text-blue-500 text-2xl" />
        </div>

        {stats.slice(8, 10).map((stat, index) => (
          <Col
            key={index}
            xs={24}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="p-4"
          >
            <Statistic
              title={
                loadingAnalytics ? (
                  <Skeleton />
                ) : (
                  <h1 className="text-white text-xs mb-4">{stat.title}</h1>
                )
              }
              value={stat.value}
              className="shadow-xl p-4 rounded-xl bg-blue-main h-32"
              valueStyle={{ color: "#ffffff" }}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default EGTStats;
