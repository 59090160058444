"use client";

import {
  Button,
  Checkbox,
  Modal,
  Radio,
  Space,
  Switch,
  Tooltip,
  message,
} from "antd";
import { Check, X } from "lucide-react";
import moment from "moment";
import { useCallback, useState } from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../redux/auth/actions";
import { selectLoading } from "../../redux/auth/selectors";
import { store } from "../../redux/store";
import SubscriptionService from "../../service/SubscriptionService";
import UserService from "../../service/UserService";
import { features, tiers } from "../Home/MakeYouFit";

const TARIFFS = {
  Basic: { price: 39, price2: 55, label: "Basic" },
  Standard: { price: 79, price2: 105, label: "Standard" },
  Premium: { price: 149, price2: 199, label: "Premium" },
};

export default function SubscriptionComponent({
  user: initialUser,
  subscription: initialSubscription,
}) {
  const [user, setUser] = useState(initialUser);
  const [subscription, setSubscription] = useState(initialSubscription);
  const [kundigungModal, setKundigungModal] = useState(false);
  const [popupComponent, setPopupComponent] = useState(null);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [checkboxTarifWechselValue, setCheckboxTarifWechselValue] =
    useState(false);
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);

  const handleToggleAutoVerlangerung = useCallback(
    async (newValue) => {
      const canToggle = !moment(subscription.cancel_at * 1000).isBefore(
        moment().add(1, "months")
      );

      if (process.env.NODE_ENV !== "development" && !canToggle) {
        return message.info(
          "Die automatische Verlängerung kann nicht ausgeschaltet werden, da die Kündigungsfrist 1 Monat zum Laufzeitende beträgt."
        );
      }

      if (!newValue) {
        setCheckboxValue(false);
        setKundigungModal(true);
        return;
      }

      message.success(
        newValue
          ? "Automatische Verlängerung wurde eingeschaltet"
          : "Automatische Verlängerung wurde ausgeschaltet"
      );

      try {
        await UserService.updateUserInfo({ autoRenewals: newValue });
        const { data: me } = await UserService.me();
        setUser(me.user);
        const updatedSubscription =
          await SubscriptionService.getSubscriptionInfo();

        console.log(updatedSubscription);
        setSubscription(updatedSubscription?.data?.subscription);
      } catch (error) {
        console.error("Error updating auto-renewal:", error);
        message.error(
          "Fehler beim Aktualisieren der automatischen Verlängerung"
        );
      }
    },
    [user, subscription]
  );

  const handleConfirmCancel = useCallback(async () => {
    if (!checkboxValue) {
      return message.info("Bitte kreuze das Kästchen an");
    }

    setUser((u) => ({ ...u, autoRenewals: false }));
    message.success("Automatische Verlängerung wurde ausgeschaltet");

    try {
      await UserService.updateUserInfo({ autoRenewals: false });

      const { data: me } = await UserService.me();
      setUser(me.user);
    } catch (error) {
      console.error("Error confirming cancellation:", error);
      message.error("Fehler beim Bestätigen der Kündigung");
    }
  }, [checkboxValue]);

  const handleTariffChange = useCallback(
    (newTier) => {
      if (newTier === user.subscription?.tier) return;

      if (user.subscription?.futurePlan) {
        return setPopupComponent({
          heading: "Umstellung aktuell nicht möglich!",
          subheading: (
            <>
              Du hast bereits einen{" "}
              <span className="text-[#0084f7] font-bold">Anschlusstarif</span>{" "}
              ausgewählt. Dieser startet automatisch zur nächsten
              Beitragszahlung und ersetzt dein{" "}
              <span className="text-[#001d47] font-bold">
                aktuelles Abonnement
              </span>
              . Weitere Änderungen sind erst wieder möglich, wenn der von dir
              gewählte{" "}
              <span className="text-[#0084f7] font-bold">Anschlusstarif</span>{" "}
              aktiv ist.
            </>
          ),
          hideButtons: true,
        });
      }

      const currentTierIndex = Object.keys(TARIFFS).indexOf(
        user.subscription?.tier
      );
      const newTierIndex = Object.keys(TARIFFS).indexOf(newTier);

      if (newTierIndex > currentTierIndex) {
        setPopupComponent({
          selection: newTier,
          heading: "TARIFWECHSEL!",
          subheading: `Du möchtest ein Upgrade in den ${TARIFFS[newTier].label}tarif durchführen! Dein neuer Lizenzbeitrag beträgt somit ${TARIFFS[newTier].price},00€ mtl (zzgl. Umsatzsteuer). Der erste Monat wird anteilig mit Deinem bereits gezahlten Betrag verrechnet. Die neuen Features stehen dir umgehend zur Verfügung. Dein Zugang zur OnlineAkademie erfolgt in Kürze.`,
          checkbox: "Ich möchte diesen Tarifwechsel jetzt durchführen",
        });
      } else {
        setPopupComponent({
          selection: newTier,
          heading: "Tarifwechselanfrage",
          subheading: `Du möchtest in den ${TARIFFS[newTier].label}-Tarif wechseln? Kontaktiere dazu bitte den Support mit dem Stichwort „Downgrade" und gib unbedingt auch deine Rufnummer an, damit wir dich für Details erreichen können.`,
          checkbox:
            "Mir ist bewusst, dass durch diesen Wechsel wichtige Features verloren gehen und dies erst zum Ende der aktuellen Laufzeitperiode möglich ist.",
        });
      }
    },
    [user.subscription]
  );

  const confirmTariffChange = useCallback(async () => {
    if (!checkboxTarifWechselValue) {
      return message.info("Bitte kreuze das Kästchen an");
    }

    const newTier = popupComponent.selection;
    const currentTierIndex = Object.keys(TARIFFS).indexOf(
      user.subscription?.tier
    );
    const newTierIndex = Object.keys(TARIFFS).indexOf(newTier);

    if (newTierIndex < currentTierIndex) {
      return navigate("/dashboard/support");
    }

    setPopupComponent(null);

    const load = message.loading(
      "Einen Moment - wir schalten Deinen neuen Features frei"
    );
    try {
      await UserService.updateUserInfo({
        newTariff: newTier,
      });
      const { data: me } = await UserService.me();
      setUser(me.user);
      store.dispatch(login(me));

      const updatedSubscription =
        await SubscriptionService.getSubscriptionInfo();
      setSubscription(updatedSubscription?.data?.subscription);
      message.success(`Tarifwechsel zu ${TARIFFS[newTier].label} erfolgreich.`);
    } catch (error) {
      console.error("Error updating subscription:", error);
      message.error("Fehler beim Tarifwechsel. Bitte versuchen Sie es erneut.");
      load();
    }
  }, [popupComponent, user.subscription, checkboxTarifWechselValue, navigate]);

  return (
    <div
      className="text-white p-5 rounded-11xl shadow-md mt-10"
      style={{ background: "#0084F7" }}
    >
      <div className="flex justify-between gap-4 items-center mb-5">
        <h2 className="text-md md:text-xl font-semibold">Lizenzbeitrag</h2>
        <p className="text-md md:text-lg">
          {user?.adminSpecifiedSubscriptionPrice ||
            TARIFFS[user.subscription?.tier]?.[
              user?.subscription?.annualBilling ? "price" : "price2"
            ]}
          ,00€ / Monat (zzgl. MwSt.
          {user?.subscription?.annualBilling ? " jährliche Abrechnung" : ""})
        </p>
      </div>

      {!user?.specialPricingSystem && (
        <div className="flex justify-between gap-4 items-center mb-5">
          <h2 className="text-md md:text-xl font-semibold">
            {subscription?.cancel_at_period_end
              ? "Ablaufdatum"
              : "Nächste Zahlung"}
          </h2>
          <p className="text-md md:text-lg">
            {moment(subscription?.current_period_end * 1000).format(
              "Do MMM, YYYY"
            )}{" "}
            {subscription?.cancel_at_period_end
              ? "(wird nicht verlängert)"
              : ""}
          </p>
        </div>
      )}

      {user?.specialPricingSystem && !user?.finalAboDate && (
        <div className="flex justify-between gap-4 items-center mb-5">
          <h2 className="text-md md:text-xl font-semibold">Nächste Zahlung</h2>
          <p className="text-md md:text-lg">
            {moment(subscription?.current_period_end * 1000).format(
              "Do MMM, YYYY"
            )}{" "}
          </p>
        </div>
      )}

      {user?.specialPricingSystem && user?.finalAboDate && (
        <div className="flex justify-between gap-4 items-center mb-5">
          <h2 className="text-md md:text-xl font-semibold">Ablaufdatum</h2>
          <p className="text-md md:text-lg">
            {moment(user?.finalAboDate).format("Do MMM, YYYY")}{" "}
          </p>
        </div>
      )}

      <div className="flex justify-between gap-4 items-center mb-5">
        <Space>
          <h2 className="text-md md:text-xl font-semibold">
            Automatische Verlängerung
          </h2>
          <Tooltip
            placement="left"
            title={
              user?.specialPricingSystem ? (
                <>
                  {user?.subscription?.annualBilling ? (
                    <>
                      <div>
                        Aktiviert: Dein Abo verlängert sich automatisch um ein
                        Jahr
                      </div>
                      <div>
                        Deaktiviert: Dein Abo endet mit einer Kündigungsfrist
                        von 3 Monaten zum Ablauf des aktuellen Abrechnungsjahres
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        Aktiviert: Dein Abo verlängert sich automatisch um einen
                        Monat
                      </div>
                      <div>
                        Deaktiviert: Dein Abo endet mit einer Kündigungsfrist
                        von 1 Monat zum Ablauf des aktuellen Abrechnungsmonats
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div>
                    Aktiviert: Dein Abo verlängert sich automatisch um 12 Monate
                  </div>
                  <div>
                    Deaktiviert: Dein Abo endet zum Ablauf der aktuellen
                    12-Monats-Periode
                  </div>
                </>
              )
            }
          >
            <IoIosInformationCircleOutline color="#001D47" size={22} />
          </Tooltip>
        </Space>
        <Switch
          checked={user?.autoRenewals}
          onChange={handleToggleAutoVerlangerung}
        />
      </div>

      <div className="flex justify-between gap-4 items-center mb-5">
        <Space>
          <h2 className="text-md md:text-xl font-semibold">Tarifauswahl</h2>
          <Tooltip
            placement="top"
            overlayInnerStyle={{ width: 340, maxWidth: "100vw" }}
            title={
              <>
                <div className="bg-[#0184f8] rounded-lg p-2 text-[10px] w-[325px] max-w-[100vw]">
                  <div className="grid grid-cols-4 grid-cols-[150px_1fr_1fr_1fr] gap-1 mb-1">
                    <div className="text-white font-semibold">
                      <div>Features</div>
                      {user?.specialPricingSystem && (
                        <div className="text-[9px] font-thin">
                          monatlicher Beitrag{" "}
                          {user?.subscription?.annualBilling &&
                            "bei jährlicher Abrechnung"}
                        </div>
                      )}
                    </div>

                    {tiers.map((tier) => (
                      <div key={tier.name} className="text-center">
                        <div className="text-white font-semibold ">
                          {tier.name}
                        </div>
                        <div className="bg-[#001d47] text-white rounded p-1 mt-1">
                          {
                            tier?.[
                              user?.subscription?.annualBilling
                                ? "price"
                                : "price2"
                            ]
                          }
                        </div>
                      </div>
                    ))}
                  </div>

                  {features.map((feature, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-4 grid-cols-[150px_1fr_1fr_1fr] gap-1 items-center py-[2px]"
                    >
                      <div className="text-white ">{feature.name}</div>
                      {[feature.basic, feature.standard, feature.premium].map(
                        (value, i) => (
                          <div
                            key={i}
                            className="flex justify-center items-center bg-[#001d47] rounded h-5"
                          >
                            {typeof value === "boolean" ? (
                              value ? (
                                <Check className="w-3 h-3 text-green-400" />
                              ) : (
                                <X className="w-3 h-3 text-red-400" />
                              )
                            ) : (
                              <span className="text-white font-medium">
                                {value}
                              </span>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  ))}
                </div>
              </>
            }
          >
            <IoIosInformationCircleOutline color="#001D47" size={22} />
          </Tooltip>
        </Space>

        <Radio.Group
          size="small"
          options={Object.entries(TARIFFS).map(([key, { label }]) => ({
            label,
            value: key,
            style:
              user.subscription?.futurePlan?.tier === key
                ? {
                    background: "#00FDFF",
                    borderColor: "#00FDFF",
                    color: "black",
                  }
                : user.subscription?.tier === key
                ? {
                    background: "#001D47",
                    borderColor: "#001D47",
                    color: "white",
                  }
                : {},
          }))}
          optionType="button"
          buttonStyle="solid"
          onChange={(e) => handleTariffChange(e.target.value)}
          value={user.subscription?.tier}
        />
      </div>

      {subscription?.cancel_at && (
        <div className="flex justify-between gap-4 items-center mb-5">
          <h2 className="text-md md:text-xl font-semibold">
            Aktuelles Laufzeitende
          </h2>
          <p className="text-md md:text-lg">
            {moment(subscription?.cancel_at * 1000).format("Do MMM, YYYY")}
          </p>
        </div>
      )}

      <div className="mt-4">
        <button
          className="bg-transparent hover:bg-blue-500 text-white border border-white hover:text-white px-4 py-2 rounded-full w-full"
          onClick={async () => {
            if (!subscription) {
              navigate("/dashboard/upgrade");
              return;
            }
            const response =
              await SubscriptionService.generateSubscriptionManagementLink();
            window.location.href = response.data.link;
          }}
        >
          Abo Verwalten
        </button>
      </div>

      <Modal
        open={kundigungModal}
        onCancel={() => setKundigungModal(false)}
        title="Achtung!"
        footer={null}
      >
        <div>
          <p className="my-1">
            Du bist dabei deine Lizenz zum Ablauf der aktuellen Laufzeitperiode
            zu kündigen. Bitte bedenke, dass Dir und deinen Kunden ab dann keine
            Funktionen mehr zur Verfügung stehen. Das bedeutet unter Anderem
            z.B.: auch keinen Zugriff mehr auf Daten wie Promoter oder
            Empfehlungen. Wir empfehlen dir für weitere Informationen und
            Optionen erstmal den Support an zu schreiben.
          </p>
          <div className="flex items-start justify-start tertiary:gap-4 gap-1 mt-4 mb-4">
            <Checkbox
              checked={checkboxValue}
              onChange={(e) => setCheckboxValue(e.target.checked)}
            />
            <p className="text-ellipsis text-start hyphens-auto">
              Ich bestätige, dass ich auf weitere Infos verzichte und mir der
              Folgen bewusst bin
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between w-full gap-1 sm:flex-row sm:flex-nowrap">
          <Button
            className="bg-redattendtion rounded-[10px] text-white h-[46px] w-[250px]"
            onClick={async () => {
              setKundigungModal(false);
              await handleConfirmCancel();
            }}
            loading={loading}
          >
            Abonnement kündigen
          </Button>
          <Button
            type="primary"
            className="h-[46px] w-[250px] rounded-[10px]"
            onClick={() => setKundigungModal(false)}
            loading={loading}
          >
            Abbrechen
          </Button>
        </div>
      </Modal>

      <Modal
        open={popupComponent !== null}
        onCancel={() => setPopupComponent(null)}
        title={popupComponent?.heading}
        footer={null}
      >
        <div>
          <p className="my-1">{popupComponent?.subheading}</p>
          {popupComponent?.checkbox && (
            <div className="flex items-start justify-start tertiary:gap-4 gap-1 mt-4 mb-4">
              <Checkbox
                checked={checkboxTarifWechselValue}
                onChange={(e) => setCheckboxTarifWechselValue(e.target.checked)}
              />
              <p className="text-ellipsis text-start hyphens-auto">
                {popupComponent?.checkbox}
              </p>
            </div>
          )}
        </div>
        {!popupComponent?.hideButtons && (
          <div className="flex flex-col items-center justify-between w-full gap-1 sm:flex-row sm:flex-nowrap">
            <Button
              className={`${
                popupComponent?.heading === "TARIFWECHSEL!"
                  ? "bg-[#001d47]"
                  : "bg-redattendtion"
              }  rounded-[10px] text-white h-[46px] w-[250px]`}
              onClick={confirmTariffChange}
              loading={loading}
            >
              {popupComponent?.selection === "Basic"
                ? "Zum Support"
                : "Bestätigen"}
            </Button>
            <Button
              type={
                popupComponent?.heading === "TARIFWECHSEL!" ? "" : "primary"
              }
              className={`${
                popupComponent?.heading === "TARIFWECHSEL!"
                  ? "bg-[#ababab] text-[#ffffff]"
                  : ""
              } h-[46px] w-[250px] rounded-[10px]`}
              onClick={() => setPopupComponent(null)}
              loading={loading}
            >
              Abbrechen
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
}
