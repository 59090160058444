import React, { useState } from "react";

const FloatLabel = ({
  children,
  label,
  value,
  className,
  focusClass = "",
  unFocusClass = "",
  keepitfocused = false,
  showEmptyText = false,
  bgColor = "#ffffff",
  style,
}) => {
  const [focus, setFocus] = useState(false);

  const labelClass =
    keepitfocused || focus || (value && value.length !== 0)
      ? "label label-float " + focusClass
      : "label " + unFocusClass;

  return (
    <div
      className={`float-label ${className}`}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      style={style}
    >
      {children}
      <label className={labelClass + " !bg-transparent text-left"}>
        <span className={`bg-[${bgColor}]`}>{label} </span>
        <span
          className={`text-redattendtion transition-all bg-transparent inline bg-white ${
            showEmptyText ? "opacity-100" : "hidden opacity-0"
          }`}
        >
          {" "}
          (Sollte nicht leer sein)
        </span>
      </label>
    </div>
  );
};

export default FloatLabel;
