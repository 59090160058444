import React from "react";
import Footer from "../Footer/Index";
import Hero from "../Home/Hero/Hero";
import Navbar from "../Home/Navbar/Navbar";

const KostenlosTesten = () => {
  return (
    <div className="bg-white flex flex-col justify-center overflow-hidden items-center !transition-all">
      <div className="bg-[#fafafa] w-screen flex justify-center items-center px-2">
        <div className="  landing-page-hero flex flex-col items-center justify-center   max-w-[1600px]">
          <Navbar removeNavigation />
          <Hero />
        </div>
      </div>

      <div className="w-full px-3 tertiary:max-w-[1600px]  bg-white border-t-[1px] border-[#d8d8d8]   mt-10">
        <Footer />
      </div>
    </div>
  );
};

export default KostenlosTesten;
