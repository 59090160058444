import React from "react";

const TypeButton = ({
  type,
  text,
  onClick,
  typeButtonColor, // Dynamische Hintergrundfarbe
  className,
  isBasicQuestion = false, // Standardwert
}) => {
  // Standard-Stile für verschiedene Typen definieren
  let style = "";
  let background = "";
  let defaultShadow = "";

  switch (type) {
    case "finance":
    case "individual":
      style = "rounded-full";
      background =
        "linear-gradient(152deg, #00DEF7 0%, #0084F7 49.48%, #001D47 100%)";
      defaultShadow = "rgba(0, 222, 247, 0.5)";
      break;
    case "stormgas":
      style = "rounded-0";
      background =
        "linear-gradient(152deg, #DBEF5D 0%, #B8D01D 49.48%, #8DA10B 100%)";
      defaultShadow = "rgba(219, 239, 93, 0.5)";
      break;
    case "property":
      style = "rounded-sm";
      background = "darkslateblue";
      defaultShadow = "rgba(72, 61, 139, 0.5)";
      break;
    case "basic":
      if (isBasicQuestion) {
        style = "rounded-full";
        background = "linear-gradient(90deg, #77420c 0%, #fae49b 100%)";
        defaultShadow = "rgba(250, 228, 155, 0.5)";
      } else {
        style = "rounded-full md:!text-sm";
        background =
          "linear-gradient(152deg, #00DEF7 0%, #0084F7 49.48%, #001D47 100%)";
        defaultShadow = "rgba(0, 222, 247, 0.5)";
      }
      break;
    default:
      style = "rounded-full";
      background = "gray";
      defaultShadow = "rgba(128, 128, 128, 0.5)";
      break;
  }

  const buttonBackground = typeButtonColor || background;

  return (
    <div className={`flex justify-center ${className} relative`}>
      {/* Button */}
      <button
        onClick={onClick}
        style={{
          background: buttonBackground, // Dynamische Hintergrundfarbe
          position: "relative", // Button bleibt in Position
          zIndex: 50, // Über dem Schatten
        }}
        className={`${style} 
          mx-auto  
          gap-4 
          items-center 
          justify-center 
          font-semibold 
          text-white 
          flex 
          text-sm
          py-4
          px-7
          md:text-xl
          md:py-4
          md:px-10`}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "105%", // Breite proportional zum Button
            height: "105%", // Höhe proportional zum Button
            borderRadius: "50%", // Einheitliche Rundung für den Schatten
            background: typeButtonColor || defaultShadow, // Schattenfarbe dynamisch
            filter: "blur(20px)", // Weicher Schatteneffekt
            zIndex: -1, // Schatten unter dem Button
          }}
        />
        {text}
      </button>
    </div>
  );
};

export default TypeButton;
