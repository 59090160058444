import { Badge, Button, Dropdown, Layout, Menu, theme } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import { MdOutlineClose } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { useSelector } from "react-redux";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { MainLogo } from "../../assets/Svgs/GUTERTIPP";
import { logout } from "../../redux/auth/actions";
import { selectUser } from "../../redux/auth/selectors";
import { store } from "../../redux/store";
import { default as NotificationService } from "../../service/NotificationService";
import {
  LogoutDoor,
  ProfileSettings,
  TicketDropdown,
  VideoCam,
} from "../Dashboard/Navitems";

import Footer from "../Footer/Index";
import { default as Messages } from "../Messages/Messages";
import Notifications from "../Notification";
import Campaign from "./admincampaign";
import Home from "./Home";
import Leaderboard from "./leaderboard";
import Leads from "./leads";
import { BellIcon, Recycle, ShareIcon, items } from "./Navitems";
import Partner from "./partner";
import SearchSelect from "./SelectUser/SelectUser";
import Settings from "./settings";
import Statisik from "./Statistik";
import Supports from "./supports";
import SystemBenachrichtigungen from "./SystemBenachrichtigungen";
import Testimonials from "./Testimonials";
import Tutorials from "./tutorials";
import UploadTutorials from "./tutorials/UploadTutorials";

const { Header, Sider, Content } = Layout;
const Elements = [
  { Element: Home, title: "" },
  { Element: Tutorials, title: "tutorialshochladen" },
  { Element: Settings, title: "admineinstellungen" },
  { Element: Partner, title: "partner" },
  { Element: Leads, title: "leads" },
  { Element: Leaderboard, title: "partnerleaderboard" },
  { Element: Supports, title: "supporttickets" },
  { Element: Campaign, title: "adminkampagnen" },
  { Element: UploadTutorials, title: "uploadtutorials" },
  { Element: SystemBenachrichtigungen, title: "systembenachrichtigungen" },
  { Element: Testimonials, title: "Testimonials" },
  { Element: Statisik, title: "Statistiken" },
];

const App = () => {
  const { user } = useSelector(selectUser);
  const [hamburger, setHamburger] = useState(false);
  const sideMenu = useRef();
  const [width, setWidth] = useState(window.innerWidth);
  const [badge, setBadgeCount] = useState(0);
  const [badgeTwo, setBadgeTwoCount] = useState(0);
  const [openSearch, setOpenSearch] = useState(false);
  const [userselect, setUserselect] = useState();
  const [notifications, setNotifications] = useState({ messages: [] });
  const [outNotifications, setOutNotifications] = useState({ messages: [] });
  const [notificationInboundPage, setNotificationInboundPage] = useState(1);
  const [notificationOutboundPage, setNotificationOutboundPage] = useState(1);
  const [select, setSelected] = useState({
    key: "admindashboard",
    item: { props: { title: "Admin Dashboard" } },
  });
  const itemsDrop = [
    {
      key: "11",
      onClick: () => {
        navigate("/dashboard/einstellungen");
        toggleSidebar();
      },
      label: (
        <div className="flex items-center text-center justify-between  w-full ">
          <FiSettings fontSize={16} />
          <p className="font-semibold mx-2">Einstellungen</p>
        </div>
      ),
    },

    {
      key: "12123",
      onClick: () => {
        navigate("/dashboard/support");
        toggleSidebar();
      },
      label: (
        <div className="flex items-center text-center justify-between  w-full ">
          <TicketDropdown />
          <p className="font-semibold mx-2">Support</p>
        </div>
      ),
    },
    {
      key: "12123123",
      label: (
        <div
          className="flex items-center text-center justify-between  w-full "
          onClick={() => {
            navigate("/dashboard/profile");
            toggleSidebar();
          }}
        >
          <ProfileSettings />
          <p className="font-semibold mx-2">Konto</p>
        </div>
      ),
    },
    {
      key: "12112",
      label: (
        <div
          className="flex items-center text-center justify-between  w-full "
          onClick={() => {
            navigate("/dashboard/tutorials");
            toggleSidebar();
          }}
        >
          <VideoCam />
          <p className="font-semibold mx-2">Videokurs</p>
        </div>
      ),
    },

    {
      key: "13",
      onClick: () => {
        store.dispatch(logout());
        window.location.href = "/";
      },
      label: (
        <div className="flex items-center text-center justify-between  w-full ">
          <LogoutDoor />
          <p className="font-semibold mx-2">Abmelden</p>
        </div>
      ),
    },
  ];

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, [select]);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const getNotifications = async () => {
      const { data: notification } =
        await NotificationService.getInboundMessages({
          pages: notificationInboundPage,
          limit: 10,
        });
      setBadgeCount(notification.unread);
      setNotifications((prev) => ({
        ...notification,
        messages: [...prev?.messages, ...notification?.messages],
      }));
    };
    getNotifications();
  }, [notificationInboundPage]);

  useEffect(() => {
    const getOutNotifications = async () => {
      const { data: outNotifications } =
        await NotificationService.getOutboundMessages({
          pages: notificationOutboundPage,
          limit: 10,
        });
      setBadgeTwoCount(outNotifications.unread);
      setOutNotifications((prev) => ({
        ...outNotifications,
        messages: [...prev?.messages, ...outNotifications?.messages],
      }));
    };
    getOutNotifications();
  }, [notificationOutboundPage]);

  const getOutNotifications = useCallback(async () => {
    const { data: outNotifications } =
      await NotificationService.getOutboundMessages({
        pages: 0,
        limit: 10,
      });
    setBadgeTwoCount(outNotifications.unread);
    setOutNotifications(() => ({
      ...outNotifications,
      messages: [...outNotifications?.messages],
    }));
  }, []);
  const getNotifications = useCallback(async () => {
    const { data: notification } = await NotificationService.getInboundMessages(
      {
        pages: 0,
        limit: 10,
      }
    );
    setBadgeCount(notification.unread);
    setNotifications(() => ({
      ...notification,
      messages: [...notification?.messages],
    }));
  }, []);
  useEffect(() => {
    getOutNotifications();
    getNotifications();
  }, [location, getNotifications, getOutNotifications]);

  useEffect(() => {
    const pathKey = location.pathname.split("?")[0]
      ? location.pathname.split("?")[0].split("/").pop()
      : location.pathname.split("/").pop();
    const capitalizeWords = (string) => {
      return string
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };
    const specialRoutes = {
      tutorialshochladen: "Tutorials Hochladen",
      admineinstellungen: "Admin Einstellungen",
      partner: "Partner",
      leads: "Leads",
      partnerleaderboard: "Partner Leaderboard",
      supporttickets: "Support Tickets",
      adminkampagnen: "Admin Kampagnen",
      systembenachrichtigungen: "System Benachrichtigungen",
      testimonials: "Testimonials",
    };
    if (specialRoutes.hasOwnProperty(pathKey)) {
      setSelected({ key: specialRoutes[pathKey].split(" ").join("") });
      setTitle(specialRoutes[pathKey]);
    } else if (pathKey === "notifications" || pathKey === "messages") {
      setSelected({ key: "" });
      setTitle(capitalizeWords("Benachrichtigungen"));
    } else if (pathKey === "" || pathKey === "admindashboard") {
      setSelected({ key: "Admin Dashboard".split(" ").join("") });
      setTitle("Admin Dashboard");
    } else {
      const capitalizedKey = capitalizeWords(pathKey);
      setSelected({ key: capitalizedKey.split(" ").join("") });
      setTitle(capitalizedKey);
    }
  }, [location.pathname]);

  const toggleSidebar = () => {
    const sider = sideMenu?.current;

    if (!hamburger) {
      sider.classList.remove("!w-[0px]", "!min-w-[0px]", "!max-w-[0px]");

      setHamburger(!hamburger);
    } else {
      sider.classList.add("!w-[0px]", "!min-w-[0px]", "!max-w-[0px]");
      setHamburger(!hamburger);
    }
  };
  const [title, setTitle] = useState("Admin Dashboard");
  return (
    <Layout
      className="max-w-[2000px] m-auto bg-white"
      style={{ overflowX: "auto" }}
    >
      <Sider
        trigger={null}
        className={`border-r-[#D8D8D8] xs:!w-[80px]  !w-[0px] !min-w-[0px] !max-w-[0px] md:!w-[250px] md:!min-w-[250px] md:!max-w-[250px] border-r-[1px] transition-all animate-fade-right overflow-hidden`}
        collapsible
        theme="light"
        ref={sideMenu}
        collapsed={width < 768}
      >
        {width > 768 && (
          <div className="overflow-hidden  my-5 flex items-center justify-center">
            <MainLogo />
          </div>
        )}
        {width < 768 && (
          <div className="xs:mt-7 flex mt-5">
            <Link
              to={"/admindashboard/"}
              onClick={() => setTitle("Admin Dashboard")}
              className="m-auto xs:inline hidden"
            >
              <img
                src="/images/logo.png"
                className=" m-auto w-[35px] "
                alt=""
                width="50"
              />
            </Link>
            <Dropdown
              prefixCls="profile-dropdown-custom"
              className="xs:hidden m-auto"
              menu={{
                items: itemsDrop.map((e) => {
                  return { ...e, title: null };
                }),
              }}
              trigger={["click"]}
            >
              <div>
                <img
                  onMouseDown={(e) => {
                    e.target.classList.add("scale-90", "shadow-2xl");
                  }}
                  onMouseUp={(e) => {
                    e.target.classList.remove("scale-90", "shadow-2xl");
                  }}
                  src={user?.avatar ?? "/images/postausgang-btn.png"}
                  alt=""
                  className="w-[40px] cursor-pointer  transition-all rounded-lg m-auto"
                />
              </div>
            </Dropdown>
          </div>
        )}

        <Menu
          hidden={!hamburger && width < 500}
          onSelect={(e) => {
            toggleSidebar();
            setSelected({ key: e.key });

            if (e.key.toLowerCase() === "admindashboard") {
              return navigate("/admindashboard/");
            }
            navigate(
              `/admindashboard/${e.key.split(" ").join("").toLowerCase()}`
            );
          }}
          items={items.map((e) => ({
            ...e,
            key: e.label.split(" ").join(""),
            title: null,
          }))}
          selectedKeys={[select.key]}
          className="!font-[500] px-3 !text-[14px] my-16"
        />

        <Button
          hidden={!hamburger && width < 500}
          className="!bg-[#000000] hover:!bg-[#000000] hover:!shadow-none relative hover:!text-white h-[50px] text-white  mx-auto rounded-[10px]  items-center mt-[300px]"
          style={{
            width: width < 768 ? "45px" : "180px",
            display: width < 768 ? "block" : "flex",
            paddnig: width < 768 ? "0px" : "12px 20px 12px 20px",
          }}
          onClick={() => navigate("/dashboard")}
        >
          <span className="ms-[26px]">
            <Recycle />
            {width < 768 ? "" : "Ansicht wechseln"}
          </span>
        </Button>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            height: "auto",
            position: "relative",
            zIndex: 91,
          }}
        >
          <div>
            <nav className=" flex items-center justify-between px-1 xs:px-5 py-5 xs:py-1 transition-all ">
              <div className="gap-3 xs:hidden flex ">
                {width < 500 && (
                  <div className="cursor-pointer" onMouseDown={toggleSidebar}>
                    {!hamburger ? (
                      <RxHamburgerMenu className="transition-all   text-[28px] animate-fade" />
                    ) : (
                      <MdOutlineClose className="transition-all  text-[28px] animate-fade mx-5 xss:mx-auto " />
                    )}
                  </div>
                )}
                {!hamburger && (
                  <h1 className="text-[20px] primary:text-[28px] w-[35px] h-[35px] font-semibold sm:hidden flex ">
                    <img
                      src="/images/logo.png"
                      className="m-auto"
                      alt=""
                      width="50"
                    />
                  </h1>
                )}
              </div>
              <h1 className="text-[20px] primary:text-[28px] font-semibold xs:flex hidden ">
                {title?.replace?.(/%20/g, " ")}
              </h1>
              <div className="black-search-input sm:!flex !hidden">
                <SearchSelect
                  value={userselect}
                  setValue={setUserselect}
                  prefixCls={"search-select-dashboard"}
                />
              </div>
              <div className="flex items-center justify-between xs:gap-1 relative">
                <div className="xl:flex hidden">
                  <Badge
                    count={Math.max(0, badge)}
                    className="xl:block hidden z-50"
                  >
                    <Link to={"/admindashboard/notifications"}>
                      <Button
                        onClick={() => {
                          setSelected({
                            key: "",
                          });
                          setTitle("Benachrichtigungen");
                        }}
                        className={`relative h-[45px]  scale-90 w-[45px]  xl:block hidden  ${
                          window.location.pathname ===
                            "/admindashboard/notifications" &&
                          "!bg-blue-main !text-white"
                        }`}
                      >
                        <BellIcon className="top-[0px] relative left-[-8px]" />
                      </Button>
                    </Link>
                  </Badge>
                  <Badge
                    count={Math.max(0, badgeTwo)}
                    className="xl:block hidden "
                  >
                    {" "}
                    <Link to={"/admindashboard/messages"}>
                      <Button
                        onClick={() => {
                          setSelected({
                            key: "",
                          });
                          setTitle("Benachrichtigungen");
                        }}
                        className={`relative h-[45px]  scale-90 w-[45px]  xl:block hidden  ${
                          window.location.pathname ===
                            "/admindashboard/messages" &&
                          "!bg-blue-main !text-white"
                        }`}
                      >
                        <ShareIcon className="top-[0px] relative left-[-8px]" />
                      </Button>
                    </Link>
                  </Badge>
                </div>
                <div>
                  {openSearch && (
                    <div className="xss:static absolute flex top-[30px] animate-fade-up left-[-100%] overflow-hidden  sm:hidden">
                      <SearchSelect
                        value={userselect}
                        setValue={setUserselect}
                        usesimple={true}
                        prefixCls={"sm"}
                      />
                    </div>
                  )}
                </div>
                <div className="xl:hidden gap-2 flex">
                  {!openSearch ? (
                    <AiOutlineSearch
                      className="sm:hidden text-responsiveHeading cursor-pointer"
                      onClick={() => setOpenSearch((prev) => !prev)}
                    />
                  ) : (
                    <MdOutlineClose
                      className="sm:hidden text-responsiveHeading cursor-pointer"
                      onClick={() => setOpenSearch((prev) => !prev)}
                    />
                  )}
                  <Badge count={Math.max(0, badge)} className=" z-50">
                    <Link to={"/admindashboard/notifications"}>
                      <BellIcon className="" />
                    </Link>
                  </Badge>
                  <Badge count={Math.max(0, badgeTwo)} className=" ">
                    <Link to={"/admindashboard/messages"}>
                      <ShareIcon className="" />
                    </Link>
                  </Badge>
                </div>
                <div className="w-[10px] xs:hidden block"></div>
                <div className="xs:block hidden">
                  <Dropdown
                    prefixCls="profile-dropdown-custom"
                    menu={{
                      items: itemsDrop.map((e) => {
                        return { ...e, title: null };
                      }),
                    }}
                    trigger={["click"]}
                  >
                    <div>
                      <img
                        onMouseDown={(e) => {
                          e.target.classList.add("scale-90", "shadow-2xl");
                        }}
                        onMouseUp={(e) => {
                          e.target.classList.remove("scale-90", "shadow-2xl");
                        }}
                        src={user?.avatar ?? "/images/postausgang-btn.png"}
                        alt=""
                        className="w-[40px] cursor-pointer ml-5 transition-all rounded-lg "
                      />
                    </div>
                  </Dropdown>
                </div>
              </div>
            </nav>
          </div>
        </Header>
        <Content
          className="xs:p-[24px] p-3"
          style={{
            minHeight: 1500,
            background: colorBgContainer,
            overflowY: "auto",
            paddingBottom: "200px",
            position: "static",
            minWidth: 250,
          }}
        >
          <Routes>
            {Elements.map((e, i) => (
              <Route key={i} path={e.title} Component={e.Element} />
            ))}
            <Route
              path="/notifications"
              element={
                <Notifications
                  getNotifications={getNotifications}
                  isInbound={true}
                  data={notifications?.messages}
                  setNotificationInboundPage={setNotificationInboundPage}
                  notificationInboundPage={notificationInboundPage}
                  totalPages={notifications?.totalPages}
                  title={setTitle}
                  read={async (e) => {
                    await NotificationService.readInboundMessage(e._id);
                    setNotifications((prev) => ({
                      ...prev,
                      messages: [...prev.messages].map((me) => {
                        if (me._id !== e._id) {
                          return me;
                        } else {
                          return { ...me, hasRead: true };
                        }
                      }),
                    }));
                    setBadgeCount((prev) => prev - 1);
                  }}
                />
              }
            />
            <Route
              path="/messages"
              element={
                <Messages
                  getNotifications={getOutNotifications}
                  isInbound={false}
                  data={outNotifications?.messages}
                  setNotificationOutboundPage={setNotificationOutboundPage}
                  notificationOutboundPage={notificationOutboundPage}
                  totalPages={outNotifications?.totalPages}
                  read={async (e) => {
                    await NotificationService.readInboundMessage(e._id);
                    setOutNotifications((prev) => ({
                      ...prev,
                      messages: [...prev.messages].map((me) => {
                        if (me._id !== e._id) {
                          return me;
                        } else {
                          return { ...me, hasRead: true };
                        }
                      }),
                    }));
                    setBadgeTwoCount((prev) => prev - 1);
                  }}
                />
              }
            />
          </Routes>
          <div className="w-full px-3 tertiary:max-w-[1600px]  bg-white border-t-[1px] border-[#d8d8d8]   mt-10">
            <Footer />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
