import { message, Modal, Select, Spin, Upload } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/auth/selectors";
import CrudService from "../../service/CrudService";
import ImageUploadService from "../../service/UploadToCloudService";
import FloatLabel from "../FloatingLabel";
import { Button, Heading } from "./components";

const { Option } = Select;

const vorlageBonuspramien = [
  {
    name: "Apple AirPods",
    description:
      "Hier gibts was auf die Ohren! Und zwar mit einem paar Apple AirPods der aktuellsten Generation oder alternative Kopfhörer  im Wert von 139,00€",
    icon: "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1734515833/mbh60hqsxroganxwifvz.png",
  },
  {
    name: "200€ CashPrämie",
    description:
      "Nur Bares ist wahres! Du erhältst ein Auszahlung in Höhe von 200,00€ direkt auf dein Konto.",
    icon: "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1734515968/wvtywabdud7ndoip2uz9.png",
  },
  {
    name: "Playstation 5",
    description:
      "Für Gamerfreunde oder Alle, die es noch werden wollen! Deine  neue PlayStation 5 steht bereits in den Startlöschern und wartet auf dich! (Oder eine alternative Spielekonsole im Wert von 399,00€)",
    icon: "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1734516147/agv3nsaflnlmmthvts6v.png",
  },
  {
    name: "Dinner zu Zweit",
    description:
      "Schnapp Dir einen deiner Liebsten macht Euch einen schönen Abend. Du erhältst einen 75€ Gutschein für einen  Restaurantbesuch Deiner Wahl.",
    icon: "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1734524912/hjijswppzzseegpyn7gl.png",
  },
  {
    name: "Apple iPad",
    description:
      "Das Non plus Ultra aller Tablets. Das brandneue Apple iPad kann schon bald bei dir sein. Natürlich in deiner Wunschfarbe und mit Apple Pencil zum schreiben und zeichnen. Kein Apple Freund? Dann gerne für ein alternatives Tablet im Wert von 520,00€",
    icon: "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1734525522/l9i1h9hv20q0uldxywgg.png",
  },
  {
    name: "KurzUrlaub",
    description:
      "Kleine Auszeiten sind wichtig. Und ganz besonders, wenn man sie mit wichtigen Menschen verbringt. Dieser Reisegutschein für einen Kurztripp oder Wellnesstage mit Begleitung im Wert von 350,00€ bringt neue Energie!",
    icon: "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1734526030/sygmec8prqyuwzbmmp5t.png",
  },
  {
    name: "Wellness",
    description:
      "Entspannung Pur! Lass es dir mit einer entspannenden  Massage im Wert von 60€ für bis zu 60min mal wieder richtig gut gehen.",
    icon: "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1734528244/ccrikuykthlecuwkqrov.png",
  },
  {
    name: "Koch-Kurs",
    description:
      "Du wolltest schon immer mal in einem Bereich als Koch glänzen? Dann hast Du jetzt die Gelegenheit! Egal ob Grillen, Sushi oder sonstigen KochKurs - mit diesem Gutschein im Wert von 120€ wirst du sehr bald die tiefen deiner Lieblingsküche eintauchen.",
    icon: "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1734526587/a0uieawrb86rfiz87wqi.jpg",
  },
  {
    name: "Dein Sport",
    description:
      "Jetzt gibt es keine Ausreden mehr. Wir übernehmen deine Mitgliedschaft in einem Fitnessstudio oder sonstigen Sportaktivitäten für das erste Jahr bis zu 300€. Alternativ zahlen wir dir PersonalTraining Einheiten oder neue Sportausstattung im selben Wert. ",
    icon: "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1734527686/br4jbxvu86zaxl1shbuh.png",
  },
  {
    name: "Kino zu Zweit",
    description:
      "Geh doch endlich mal wieder ins Kino und lass es dir richtig gut gehen! Wir laden dich und deine Begleitung ein. Und damit es sich lohnt, selbstverständlich auch mit Getränken, Popcorn und Nachos bis zu einem Gesamtwert von 50,00€",
    icon: "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1734528093/f4hktx8syalzu4w6hgfk.png",
  },
  {
    name: "Special",
    description: "Schalte deine persönliche Spezial-Überraschung frei",
    icon: "https://res.cloudinary.com/dcwsmnhb9/image/upload/v1734772782/ewx696lbqjukfak8okn6.png",
  },
];

export function AddBonusPramieModal({
  open,
  onOpenChange,
  handleRefresh,
  editingBonus,
  setEditingBonus,
}) {
  const [name, setName] = useState("");
  const [icon, setIcon] = useState("");
  const [anzahlEmpfehlungen, setAnzahlEmpfehlungen] = useState(1);
  const [description, setDescription] = useState("");
  const [uploadingIcon, setUploadingIcon] = useState(false);

  const { user } = useSelector(selectUser);

  useEffect(() => {
    if (editingBonus) {
      setName(editingBonus.name);
      setAnzahlEmpfehlungen(Math.max(1, editingBonus.anzahlEmpfehlungen));
      setDescription(editingBonus.description);
      setIcon(editingBonus.icon);
    } else {
      setName("");
      setIcon("");
      setAnzahlEmpfehlungen(1);
      setDescription("");
    }
  }, [editingBonus]);

  const handleSubmit = useCallback(async () => {
    const existing = await CrudService.search("BonusPramie", 2, 1, {
      filters: { user_id: user?._id, anzahlEmpfehlungen },
    });

    if (
      editingBonus &&
      existing.data.items.find((x) => x._id !== editingBonus._id && !x.disabled)
    ) {
      return message.error(
        `Du hast bereits eine aktive Bonusprämie für ${anzahlEmpfehlungen} Empfehlungen`
      );
    }
    if (!editingBonus && existing.data.items.find((x) => !x.disabled)) {
      return message.error(
        `Du hast bereits eine aktive Bonusprämie für ${anzahlEmpfehlungen} Empfehlungen`
      );
    }

    if (editingBonus) {
      CrudService.update("BonusPramie", editingBonus._id, {
        user_id: user?._id,
        name,
        icon,
        anzahlEmpfehlungen,
        description,
      }).then(() => handleRefresh());
    } else {
      CrudService.create("BonusPramie", {
        user_id: user?._id,
        name,
        icon,
        anzahlEmpfehlungen,
        description,
        disabled: true,
      }).then(() => handleRefresh());
    }

    setEditingBonus(null);
    onOpenChange(false);
    setName("");
    setIcon("");
    setAnzahlEmpfehlungen(1);
    setDescription("");
  }, [editingBonus, user, name, icon, anzahlEmpfehlungen, description]);

  const handleTemplateSelect = (template) => {
    setName(template.name);
    setDescription(template.description);
    setIcon(template.icon);
  };

  return (
    <>
      <Modal
        wrapClassName="standardRoot-modal"
        footer={[]}
        open={open}
        onCancel={() => onOpenChange(false)}
        closeIcon={<></>}
        destroyOnClose
      >
        {/* create bonus section */}
        <div className="flex w-full justify-center rounded-[10px] border border-solid border-[#d8d8d8] bg-[#ffffff] p-6 sm:p-5">
          <div className="w-[40%] md:w-full">
            <div className="ml-1 flex items-center justify-between gap-5 md:ml-0">
              <Heading
                size="desktop_label_bold"
                as="h1"
                className="self-end text-[20px] font-semibold text-[#000000]"
              >
                Neue Bonusprämie erstellen
              </Heading>
              <img
                src="/images/img_cross_small_1.svg"
                alt="Close Image"
                className="h-[32px] cursor-pointer"
                onClick={() => onOpenChange(false)}
              />
            </div>
            <br />

            <div className="mb-4">
              <Select
                style={{ width: "100%" }}
                placeholder="Wähle eine Vorlage aus"
                onChange={(value) => {
                  const selected = vorlageBonuspramien.find(
                    (t) => t.name === value
                  );
                  if (selected) handleTemplateSelect(selected);
                }}
              >
                {vorlageBonuspramien.map((template, index) => (
                  <Option key={index} value={template.name}>
                    {template.name}
                  </Option>
                ))}
              </Select>
            </div>

            <FloatLabel
              label={"Prämienname"}
              unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full "
              className={" flex-grow"}
              value={name}
              onChange={(e) => setName(e.target.value)}
            >
              <div className="w-full flex justify-between items-center ">
                <input
                  type="text"
                  className="border-[1px] border-[#d8d8d8] outline-none rounded-[15px] p-[15px_12.5px] w-full !appearance-none"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </FloatLabel>
            <br />
            <FloatLabel
              label={"Empfehlungsanzahl"}
              unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full "
              className={" flex-grow"}
              value={anzahlEmpfehlungen}
              onChange={(e) =>
                setAnzahlEmpfehlungen(Math.max(1, e.target.value))
              }
            >
              <div className="w-full flex justify-between items-center ">
                <input
                  type="number"
                  className="border-[1px] border-[#d8d8d8] outline-none rounded-[15px] p-[15px_12.5px] w-full !appearance-none"
                  value={anzahlEmpfehlungen}
                  min={1}
                  onChange={(e) =>
                    setAnzahlEmpfehlungen(Math.max(1, e.target.value))
                  }
                />
              </div>
            </FloatLabel>
            <br />
            <FloatLabel
              label={"Prämienbeschreibung"}
              unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full "
              className={" flex-grow"}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            >
              <div className="w-full flex justify-between items-center ">
                <textarea
                  rows={3}
                  className="border-[1px] border-[#d8d8d8] outline-none rounded-[15px] p-[15px_12.5px] w-full !appearance-none"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </FloatLabel>

            <div className="ml-1 mt-5 md:ml-0">
              <div className="flex flex-col items-start">
                <Heading
                  as="h2"
                  className="relative z-[1] ml-6 flex items-center justify-center bg-[#ffffff] p-0.5 text-[16px] font-semibold text-[#d8d8d8] md:ml-0"
                >
                  Produktfoto
                </Heading>
                <Upload.Dragger
                  name="file"
                  className="w-full mt-2"
                  customRequest={async ({ file, onSuccess }) => {
                    if (uploadingIcon) return;
                    try {
                      setUploadingIcon(true);
                      const { secure_url } = await ImageUploadService.upload(
                        file
                      );
                      setIcon(secure_url);
                      onSuccess();
                    } catch (err) {
                      message.error("Fehlgeschlagen");
                    } finally {
                      setUploadingIcon(false);
                    }
                  }}
                >
                  <div className="relative flex justify-center self-stretch rounded-[14px] border-[1.5px] border-solid border-[#d8d8d8] px-14 py-16 md:p-5">
                    <div className="flex flex-col items-center gap-2.5">
                      {uploadingIcon ? (
                        <Spin />
                      ) : icon ? (
                        <img src={icon} className="h-[40px] border-rounded" />
                      ) : (
                        <img
                          src="/images/img_icons8_hochladen.svg"
                          alt="Upload Icon"
                          className="h-[40px]"
                        />
                      )}
                      <Heading
                        as="h3"
                        className="text-[16px] font-semibold text-[#000000]"
                      >
                        Produktfoto hochladen
                      </Heading>
                    </div>
                  </div>
                </Upload.Dragger>
              </div>
            </div>

            <Button
              type="primary"
              className="w-full ml-1 mt-[60px] rounded-[10px] px-[34px] md:ml-0 sm:px-5"
              onClick={handleSubmit}
            >
              Jetzt {editingBonus ? "speichern" : "erstellen"}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
