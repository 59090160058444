import { Button, Select, Skeleton, message } from "antd";
import classNames from "classnames";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MainLogo } from "../../../assets/Svgs/GUTERTIPP";
import PromoterService from "../../../service/PromoterService";
import PublicService from "../../../service/PublicService";
import LoadingScreen from "../../_NEW/LandingPage/loadingScreen/LoadingScreen";
import FloatLabel from "../../FloatingLabel";
import LoadingButton from "../../LoadingButton/LoadingButton";

const styles = {
  modelBg: "rgba(250, 250, 250, 0.30)",
  formBgOne: "transparent",
  formBgTwo: "white",
  font: "Manrope",
  rounded: true,
  buttonbg: "#001D47",
};
const PromoterRegistrationQR = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [acceptAGB, setAcceptAGB] = useState(false);
  const [form, setForm] = useState({
    anrede: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });
  const navigate = useNavigate();
  React.useEffect(() => {
    const getUserName = async () => {
      try {
        const { data } = await PublicService.getUserName(id);
        setUser(data);
      } catch (error) {
        console.log(error);
      }
    };
    getUserName();
  }, [id]);

  const handleChange = (e) => {
    const value = e.target ? e.target.value : e;
    const name = e.target ? e.target.name : "anrede";
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [alreadyClicked, setAlreadyClicked] = useState(false);
  const handleSubmit = async () => {
    try {
      for (let i = 0; i < Object.keys(form).length; i++) {
        const key = Object.keys(form)[i];
        if (form[key].length === 0 && key !== "lastname") {
          message.error(
            `${key.replace(key[0], key[0].toUpperCase())} erforderlich`
          );
          return;
        }
      }
      setAlreadyClicked(true);
      const response = await PromoterService.create({ ...form, userId: id });
      setSubmitted(true);
      setForm({
        anrede: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
      });

      message.success("Vielen Dank für Deine Registrierung!");
      navigate(`/promoter-link/${response?.data?.item?._id}`);
    } catch (error) {}
    setAlreadyClicked(false);
  };
  const getGermanPlaceholder = (field, form) => {
    return {
      anrede: "Anrede",
      firstname: form?.anrede === "Firma" ? "Firmenname" : "Vorname",
      lastname: form?.anrede === "Firma" ? "Rechtsform" : "Nachname",
      email: "Email",
      phone: "Telefon",
    }[field];
  };

  if (!user) {
    return <LoadingScreen />;
  }
  return (
    <div className="overflow-auto py-5 w-full max-w-[2000px] m-auto max-w-screen overflow-hidden">
      <div className="flex items-center sm:w-8/12 m-auto">
        <div className="my-2   w-fit mx-auto text-[#000000] relative xl:text-primary text-responsiveHeading font-bold text-center">
          Jetzt registrieren und{" "}
          <span className="text-trkis whitespace-nowrap">
            {user ? (
              `${user?.firstname + " " + user?.lastname}`
            ) : (
              <Skeleton active />
            )}
          </span>{" "}
          <br />
          weiterempfehlen
        </div>
      </div>

      <div className="tertiary:w-6/12 sm:w-8/12 w-11/12 m-auto">
        <form
          className=" w-full  m-auto"
          onSubmit={(e) => {
            e.preventDefault();
            if (alreadyClicked) {
              return;
            }
            handleSubmit(e);
          }}
        >
          <div className="flex flex-wrap gap-3   m-auto ">
            {Object.keys(form).map((e, i) => (
              <FloatLabel
                label={getGermanPlaceholder(e, form)}
                unFocusClass="!top-[16px] !text-[16px]"
                focusClass="!bg-transparent !text-[10px]"
                value={form[e]}
                className={classNames(" flex-grow w-auto ", {
                  " !w-full !flex-grow-0": e === "anrede",
                  "": e !== "anrede",
                })}
                style={{ width: "100%" }}
              >
                {e === "anrede" ? (
                  <Select
                    required={true}
                    tabIndex={i + 1}
                    name={e}
                    value={form[e]}
                    onChange={handleChange}
                    prefixCls="promoterregselect"
                    className="border-[1px] h-full w-full shadow-none   flex-grow  text-[20px] font-semibold border-[#d6d6d6] rounded-[10px] p-3 outline-none bg-transparent  "
                  >
                    <option value="Herr">Herr</option>
                    <option value="Frau">Frau</option>
                    <option value="Divers">Divers</option>
                    <option value="Firma">Firma</option>
                  </Select>
                ) : (
                  <input
                    required={e !== "lastname" || form.anrede !== "Firma"}
                    tabIndex={i + 1}
                    name={e}
                    type="text"
                    value={form[e]}
                    onChange={handleChange}
                    className="border-[1px]  w-full flex-grow  text-[20px] font-semibold border-[#d6d6d6] rounded-[10px] p-3 outline-none bg-transparent  "
                  />
                )}
              </FloatLabel>
            ))}
            <div className="flex flex-col justify-center  items-center md:items-end gap-3"></div>
          </div>
          <div className=" my-3" style={{ fontSize: 12 }}>
            Diese Daten sind nötig um Dich als Promoter führen zu können. Deine
            Teilnahme ist kostenlos, sowie unverbindlich und kann auf Wunsch
            jederzeit beendet werden.
          </div>
          <div
            className="flex font-medium text-[14px] gap-3 items-center"
            style={{ fontSize: 12 }}
          >
            <input
              required={true}
              type="checkbox"
              checked={acceptPrivacy}
              onChange={(e) => setAcceptPrivacy(e.target.checked)}
              style={{ accentColor: styles.buttonbg, scale: "1.1" }}
            />
            <p>
              Ich stimme dem{" "}
              <a
                href="/datenschutz"
                target="_blank"
                className="text-[#00def7] font-bold"
              >
                Datenschutz
              </a>{" "}
              von EinGuterTipp zu
            </p>
          </div>
          <div
            className="flex font-medium text-[14px] gap-3 items-center mt-2"
            style={{ fontSize: 12 }}
          >
            <input
              required={true}
              type="checkbox"
              checked={acceptAGB}
              onChange={(e) => setAcceptAGB(e.target.checked)}
              style={{ accentColor: styles.buttonbg, scale: "1.1" }}
            />
            <p>
              Ich stimme den{" "}
              <a
                href={`${window.location.origin}/teilnahmebedingungen/${user?._id}`}
                target="_blank"
                className="text-[#00def7] font-bold"
              >
                Teilnahmebedingungen
              </a>{" "}
              zu
            </p>
          </div>

          <Button
            htmlType="submit"
            loading={alreadyClicked}
            className={`w-full rounded-[15px] bg-darkslateblue text-white p-[12px_20px] text-center text-xl font-semibold !border-none !shadow-none !h-auto w-full cursor-pointer text-white text-xl font-semibold rounded-[10px] my-5 text-center !bg-[#0285F7] hover:!text-[#ffffff]`}
          >
            Weiter
          </Button>
        </form>
      </div>
      <div className="sm:w-[95%] m-auto flex justify-center items-center mt-6">
        <div className="flex  justify-left sm:justify-between font-semibold text-[12px] sm:text-[16px]  gap-5 sm:gap-20 my-5 w-full px-5">
          <h1 className="flex-1 whitespace-wrap">©</h1>
          <div className="flex flex-1 items-center gap-2 sm:gap-10 justify-end">
            <Link
              to={`${window.location.origin}/impressum/${user?._id}`}
              target="_blank"
            >
              <span>Impressum</span>
            </Link>
            <Link to={"/datenschutz"} target="_blank">
              Datenschutz
            </Link>
            <Link
              to={`${window.location.origin}/teilnahmebedingungen/${user?._id}`}
              target="_blank"
            >
              Teilnahmebedingungen
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoterRegistrationQR;
