import { Button, Space, Spin } from "antd";
import React, { useCallback, useState } from "react";
import { FaWhatsapp } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { UploadIcon } from "../../assets/Svgs/UploadIcon";
import { selectUser } from "../../redux/auth/selectors";
import PublicService from "../../service/PublicService";
import UploadToCloudService from "../../service/UploadToCloudService";
import constructWhatsappLink from "../../utils/whatsappLink";
import FloatLabel from "../FloatingLabel";

var windowReference = window;

const Support = ({ title }) => {
  const { user } = useSelector(selectUser);
  const [submitting, setSubmitting] = React.useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [whatsappNummer, setWhatsappNummer] = useState("");
  const [formData, setFormData] = React.useState({
    email: "",
    theme: "",
    message: "",
    image: "",
  });
  React.useEffect(() => {
    title("Support");
    setTimeout(() => title("Support"), 200);
    setFormData((prev) => ({ ...prev, email: user?.email }));
  }, [title]);

  React.useEffect(() => {
    PublicService.getAdminConfigure().then((res) => {
      setWhatsappNummer(res.data?.config?.CMS?.whatsappNummer);
    });
  }, []);

  const ImageUpload = async (e) => {
    const data = await UploadToCloudService.upload(e.target.files[0]);
    if (data?.secure_url) {
      const image = data?.secure_url;
      setFormData((prev) => ({ ...prev, image }));
    }
  };
  const handleChange = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };
  const handleSubmit = useCallback(() => {
    if (!formData.theme) return;
    if (!formData.message) return;
    if (buttonLoading) return;
    setButtonLoading(true);

    if (submitting) return;
    setSubmitting(true);

    PublicService.requestSupport({
      ...formData,
      user_id: user?._id,
    })
      .then((result) => {
        if (whatsappNummer) {
          const ticketNumber = `Ticket ${result?.data?.supportticket?.ticketid}`;
          const whatsappText = `${ticketNumber}\n"${formData.theme}"\n\nLieber Support, mein Name ist ${user.firstname} ${user.lastname} und ich habe folgendes Anliegen: ${formData.message}`;
          windowReference.location.href = constructWhatsappLink(
            whatsappNummer,
            whatsappText
          );
        }

        setFormData({
          email: "",
          theme: "",
          message: "",
          image: "",
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setSubmitting(false);
        setButtonLoading(false);
      });
  }, [user, submitting, formData, buttonLoading, whatsappNummer]);
  return (
    <div className="p-5 rounded-[20px] bg-[#fafafa]">
      <h1 className="text-2xl font-black text-[#001D47]">Support</h1>
      <p className="text-[#001D47] smx:text-[16px] mb-2">
        Schütte Dein{" "}
        <img
          style={{ height: 40 }}
          className="inline mx-[-10px]"
          src="/images/morflaxstudio56@2x.png"
        />{" "}
        aus - wir sind zur Stelle!
      </p>

      <div className="my-10 sm:w-[550px] w-full flex flex-col gap-8">
        <FloatLabel
          label={"E-Mail Adresse"}
          unFocusClass="sm:!top-[15px] !top-[20px]  !text-[16px] text-ellipsis  !font-semibold  !w-full"
          className={"xl:flex-grow-0 flex-grow"}
          focusClass="!text-base font-semibold text-[#D8D8D8]  ms-[5px]"
          keepitfocused
          bgColor="#FAFAFA"
        >
          <input
            type="text"
            className="border-[1px]  border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_15px] font-semibold xl:w-auto !w-full bg-transparent "
            value={formData.email}
            disabled
            onChange={(e) => handleChange("email", e.target.value)}
          />
        </FloatLabel>
        <FloatLabel
          label={"Thema"}
          unFocusClass="sm:!top-[15px] !top-[20px]  !text-[16px] text-ellipsis  !font-semibold  !w-full"
          className={"xl:flex-grow-0 flex-grow"}
          focusClass="!text-base font-semibold text-[#D8D8D8]  ms-[5px]"
          keepitfocused
          bgColor="#FAFAFA"
        >
          <input
            type="text"
            className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_15px]   font-semibold xl:w-auto !w-full bg-transparent "
            value={formData.theme}
            onChange={(e) => handleChange("theme", e.target.value)}
          />
        </FloatLabel>
      </div>
      <div className="my-8">
        <FloatLabel
          label={"Nachricht"}
          unFocusClass="sm:!top-[15px] !top-[20px]  !text-[16px] text-ellipsis  !font-semibold  !w-full"
          className={"xl:flex-grow-0 flex-grow"}
          focusClass="!text-base font-semibold text-[#D8D8D8]  ms-[5px]"
          keepitfocused
          bgColor="#FAFAFA"
        >
          <textarea
            type="text"
            className="border-[1px] border-[#d8d8d8] !shadow-none !outline-none rounded-[15px] p-[15px_15px]  font-semibold  xl:w-auto !w-full bg-transparent min-h-[300px]"
            value={formData.message}
            onChange={(e) => handleChange("message", e.target.value)}
          />
        </FloatLabel>
      </div>
      <div>
        {/* <div className="rounded-11xl bg-white min-h-[200px] overflow-hidden">
          <input
            type="file"
            id="upload-landingpageconfig-first"
            className="hidden"
            onChange={(e) => ImageUpload(e)}
          />
          {formData?.image?.length < 5 ? (
            <label
              htmlFor="upload-landingpageconfig-first"
              className="flex items-center flex-col h-[200px] select-none justify-center cursor-pointer"
            >
              <img src="/images/icons-8-hochladen-1.png" alt="" />
              <p className=" sm:text-xl text-base  font-semibold">
                Dateien zu Ihrer Anfrage hinzufügen (per Klick oder Drag&Drop)
              </p>
            </label>
          ) : (
            <label
              htmlFor="upload-landingpageconfig-first"
              className="h-[200px] relative  rounded-11xl overflow-hidden "
            >
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  backgroundImage: `url(${formData?.image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>
              <div className="h-[200px] top-0 absolute cover-upload-bg-second  w-full flex justify-end items-end">
                <div className=" sm:m-10 m-6 justify-center items-center flex flex-col text-white">
                  <UploadIcon />
                  <p className=" sm:text-xl text-base  text-white font-semibold">
                    Bild ändern
                  </p>
                </div>
              </div>
            </label>
          )}
        </div> */}
      </div>
      {!whatsappNummer ? (
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={buttonLoading}
          className="h-[60px] rounded-3xs text-xl font-semibold w-full my-5"
        >
          Anfrage abschicken
        </Button>
      ) : (
        <Button
          loading={buttonLoading}
          onClick={handleSubmit}
          className="h-[60px] text-[#ffffff] !bg-[#01D753] hover:!text-[#ffffff] hover:!outline-none hover:!border-0 rounded-3xs text-xl font-semibold w-full my-5"
        >
          <Space>
            <FaWhatsapp size={24} />
            <div>Support Chat öffnen</div>
          </Space>
        </Button>
      )}
    </div>
  );
};

export default Support;
