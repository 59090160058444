import { Collapse, Input, message, Radio, Switch } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import AdminService from "../../../service/AdminService";
import useWindowWidth from "../../Home/MakeYouFit/useWindowWidth";

const TARIFFS = {
  Basic: { price: 39, price2: 55, label: "Basic" },
  Standard: { price: 79, price2: 105, label: "Standard" },
  Premium: { price: 149, price2: 199, label: "Premium" },
};

const PartnerSubscription = ({ subscriptionUser, userid }) => {
  const [subscription, setSubscription] = useState();
  const [userobj, setUserobj] = useState();
  const [price, setPrice] = useState("");
  const width = useWindowWidth();
  const debounceTimeout = useRef(null);

  useEffect(() => {
    const getSubscriptionInfo = async () => {
      if (!subscriptionUser?.id) return;
      const { data } = await AdminService.getUserSubscriptionInfo(
        subscriptionUser.id
      );
      setSubscription(data?.subscription);
    };
    getSubscriptionInfo();
  }, [subscriptionUser]);

  const fetchUser = useCallback(() => {
    if (userid)
      AdminService.getUserStats(userid).then((res) => {
        setUserobj(res.data.user);
        setPrice(res.data.user.adminSpecifiedSubscriptionPrice || "");
      });
  }, [userid]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const handleToggleAutoVerlangerung = async (newValue) => {
    await AdminService.updateUserDetails({
      user_id: userobj._id,
      data: {
        autoRenewals: newValue,
      },
    }).then(async () => {
      fetchUser();
    });

    if (newValue) {
      message.success("Automatische Verlängerung wurde eingeschaltet");
    } else {
      message.success("Automatische Verlängerung wurde ausgeschaltet");
    }
  };

  const handlePriceChange = (e) => {
    const value = e.target.value;
    setPrice(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(async () => {
      await AdminService.updateUserDetails({
        user_id: userobj._id,
        data: {
          adminSpecifiedSubscriptionPrice: value,
        },
      });
    }, 1000);
  };

  const handleTariffChange = async (newTariff) => {
    try {
      await AdminService.updateUserDetails({
        user_id: userobj._id,
        data: {
          newTariff,
        },
      });
      fetchUser();
      message.success(
        `Tarifwechsel zu ${TARIFFS[newTariff]?.label} erfolgreich initiiert.`
      );
    } catch (error) {
      console.error("Error updating tariff:", error);
      message.error("Fehler beim Tarifwechsel. Bitte versuchen Sie es erneut.");
    }
  };

  if (!subscription?.id) return <></>;
  if (!userobj) return <></>;

  return (
    <Collapse
      items={[
        {
          key: "Abonmement",
          label: "Abonmement",
          children: (
            <div className="space-y-5">
              <div className="flex justify-between items-center">
                <h2 className="font-semibold">Standard</h2>
                <p>
                  {(
                    subscription?.plan?.amount /
                    100 /
                    (userobj?.subscription?.annualBilling ? 12 : 1)
                  ).toFixed(2)}
                  € / Monat (zzgl. MwSt.
                  {userobj?.subscription?.annualBilling
                    ? " jährliche Abrechnung"
                    : ""}
                  )
                </p>
              </div>

              <div className="flex justify-between items-center">
                <h2 className="font-semibold">Spezialpreis</h2>
                <div className="flex items-center">
                  <Input
                    type="number"
                    value={price}
                    onChange={handlePriceChange}
                    style={{ width: "100px" }}
                  />
                  <span className="ml-2">
                    € / Monat (zzgl. MwSt.
                    {userobj?.subscription?.annualBilling
                      ? " jährliche Abrechnung"
                      : ""}
                    )
                  </span>
                </div>
              </div>

              <div className="flex justify-between items-center">
                <h2 className="font-semibold">
                  {subscription?.cancel_at_period_end
                    ? "Ablaufdatum"
                    : "Nächste Zahlung"}
                </h2>
                <p>
                  {moment(subscription?.current_period_end * 1000).format(
                    "Do MMM, YYYY"
                  )}{" "}
                  {subscription?.cancel_at_period_end
                    ? "(wird nicht verlängert)"
                    : ""}
                </p>
              </div>

              <div className="flex justify-between items-center">
                <h2 className="font-semibold">Automatische Verlängerung</h2>
                <Switch
                  checked={userobj?.autoRenewals}
                  onChange={handleToggleAutoVerlangerung}
                />
              </div>

              <div className="flex justify-between items-center">
                <h2 className="font-semibold">Tarifauswahl</h2>
                <Radio.Group
                  options={Object.entries(TARIFFS).map(([key, { label }]) => ({
                    label,
                    value: key,
                  }))}
                  optionType="button"
                  buttonStyle="solid"
                  value={userobj.subscription?.tier}
                  onChange={(e) => handleTariffChange(e.target.value)}
                />
              </div>

              {subscription?.cancel_at && (
                <div className="flex justify-between items-center">
                  <h2 className="font-semibold">Aktuelles Laufzeitende</h2>
                  <p>
                    {moment(subscription?.cancel_at * 1000).format(
                      "Do MMM, YYYY"
                    )}
                  </p>
                </div>
              )}

              {userobj.subscription?.futurePlan && (
                <div className="flex justify-between items-center">
                  <h2 className="font-semibold">Zukünftiger Tarif</h2>
                  <p>
                    {TARIFFS[userobj.subscription.futurePlan.tier]?.label} (ab{" "}
                    {moment(
                      userobj.subscription.futurePlan.effectiveDate
                    ).format("Do MMM, YYYY")}
                    )
                  </p>
                </div>
              )}
            </div>
          ),
        },
      ]}
      defaultActiveKey={["1"]}
    />
  );
};

export default PartnerSubscription;
