import { UploadOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Divider,
  message,
  Modal,
  Popconfirm,
  Space,
  Switch,
  Upload,
} from "antd";
import { ExternalLink } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { login } from "../../redux/auth/actions";
import { selectLoading, selectUser } from "../../redux/auth/selectors";
import { store } from "../../redux/store";
import CrudService from "../../service/CrudService";
import PromotionsLinkService from "../../service/PromotionsLinkService";
import PublicService from "../../service/PublicService";
import UploadToCloudService from "../../service/UploadToCloudService";
import UserService from "../../service/UserService";
import { aboTarif } from "../../utils/divers";
import CollapseItem from "../Dashboard/kampaign/CollapseItem";
import { LoadingDiv } from "../Dashboard/Promoter/Cards";
import FloatLabel from "../FloatingLabel";
import { AddBonusPramieModal } from "./AddBonusPramieModal";
import { AddLandingPageModal } from "./AddLandingPageModal";
import { notiificationoconfig, Pramienkeys } from "./Data";
import { Heading } from "./Heading";

const Einstellungen = ({ title }) => {
  const [openAddNewModal, setOpenAddNewModal] = useState(false);
  const [openAddBonusPramieModal, setOpenAddBonusPramieModal] = useState(false);
  const [landingPages, setLandingPages] = useState([]);
  const [bonuses, setBonuses] = useState([]);
  const [editingLP, setEditingLP] = useState(null);
  const [editingBonus, setEditingBonus] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(false);

  const [videoLoading, setVideoLoading] = useState(false);
  const [thumbnailLoading, setThumbnailLoading] = useState(false);
  const [erklarFilm, setErklarFilm] = useState("");
  const [config, setConfig] = useState({});
  const loading = useSelector(selectLoading);

  const handleVideoUpload = useCallback(
    async (info) => {
      if (videoLoading) return;
      setVideoLoading(true);
      try {
        const res = await UploadToCloudService.upload(info.file.originFileObj);
        await UserService.updateUserInfo({
          erklarFilm: res.secure_url,
        });
        await getUser(true);
        message.success("Video hochgeladen");
      } catch (e) {
        message.error("Fehler beim hochladen");
      } finally {
        setVideoLoading(false);
      }
    },
    [videoLoading]
  );
  const handleThumbnailUpload = useCallback(
    async (info) => {
      if (thumbnailLoading) return;
      setThumbnailLoading(true);
      try {
        const res = await UploadToCloudService.upload(info.file.originFileObj);
        await UserService.updateUserInfo({
          erklarFilmThumbnail: res.secure_url,
        });
        await getUser(true);
        message.success("Thumbnail hochgeladen");
      } catch (e) {
        message.error("Fehler beim hochladen");
      } finally {
        setThumbnailLoading(false);
      }
    },
    [thumbnailLoading]
  );

  const handleEmpfehlungsPortalLogo = useCallback(
    async (info) => {
      if (loading) return;
      try {
        const res = await UploadToCloudService.upload(info.file.originFileObj);
        await UserService.updateUserInfo({
          empfehlungsPortalLogo: res.secure_url,
        });
        await getUser(true);
        message.success("Hochgeladen");
      } catch (e) {
        message.error("Fehler beim hochladen");
      } finally {
      }
    },
    [loading]
  );
  const handleDeleteErklarfilm = async () => {
    setVideoLoading(true);
    try {
      await UserService.updateUserInfo({
        erklarFilm: "",
        erklarFilmThumbnail: "",
      });
      await getUser(true);
    } catch (e) {
    } finally {
      setVideoLoading(false);
    }
  };

  const getAdminSetting = async () => {
    const { data } = await PublicService.getCMS();
    setConfig(data);
  };
  useEffect(() => {
    getAdminSetting();
  }, []);

  const { user } = useSelector(selectUser);

  const fetchLandingPages = useCallback(async () => {
    if (!user) return;

    const res = await CrudService.search("LinkKonfig", 100000, 1, {
      filters: { user_id: user._id },
      populate: "landingPage",
    });
    setLandingPages(res.data.items);
  }, [user]);

  useEffect(() => {
    fetchLandingPages();
  }, [fetchLandingPages]);

  const fetchBonuses = useCallback(async () => {
    if (!user) return;

    const res = await CrudService.search("BonusPramie", 100000, 1, {
      filters: { user_id: user._id },
    });
    setBonuses(res.data.items);
  }, [user]);

  useEffect(() => {
    fetchBonuses();
  }, [fetchBonuses]);

  const getUser = async (setdata) => {
    const { data } = await UserService.me();
    store.dispatch(login(data));
    if (setdata) setUserSettings(data.user);
  };

  const [userSettings, setUserSettings] = useState({});
  React.useEffect(() => {
    title("Einstellungen");
    setTimeout(() => title("Einstellungen"), 200);
    getUser(true);
    return () => {
      UserService.updateUserInfo({ ...userSettings });
    };
  }, [title]);
  const handleChange = (key, subkey, subsubkey, value, pattern) => {
    if (pattern && !pattern?.test(value)) {
      return;
    }
    setUserSettings((prev) => ({
      ...prev,
      [key]: {
        ...prev?.[key],
        [subkey]: { ...prev?.[key]?.[subkey], [subsubkey]: value },
      },
    }));
  };
  const handleChangePramien = (key, subkey, value, pattern) => {
    if (pattern && !pattern?.test(value)) {
      return;
    }
    setUserSettings((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [subkey]: value,
      },
    }));
  };

  const handleSave = async () => {
    await UserService.updateUserInfo({ ...userSettings }).then(() => {
      getUser(true);
      message.success("Erfolgreich gespeichert");
    });
  };
  return (
    <div>
      <CollapseItem
        mobilDunner
        defaultOpen={false}
        title={
          <div className="text-xl font-bold">Erinnerungen konfigurieren:</div>
        }
      >
        <div className="flex flex-col gap-7 mt-5 text-sm">
          {Object.keys(notiificationoconfig)?.map((e) => {
            const textParts = notiificationoconfig[e].split("...");

            return (
              <div className="flex  gap-5 items-center">
                <div className="flex gap-2 items-center">
                  <div className="text-center relative w-[30px]">
                    <p className="absolute -top-4 mx-auto left-0 right-0 ">
                      App
                    </p>
                    <Checkbox
                      className="scale-150 bg-transparent mt-2"
                      checked={
                        userSettings?.notificationConfiguration?.[e]?.app
                      }
                      onChange={(ce) =>
                        handleChange(
                          "notificationConfiguration",
                          e,
                          "app",
                          ce.target.checked
                        )
                      }
                    />
                  </div>
                  <div className="text-center relative w-[30px]">
                    <p className="absolute -top-4 mx-auto left-0 right-0">
                      Mail
                    </p>
                    <Checkbox
                      className="scale-150 bg-transparent mt-2"
                      checked={
                        userSettings?.notificationConfiguration?.[e]?.email
                      }
                      onChange={(ce) =>
                        handleChange(
                          "notificationConfiguration",
                          e,
                          "email",
                          ce.target.checked
                        )
                      }
                    />
                  </div>
                </div>
                <div className="flex  gap-5 items-center">
                  {textParts[0]}

                  {!!textParts[1] && (
                    <FloatLabel
                      className={
                        "border border-[#d8d8d8] rounded-3xs h-12 w-16  p-2"
                      }
                      keepitfocused
                      label={"Dauer"}
                    >
                      <input
                        type="text"
                        value={
                          userSettings?.notificationConfiguration?.[e]?.days
                        }
                        onChange={(ce) =>
                          handleChange(
                            "notificationConfiguration",
                            e,
                            "days",
                            ce.target.value,
                            ce.target.value.length > 0 ? /^[0-9]+$/g : null
                          )
                        }
                        className="outline-none border-none appearance-none h-full w-full bg-transparent"
                      />
                    </FloatLabel>
                  )}
                  {textParts[1] ?? ""}
                </div>
              </div>
            );
          })}
        </div>

        <Button
          className="h-[60px] w-full my-5 text-xl rounded-3xs"
          type="primary"
          onClick={() => handleSave(true)}
        >
          Speichern
        </Button>
      </CollapseItem>

      <CollapseItem
        mobilDunner
        spaceOben
        title={
          <div
            className={`text-xl font-bold ${
              aboTarif(user) < 2 ? "text-[#ababab]" : ""
            }`}
          >
            Verwaltung Empfehlungsportal {aboTarif(user) < 2 && "(Upgrade)"}
          </div>
        }
        defaultOpen={false}
        upgradeRequired={aboTarif(user) < 2}
      >
        <div className="p-8 flex flex-col gap-8 item relative mdx:p-2">
          <div className=" flex flex-col gap-[30px] self-stretch">
            <div className="flex flex-col items-start gap-5">
              {aboTarif(user) > 2 && (
                <>
                  <div className="w-[300px] mdx:w-[250px] flex justify-between items-center">
                    <Heading
                      size="desktop_label_bold"
                      as="h5"
                      className="text-[20px] mdx:text-[16px] font-semibold text-[#000000]"
                    >
                      EinGuterTipp Branding
                    </Heading>
                    <Switch
                      loading={loading}
                      checked={user?.egtBrandingEmpfehlungsportal}
                      onChange={async (e) => {
                        await UserService.updateUserInfo({
                          egtBrandingEmpfehlungsportal: e,
                        });
                        await getUser(true);
                      }}
                    />
                  </div>
                </>
              )}

              {aboTarif(user) > 2 && !user?.egtBrandingEmpfehlungsportal && (
                <>
                  <div className="min-w-[300px] mdx:min-w-[250px] gap-4 flex justify-between items-center">
                    <Upload
                      loading={loading}
                      name="image"
                      onChange={handleEmpfehlungsPortalLogo}
                      accept="image/*"
                    >
                      <Button icon={<UploadOutlined />} loading={loading}>
                        Eigenes Logo hochladen
                      </Button>
                    </Upload>

                    {user?.empfehlungsPortalLogo && (
                      <img src={user.empfehlungsPortalLogo} width={50} />
                    )}
                    {user?.empfehlungsPortalLogo && (
                      <Popconfirm
                        title="Bist du sicher?"
                        onConfirm={async () => {
                          await UserService.updateUserInfo({
                            empfehlungsPortalLogo: "",
                          });
                          await getUser(true);
                        }}
                      >
                        <MdDelete
                          size={21}
                          color="red"
                          className="cursor-pointer"
                        />
                      </Popconfirm>
                    )}
                  </div>
                  <Divider />
                </>
              )}

              <Heading
                size="desktop_label_bold"
                as="h5"
                className="text-[20px] smx:text-[18px] font-semibold text-[#000000]"
              >
                Links konfigurieren
              </Heading>
              <div className="self-stretch rounded-[16px] bg-[#ffffff] p-[30px] smx:p-6 overflow-auto">
                <div className="flex flex-col gap-[30px] ">
                  {landingPages?.map?.((x) => (
                    <div
                      key={x._id}
                      className="flex flex-1 items-center justify-between gap-5 mdx:flex-col-reverse mdx:items-start mdx:border-[#dedede] mdx:border-b mdx:pb-[30px] smx:gap-2"
                    >
                      <div className="flex justify-start  gap-10 mdx:gap-2">
                        <div className="flex flex-col items-start w-[200px] mdx:w-[150px] justify-center">
                          <Heading
                            as="p"
                            className="text-[14px] font-medium text-[#868686]"
                          >
                            Thema
                          </Heading>

                          <div className="flex items-center gap-2.5">
                            <img
                              src={x.theme.icon}
                              alt=" "
                              className="w-[20px] h-[20px] object-contain"
                            />
                            <Heading
                              size="desktop_label_semibold"
                              as="h6"
                              className="text-[16px] font-semibold text-[#000000] max-w-[150px] truncate overflow-hidden"
                            >
                              {x?.theme?.name ?? x?.theme?.label}
                            </Heading>
                          </div>
                        </div>
                        <div className="flex flex-col items-left justify-center">
                          <Heading
                            as="p"
                            className="text-[14px] font-medium text-[#868686]"
                          >
                            Landingpage
                          </Heading>
                          <Heading
                            size="desktop_label_semibold"
                            as="h6"
                            className="text-[16px] font-semibold text-[#000000] max-w-[300px] mdx:max-w-[150px] truncate"
                          >
                            {x?.landingPage?.name}
                          </Heading>
                        </div>
                      </div>
                      <div className="flex gap-2.5">
                        <LoadingDiv loading={loading}>
                          {x?.disabled ? (
                            <img
                              src="/images/img_info_red.svg"
                              alt="Info Icon"
                              className="h-[38px] mdx:h-[28px] rounded-lg cursor-pointer bg-[#ff0000]"
                              onClick={async () => {
                                await CrudService.update("LinkKonfig", x._id, {
                                  disabled: false,
                                });
                                fetchLandingPages();
                              }}
                            />
                          ) : (
                            <img
                              src="/images/img_info_green.svg"
                              alt="Info Icon"
                              className="h-[38px] mdx:h-[28px] rounded-lg cursor-pointer"
                              onClick={() =>
                                setConfirmation({
                                  action: "disable",
                                  id: x._id,
                                  text: "Mit der Deaktivierung dieser Landingpage im Empfehlungsportal, werden auch alle zugehörigen Links für das Portal deaktiviert. Sprich, deine Promoter sehen den Link zu dieser Seite nicht mehr. ",
                                })
                              }
                            />
                          )}
                        </LoadingDiv>

                        <img
                          onClick={() => {
                            setEditingLP(x);
                            setOpenAddNewModal(true);
                          }}
                          className="h-[38px] mdx:h-[28px] w-[38px] rounded-lg cursor-pointer"
                          src="/images/img_edit.svg"
                        />

                        <img
                          onClick={() =>
                            setConfirmation({
                              action: "delete",
                              id: x._id,
                              text: "Mit der Löschung dieser Landingpage im Empfehlungsportal, werden auch alle zugehörigen Links für das Portal deaktiviert. Sprich, deine Promoter sehen den Link zu dieser Seite nicht mehr. ",
                            })
                          }
                          src="/images/img_info_white_0.svg"
                          alt="White Info Icon"
                          className="h-[38px] mdx:h-[28px] rounded-lg cursor-pointer"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Button
              onClick={() => {
                setOpenAddNewModal(true);
                setEditingLP(null);
              }}
              className="flex h-[70px] flex-row items-center justify-center self-stretch rounded-[10px] bg-[#0084f7] px-[34px] text-center text-[20px] smx:text-[18px] font-semibold text-[#ffffff] sm:px-5 mdx:text-[14px]"
            >
              Neue Landingpage
            </Button>
          </div>
          <div className="mt-5 flex flex-col items-start justify-center gap-[26px] self-stretch">
            <div className="w-full flex justify-between smx:flex-col gap-4">
              <Heading
                size="desktop_label_bold"
                as="h5"
                className="text-[20px] smx:text-[18px] font-semibold text-[#000000]"
              >
                Bonusprämien konfigurieren
              </Heading>

              <Link
                to={"/dashboard/pramienportal/Vorschau Empfehlungsportal"}
                target="_blank"
              >
                <Space>
                  <Heading
                    size="desktop_label_bold"
                    as="h5"
                    className="text-[20px] smx:text-[18px] font-semibold text-[#000000]"
                  >
                    Testseite ansehen
                  </Heading>
                  <ExternalLink size={18} className="cursor-pointer" />
                </Space>
              </Link>
            </div>

            <div className="self-stretch rounded-[16px] bg-[#ffffff] p-[30px] smx:p-6 overflow-auto">
              <div className="flex flex-col gap-[30px]">
                {bonuses?.map?.((x) => (
                  <div
                    key={x._id}
                    className="flex flex-1 items-center justify-between gap-5 mdx:flex-col-reverse mdx:items-start smx:gap-2 mdx:border-[#dedede] mdx:border-b mdx:pb-[30px]"
                  >
                    <div className="flex justify-start  gap-10 mdx:gap-2">
                      <div className="flex flex-col items-start w-[200px] mdx:w-[150px] justify-center">
                        <Heading
                          as="p"
                          className="text-[14px] font-medium text-[#868686]"
                        >
                          Prämienname
                        </Heading>

                        <div className="flex items-center gap-2.5">
                          {x.icon && (
                            <img
                              src={x.icon}
                              alt=" "
                              className="w-[20px] h-[20px] object-contain"
                            />
                          )}
                          <Heading
                            size="desktop_label_semibold"
                            as="h6"
                            className="text-[16px] font-semibold text-[#000000] max-w-[150px] truncate overflow-hidden"
                          >
                            {x?.name}
                          </Heading>
                        </div>
                      </div>
                      <div className="flex flex-col items-left justify-center">
                        <Heading
                          as="p"
                          className="text-[14px] font-medium text-[#868686]"
                        >
                          Anzahl Empfehlungen
                        </Heading>
                        <Heading
                          size="desktop_label_semibold"
                          as="h6"
                          className="text-[16px] font-semibold text-[#000000] max-w-[300px] truncate"
                        >
                          {x?.anzahlEmpfehlungen}
                        </Heading>
                      </div>
                    </div>
                    <div className="flex gap-2.5">
                      <LoadingDiv loading={loading}>
                        {x?.disabled ? (
                          <img
                            src="/images/img_info_red.svg"
                            alt="Info Icon"
                            className="h-[38px] mdx:h-[28px] rounded-lg cursor-pointer bg-[#ff0000]"
                            onClick={async () => {
                              const existing = await CrudService.search(
                                "BonusPramie",
                                2,
                                1,
                                {
                                  filters: {
                                    user_id: user?._id,
                                    anzahlEmpfehlungen: x.anzahlEmpfehlungen,
                                  },
                                }
                              );

                              if (
                                existing.data.items.find(
                                  (b) => b._id !== x._id && !b.disabled
                                )
                              ) {
                                return message.error(
                                  `Du hast bereits eine aktive Bonusprämie für ${x.anzahlEmpfehlungen} Empfehlungen`
                                );
                              }

                              await CrudService.update("BonusPramie", x._id, {
                                disabled: false,
                              });
                              fetchBonuses();
                            }}
                          />
                        ) : (
                          <img
                            src="/images/img_info_green.svg"
                            alt="Info Icon"
                            className="h-[38px] mdx:h-[28px] rounded-lg cursor-pointer"
                            onClick={async () => {
                              setConfirmation({
                                action: "pramie-disable",
                                id: x._id,
                                text: "Mit der Deaktivierung dieser Prämie im Empfehlungsportal, werden deine Promoter sie dort nicht mehr sehen. ",
                              });
                            }}
                          />
                        )}
                      </LoadingDiv>

                      <img
                        onClick={() => {
                          setEditingBonus(x);
                          setOpenAddBonusPramieModal(true);
                        }}
                        className="h-[38px] mdx:h-[28px] w-[38px] rounded-lg cursor-pointer"
                        src="/images/img_edit.svg"
                      />

                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setConfirmation({
                            action: "pramie-delete",
                            id: x._id,
                            text: "Mit der Löschung dieser Prämie im Empfehlungsportal, werden deine Promoter sie dort nicht mehr sehen. ",
                          });
                        }}
                      >
                        <img
                          src="/images/img_info_white_0.svg"
                          alt="White Info Icon"
                          className="h-[38px] mdx:h-[28px] rounded-lg cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col items-start gap-1 justify-center self-stretch">
              <Button
                onClick={() => {
                  if (bonuses.length === 0) {
                    setConfirmation({
                      action: "new-bonus",
                      text: "Bonusprämien werden NICHT über das EinGuterTipp bzw. das System abgerechnet und/oder eingelöst. Die von dir erstellten Bonusprämien sind direkt von dir an deine Promoter zu erbringen“ Die Vorlagen sind nur Ideenbeispiele. Erstelle nur Prämien, die Du zu 100% einlösen kannst.",
                    });
                  } else {
                    setOpenAddBonusPramieModal(true);
                    setEditingBonus(null);
                  }
                }}
                className="flex h-[70px] flex-row items-center justify-center self-stretch rounded-[10px] bg-[#0084f7] px-[34px] text-center text-[20px] smx:text-[18px] font-semibold text-[#ffffff] sm:px-5 mdx:text-[14px]"
              >
                Neue Bonusprämie
              </Button>
            </div>
          </div>

          <div className="mt-5 flex flex-col items-start justify-center gap-[26px] self-stretch">
            <Heading
              size="desktop_label_bold"
              as="h5"
              className="text-[20px] smx:text-[18px] font-semibold text-[#000000]"
            >
              Erklärfilm anpassen
            </Heading>

            <div className="self-stretch rounded-[16px] bg-[#ffffff] p-[30px] smx:p-6 overflow-auto">
              <div className="p-8 flex flex-col gap-4">
                <div className="flex mdx:flex-col gap-4">
                  <Upload
                    loading={videoLoading}
                    name="video"
                    onChange={handleVideoUpload}
                    accept="video/*"
                  >
                    <Button icon={<UploadOutlined />} loading={videoLoading}>
                      Eigenes Video hochladen
                    </Button>
                  </Upload>
                  <Upload
                    loading={thumbnailLoading}
                    name="thumbnail"
                    onChange={handleThumbnailUpload}
                    accept="image/*"
                  >
                    <Button
                      icon={<UploadOutlined />}
                      loading={thumbnailLoading}
                    >
                      Eigenes Thumbnail hochladen
                    </Button>
                  </Upload>

                  {user?.erklarFilm && (
                    <Popconfirm
                      onConfirm={handleDeleteErklarfilm}
                      title="Bist du sicher?"
                    >
                      <Button
                        danger
                        icon={<MdDelete />}
                        loading={thumbnailLoading}
                      >
                        Eigenes Video löschen
                      </Button>
                    </Popconfirm>
                  )}
                </div>

                {!videoLoading &&
                  !thumbnailLoading &&
                  (user?.erklarFilm || config?.CMS?.erklarFilm) && (
                    <div className="!w-full flex flex-col items-center justify-center mb-10">
                      <video
                        playsInline
                        className={`aspect-video secondary:w-[65%] rounded-sm object-cover shadow-[0_8px_30px_rgb(0,0,0,0.12)] bg-[#000000] p-1 rounded-sm bg-opacity-50`}
                        controls
                        poster={
                          user?.erklarFilm
                            ? user?.erklarFilmThumbnail
                            : config.CMS.erklarFilmThumbnail
                        }
                      >
                        <source
                          src={user?.erklarFilm || config?.CMS?.erklarFilm}
                          type="video/mp4"
                        />
                        <source
                          src={user?.erklarFilm || config?.CMS?.erklarFilm}
                          type="video/webm"
                        />
                        <source
                          src={user?.erklarFilm || config?.CMS?.erklarFilm}
                          type="video/quicktime"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </CollapseItem>

      <div className="my-10">
        <CollapseItem
          mobilDunner
          defaultOpen={false}
          title={
            <div className="text-xl font-bold">
              Standardkonfiguration für Prämiensystem:
            </div>
          }
        >
          <div className="flex flex-col gap-7 mt-5 text-sm">
            <Alert
              type="info"
              message="Diese Einstellungen werden für jede neu angelegte Landingpage übernommen, können aber dort jederzeit angepasst werden."
            />
            {Object.keys(Pramienkeys)?.map((e, i) => {
              return (
                <FloatLabel
                  label={Pramienkeys[e]}
                  unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full"
                  className={"xl:flex-grow-0 flex-grow"}
                  keepitfocused
                  key={i}
                >
                  <input
                    type="text"
                    className="border-[1px] border-[#d8d8d8] !shadow-none font-semibold !outline-none rounded-[15px] p-[15px_12.5px] xl:w-auto !w-full "
                    value={
                      (userSettings?.pramienConfiguration?.[e] || 0) + " Euro"
                    }
                    onChange={(ce) =>
                      handleChangePramien(
                        "pramienConfiguration",
                        e,
                        ce.target.value.replace(" Euro", ""),
                        ce.target.value.replace(" Euro", "").length > 0
                          ? /^[0-9]+$/g
                          : null
                      )
                    }
                  />
                </FloatLabel>
              );
            })}
          </div>

          <Button
            className="h-[60px] w-full my-5 text-xl rounded-3xs"
            type="primary"
            onClick={() => handleSave(true)}
          >
            Speichern
          </Button>
        </CollapseItem>
      </div>

      <AddLandingPageModal
        open={openAddNewModal}
        onOpenChange={setOpenAddNewModal}
        handleRefreshPages={fetchLandingPages}
        editingLP={editingLP}
        setEditingLP={setEditingLP}
        landingPageThemes={landingPages}
      />

      <AddBonusPramieModal
        open={openAddBonusPramieModal}
        onOpenChange={setOpenAddBonusPramieModal}
        handleRefresh={fetchBonuses}
        editingBonus={editingBonus}
        setEditingBonus={setEditingBonus}
      />

      <Modal
        open={confirmation}
        onCancel={() => {
          setConfirmation(false);
          setCheckboxValue(false);
        }}
        title="ACHTUNG!"
        footer={[]}
      >
        <div>
          <p className="my-1">{confirmation?.text}</p>
          <div className="flex items-start justify-start tertiary:gap-4 gap-1 mt-4 mb-4">
            <Checkbox
              checked={checkboxValue}
              onChange={(e) => setCheckboxValue(e.target.checked)}
            />
            <p className="text-ellipsis text-start hyphens-auto ">
              {confirmation?.action === "new-bonus"
                ? "Ich habe verstanden"
                : "Ich bestätige, dass ich den Hinweis zur Kenntnis genommen habe und mir der Folgen bewusst bin"}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between w-full gap-1 sm:flex-row sm:flex-nowrap">
          <Button
            className="bg-redattendtion rounded-[10px] text-white h-[46px] w-[250px]"
            onClick={async () => {
              if (!checkboxValue)
                return message.info(
                  "Bitte bestätige, dass du den Hinweis zur Kenntnis genommen hast"
                );
              setConfirmation(false);
              setCheckboxValue(false);

              if (confirmation.action === "delete") {
                await PromotionsLinkService.disableLandingPage(confirmation.id);
                await CrudService.delete("LinkKonfig", confirmation.id);
                fetchLandingPages();
              } else if (confirmation.action === "disable") {
                await PromotionsLinkService.disableLandingPage(confirmation.id);
                await CrudService.update("LinkKonfig", confirmation.id, {
                  disabled: true,
                });
                fetchLandingPages();
              } else if (confirmation.action === "pramie-disable") {
                await CrudService.update("BonusPramie", confirmation.id, {
                  disabled: true,
                });
                fetchBonuses();
              } else if (confirmation.action === "pramie-delete") {
                await CrudService.delete("BonusPramie", confirmation.id);
                fetchBonuses();
              } else if (confirmation.action === "new-bonus") {
                setOpenAddBonusPramieModal(true);
                setEditingBonus(null);
              }
            }}
          >
            {confirmation.action === "new-bonus"
              ? "Weiter"
              : confirmation.action === "delete"
              ? "Löschen"
              : "Deaktivieren"}
          </Button>
          <Button
            type="primary"
            className="h-[46px] w-[250px] rounded-[10px]"
            onClick={(e) => {
              setConfirmation(false);
              setCheckboxValue(false);
            }}
          >
            Abbrechen
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Einstellungen;
